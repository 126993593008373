<script setup lang="ts">
import { inject, ref, toRefs, watch } from 'vue'

import SectionLabel from '@voix/components/chrome/SectionLabel.vue'
import SpinnerLoader from '@voix/components/chrome/Loader.vue'
import type { Ref } from 'vue'
import { $voixNuxtApi } from '@voix/voix-layer/composables/voixNuxtApi'
import MediaDirectory from './MediaDirectory.vue'

const props = defineProps({
  selectedDirectory: {
    type: String,
    required: true,
  },
  directoryMode: {
    type: String,
    required: true,
  },
})

defineEmits(['selectDirectory'])

const provider: Ref<string> = inject('provider')
const { selectedDirectory } = toRefs(props)
const loading = ref(true)
const fresh = ref(false)

const directories = ref([])

watch(() => props.selectedDirectory, () => {
  getHierarchies()
})

function refreshDirectory() {
  fresh.value = true
  getHierarchies().then(() => {
    fresh.value = false
  })
}

async function getHierarchies() {
  loading.value = true

  const query = {}

  if (selectedDirectory.value)
    query.hierarchy_id = selectedDirectory.value

  if (fresh.value)
    query.fresh = 1

  const params = new URLSearchParams(query).toString()
  const url = `/api/voix/media/${provider.value}/hierarchies?${params.toString()}`

  const { data } = await $voixNuxtApi(url)
  directories.value = data.value
  loading.value = false
}

getHierarchies()
</script>

<template>
  <div>
    <div
      v-if="loading === true"
      class="h-full flex justify-center items-center"
    >
      <div class="p-4">
        <SpinnerLoader>
          <div class="mt-2 text-center voix-admin-text font-medium text-xs">
            Loading...
          </div>
        </SpinnerLoader>
      </div>
    </div>
    <div
      v-else class="p-4"
    >
      <div class="flex justify-between">
        <SectionLabel>{{ props.directoryMode }}</SectionLabel>

        <button class="p-0.5 px-1.5 voix-admin-bg-lightest voix-admin-text rounded-sm" @click="refreshDirectory">
          <Icon
            name="pepicons-pop:refresh" class="w-4 h-4"
          />
        </button>
      </div>

      <div
        class="mt-3 flex flex-col space-y-1 text-2xs font-medium text-gray-400"
      >
        <MediaDirectory
          v-for="directory in directories"
          :key="directory.id"
          :directory="directory"
          :directory-mode="props.directoryMode"
          :selected-directory="selectedDirectory"
          @select-directory="$emit('selectDirectory', $event)"
        />

        <div
          v-if="directories.length < 1"
          class="p-4 rounded bg-gray-200 text-gray-400 uppercase font-bold text-center"
        >
          No Associated {{ directoryMode }}
        </div>
      </div>
    </div>
  </div>
</template>
@voix/voix-layer/composables/voixNuxtApi
