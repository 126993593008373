<script setup lang="ts">
// TODO: Allow the slice image location to be customized - just need to
// figure out how to define that(global across whole application or locally
// in each slice ? Maybe both ?)
import { useImage } from '@vueuse/core'

import { useAnimationUtils } from '@voix/composables/useAnimationsUtils'
import type { VoixModuleOptionsInterface } from '@voix/types'
import { useRuntimeConfig } from '#imports'

const props = defineProps({
  slice: {
    type: Object as any,
    required: true,
  },
  selected: {
    type: Boolean,
    default: false,
  },
  componentName: {
    type: String,
    required: true,
  },
})

const animationUtils = useAnimationUtils()
const hasMounted = animationUtils.isMounted
const hasPreview = props.slice.preview

const runtimeConfig = useRuntimeConfig()
const voixConfig = runtimeConfig.public.voix as VoixModuleOptionsInterface
const previewDirectory = voixConfig.options.slicesPreviewDir
const previewImageUrl = runtimeConfig.public.voixEnv === 'development'
  ? `${previewDirectory}/${props.slice.preview}`
  : `/assets${previewDirectory}/${props.slice.preview}`

const slicePreviewSuggestion = `${previewDirectory}/${props.componentName}`
const slicePreviewUrl = hasPreview ? previewImageUrl : null
const slicePreviewImg = hasPreview && slicePreviewUrl ? useImage({ src: slicePreviewUrl }) : null
</script>

<template>
  <div v-if="slice">
    <Transition
      enter-active-class="transition-all duration-500"
      enter-from-class="opacity-0 scale-125"
      enter-to-class=" opacity-100 scale-100"
    >
      <div
        v-show="hasMounted"
        class="relative w-full h-64 rounded-lg overflow-hidden transition-all duration-300"
        :class="{ 'shadow-lg scale-110 z-10': selected }"
      >
        <img
          v-if="hasPreview && slicePreviewUrl && slicePreviewImg?.isReady.value === true"
          :src="slicePreviewUrl"
          :alt="`${props.slice.name} Slice Preview`"
          class="absolute inset-0"
        >
        <div
          v-else
          class="absolute inset-0 bg-gray-200 text-gray-500 flex justify-center items-center text-center leading-6"
        >
          <span v-if="hasPreview && slicePreviewImg?.isLoading.value === true">Loading...</span>
          <span v-else class="mx-4">No preview found at<br> <span class="bg-gray-300 text-gray-700 p-1 rounded break-words">{{ slicePreviewSuggestion }}(.jpg | .webp | .png | .gif)</span></span>
        </div>
      </div>
    </Transition>
    <div class="mt-2 flex justify-between">
      <div class="font-medium text-xs">
        {{ slice?.name.label }}
      </div>
      <!-- TODO: Need icons for what you can do for this slice -->
      <div class="flex space-x-3 text-gray-400" />
    </div>
  </div>
</template>
