<script setup lang="ts">
import { useDebounceFn } from '@vueuse/core'

import SectionLabel from '@voix/components/chrome/SectionLabel.vue'

const props = defineProps<{
  modelValue: string
  label: string
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void
}>()

const updateColor = useDebounceFn(
  (e) => {
    emit('update:modelValue', (e.target as HTMLInputElement).value)
  },
  10,
  { maxWait: 50 },
)
</script>

<template>
  <div>
    <SectionLabel v-if="props.label">
      {{ props.label }}
    </SectionLabel>
    <div class="flex space-x-2">
      <div class="relative w-6 h-6 rounded-full overflow-hidden">
        <div
          class="w-6 h-6 rounded-full border border-gray-300 pointer-events-none"
          :style="{ backgroundColor: props.modelValue }"
        />
        <div
          v-if="!props.modelValue"
          class="w-full border-b-4 border-red-600 rotate-45 -translate-y-3.5 pointer-events-none"
        />
        <input
          type="color"
          class="absolute w-12 h-12 inset-0 rounded-full appearance-none opacity-0"
          :value="props.modelValue"
          @input="updateColor"
        >
      </div>
      <button
        class="rounded-lg uppercase bg-gray-100 text-gray-400 text-xs font-bold px-2"
        @click="emit('update:modelValue', '')"
      >
        Clear
      </button>
    </div>
  </div>
</template>
