<script setup lang="ts">
import { computed, inject, ref, watch } from 'vue'

import type { PropType } from 'vue'
import type { VoixMediaImage, VoixMediaVideo } from '@voix/types'

import SpinnerLoader from '@voix/components/chrome/Loader.vue'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { mediaSelected } from '@voix/composables/queries/useQueryMedia'
import ImageTags from './../Tags.vue'

const props = defineProps({
  media: {
    type: Object as PropType<VoixMediaImage | VoixMediaVideo>,
    required: true,
  },
  provider: {
    type: String,
    required: true,
  },
  selectedFileId: {
    type: String,
    default: null,
  },
  viewMode: {
    type: String as PropType<'grid' | 'list'>,
    required: true,
  },
})

const emit = defineEmits(['select', 'deselect'])

const breakpointNames = {
  'default': 'Smallest',
  'sm': 'Mobile',
  'md': 'Mobile/Tablet',
  'lg': 'Tablet/Desktop',
  'xl': 'Large Desktop',
  '2xl': 'XL Desktop',
}

const previewLoaded = ref(false)
const mediaLoaded = ref(false)

const fieldConfiguration = inject('fieldConfiguration', null)
const selectMedia = inject('selectMedia')

const isSelected = computed(() => {
  return props.selectedFileId === props.media.id
})

function requestSelect() {
  if (!isSelected.value)
    emit('select', props.media.id)
}

function requestDeselect() {
  emit('deselect')
}

async function onMediaSelect(breakpointName) {
  const { data } = await mediaSelected({
    id: props.media.id,
    client: props.provider,
    url: props.media.url,
  })
  console.log('onMediaSelect2', data)

  const mediaData = Object.assign({ final_url: data.value.url }, props.media)
  selectMedia(breakpointName, mediaData)
}
</script>

<template>
  <div class="relative">
    <button
      v-if="isSelected"
      class="absolute z-30 top-0 right-0 text-white m-6 p-1 bg-white/10 rounded transition duration-200 hover:bg-white/30"
      @click="requestDeselect"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </button>
    <div>
      <div
        class="w-full px-3 py-3 rounded-lg text-sm text-gray-500"
        :class="{
          'hover:bg-gray-100 cursor-pointer': !isSelected,
          'grid grid-cols-12 gap-4': viewMode === 'list' && !isSelected,
        }"
        @click="requestSelect"
      >
        <div
          class="relative overflow-hidden rounded flex-none bg-gray-800"
          :class="{
            'h-32 w-full': !isSelected && viewMode === 'grid',
            'w-16 h-16 col-span-2': !isSelected && viewMode === 'list',
            'h-96 w-full': isSelected,
          }"
        >
          <div
            v-if="!previewLoaded && !isSelected"
            class="pointer-events-none absolute inset-0 flex justify-center items-center opacity-75"
          >
            <SpinnerLoader class="bg-white" />
          </div>

          <template v-if="media.type === 'image'">
            <div
              v-if="!mediaLoaded && isSelected"
              class="pointer-events-none absolute inset-0 flex justify-center items-center opacity-75"
            >
              <SpinnerLoader class="bg-white" />
            </div>
            <!-- Preview Thumb -->
            <NuxtImg
              v-if="!isSelected"
              :src="props.media.thumb"
              :alt="props.media.id"
              class="w-full h-full transition duration-1000"
              :class="{
                'object-cover': !isSelected,
                'object-contain': isSelected,
                'opacity-0': !previewLoaded,
                'opacity-100': previewLoaded,
              }"
              @load="previewLoaded = true"
            />
            <!-- Full Preview -->
            <NuxtImg
              v-if="isSelected"
              :src="isSelected ? props.media.url : props.media.thumb"
              :alt="props.media.id"
              class="w-full h-full transition duration-1000"
              :class="{
                'object-cover': !isSelected,
                'object-contain': isSelected,
                'opacity-0': !mediaLoaded,
                'opacity-100': mediaLoaded,
              }"
              @load="mediaLoaded = true"
            />
          </template>
          <template v-if="media.type === 'video'">
            <img
              v-if="!isSelected"
              :src="props.media.thumb"
              :alt="props.media.id"
              class="w-full h-full object-cover transition duration-1000"
              :class="{
                'opacity-0': !previewLoaded,
                'opacity-100': previewLoaded,
              }"
              @load="previewLoaded = true"
            >

            <video
              v-if="isSelected"
              :src="props.media.url"
              :alt="props.media.id"
              class="w-full h-full object-contain"
              controls
              autoplay
              muted
            />
          </template>
          <span class="px-1 text-2xs rounded bg-white voix-admin-text absolute bottom-2 right-2">
            {{ props.media?.mime_type ? props.media.mime_type : 'Unknown' }}
          </span>
        </div>
        <div
          class="flex items-center justify-between"
          :class="{
            'mt-2.5 ': viewMode === 'grid' || isSelected,
            'col-span-10': viewMode === 'list',
          }"
        >
          <div
            class="voix-admin-text truncate flex items-center font-medium"
            :class="{
              'text-left': !isSelected && viewMode === 'list',
              'text-xs': !isSelected,
              'text-lg': isSelected,
            }"
          >
            {{ props.media?.name ? props.media.name : props.media.id }}
          </div>
          <div
            v-if="viewMode === 'list' && !isSelected"
            class="flex items-center"
          >
            {{ props.media.width }} x {{ props.media.height }}
          </div>

          <div class="relative">
            <button
              v-if="isSelected && !fieldConfiguration"
              class="relative rounded-full flex justify-between space-x-3 voix-admin-bg text-white px-5 py-3 text-xs font-bold"
              @click="onMediaSelect('default')"
            >
              <span v-if="props.media.type === 'image'">Use Image </span>
              <span v-if="props.media.type === 'video'">Use Video </span>
            </button>

            <Menu v-if="isSelected && fieldConfiguration">
              <MenuButton
                class="relative rounded-full flex justify-between space-x-3 voix-admin-bg text-white px-5 py-3 text-xs font-bold"
              >
                <span v-if="props.media.type === 'image'">Use Image </span>
                <span v-if="props.media.type === 'video'">Use Video </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="4"
                  stroke="currentColor"
                  class="w-4 h-4"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                  /></svg>
              </MenuButton>
              <Transition
                enter-active-class="transition duration-100 ease-out"
                enter-from-class="transform scale-95 opacity-0"
                enter-to-class="transform scale-100 opacity-100"
                leave-active-class="transition duration-75 ease-out"
                leave-from-class="transform scale-100 opacity-100"
                leave-to-class="transform scale-95 opacity-0"
              >
                <MenuItems
                  class="absolute z-30 top-0 right-0 transform translate-y-14 rounded-lg bg-white flex flex-col shadow-lg w-56"
                >
                  <MenuItem
                    v-for="(
                      breakpoint, breakpointName
                    ) in fieldConfiguration.breakpoints"
                    :key="breakpointName"
                    v-slot="{ active }"
                    class="px-4 py-2"
                  >
                    <button
                      class="whitespace-nowrap"
                      :class="{ 'voix-admin-bg-lightest': active }"
                      @click="onMediaSelect(breakpointName)"
                    >
                      For
                      <span class="font-bold">{{ breakpointNames[breakpointName] }}</span>
                      and Up
                    </button>
                  </MenuItem>
                </MenuItems>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>

      <div
        v-if="isSelected"
        class="mt-4 w-full text-left"
      >
        <ImageTags class="col-span-4" :tags="props.media.tags" />
      </div>
    </div>
  </div>
</template>
