<script setup lang="ts">
import PropertiesPopupPanel from '@voix/components/chrome/PropertiesPopupPanel.vue'
import { computed, inject, ref, watch } from 'vue'
import type { Ref } from 'vue'

import CheckboxInput from '../../../chrome/controls/CheckboxInput.vue'
import DropdownSelect from '../../../chrome/controls/DropdownSelect.vue'
import MediaDirectories from './MediaDirectories.vue'
import ProviderSelector from './ProviderSelector.vue'
import MediaSelect from './MediaSelect.vue'
import LocalFileSelector from './LocalFileSelector.vue'
import { useCookie, useNuxtApp } from '#imports'

const emit = defineEmits(['close'])

const { $voix } = useNuxtApp()
const mode = inject<'sidebar' | 'modal'>('mediaManagerDisplayMode', 'sidebar')
const selectedDirectory = ref('')

const provider: Ref<string | null> = inject('provider', ref(null))

const directoryMode = computed(() => {
  if (provider.value && $voix.options.mediaProviderConfig[provider.value]?.hierarchyMode)
    return $voix.options.mediaProviderConfig[provider.value].hierarchyMode

  else
    return 'tags'
})

const rememberMediaDirectory: { value: Array<{ title: string; id: string }> } = useCookie('voix-media-directory')
const breadCrumbDirectories: { value: Array<{ title: string; id: string }> } = useCookie('voix-media-breadcrumb-directories')
const rememberMediaProvider: { value: string } = useCookie('voix-media-provider')
const shouldRememberMediaDirectory: { value: boolean } = ref(rememberMediaDirectory.value?.length > 0)

function selectDirectory(directory: { title: string; id: string }) {
  if (directory?.id) {
    if (shouldRememberMediaDirectory.value) {
      if (
        !rememberMediaDirectory.value
      || typeof rememberMediaDirectory.value !== 'object'
      || directoryMode.value === 'tags'
      )
        rememberMediaDirectory.value = []

      rememberMediaDirectory.value.push(directory)
      if (provider.value)
        rememberMediaProvider.value = provider.value
    }

    breadCrumbDirectories.value.push(directory)
    selectedDirectory.value = directory.id
  }
  else {
    goToHomeDirectory()
  }
}

function refreshDirectory() {
  if (selectedDirectory.value)
    selectDirectory({ title: '', id: selectedDirectory.value })
}

function goToHomeDirectory() {
  rememberMediaDirectory.value = []
  breadCrumbDirectories.value = []
  selectedDirectory.value = ''
}

watch(provider, (newValue, oldValue) => {
  if (provider.value)
    rememberMediaProvider.value = provider.value
  if (oldValue)
    goToHomeDirectory()
})

const breadcrumbOptions = computed(() => {
  const options = [{
    label: 'Home',
    value: '',
    key: '',
  }]
  if (breadCrumbDirectories.value) {
    options.push(...breadCrumbDirectories.value.map((directory) => {
      return {
        label: directory.title,
        value: directory.id,
        key: directory.id,
      }
    }))
  }

  return options
})

function selectFromBreadcrumbs(directoryId: string) {
  if (directoryId) {
    const selectedBreadcrumbDirectory = breadCrumbDirectories.value?.find(directory => directory.id === directoryId)
    if (selectedBreadcrumbDirectory) {
      const index = breadCrumbDirectories.value.indexOf(selectedBreadcrumbDirectory)
      breadCrumbDirectories.value = breadCrumbDirectories.value.slice(0, index + 1)
      selectedDirectory.value = selectedBreadcrumbDirectory.id
    }
  }
  else {
    goToHomeDirectory()
  }
}

if (rememberMediaProvider.value)
  provider.value = rememberMediaProvider.value

if (rememberMediaDirectory.value) {
  const lastDirectoryOnMemoryStack = rememberMediaDirectory.value[rememberMediaDirectory.value.length - 1]
  selectedDirectory.value = lastDirectoryOnMemoryStack ? lastDirectoryOnMemoryStack.id : ''

  breadCrumbDirectories.value = rememberMediaDirectory.value
}
</script>

<template>
  <div
    :class="{
      'fixed z-50 inset-0 flex justify-center items-center': mode === 'modal',
    }"
  >
    <div class="bg-white/75 absolute inset-0" @click="$emit('close')" />
    <PropertiesPopupPanel
      :class="{
        'w-[500px] lg:w-[700px] 2xl:w-[900px] min-h-screen': mode === 'sidebar',
        'relative z-20 w-5/6 h-[75vh]': mode === 'modal',
      }"
    >
      <div class="flex items-stretch h-full">
        <div class="border-r border-gray-100 w-[200px] bg-gray-50">
          <ProviderSelector />

          <template v-if="directoryMode === 'directories' || directoryMode === 'tags'">
            <div class="flex space-x-2 voix-admin-bg-lightest px-4 py-2 border-b voix-admin-border-lightest">
              <CheckboxInput
                :selected="shouldRememberMediaDirectory.value"
                @click="shouldRememberMediaDirectory.value = !shouldRememberMediaDirectory.value"
              />
              <span class="text-2xs font-bold uppercase voix-admin-text-dark">Remember Location</span>
            </div>

            <DropdownSelect id="media-manager-breadcrumb-selector" :model-value="selectedDirectory" class="m-2" :options="breadcrumbOptions" placeholder="Breadcrumbs" @update:model-value="selectFromBreadcrumbs" />

            <MediaDirectories
              :selected-directory="selectedDirectory"
              :directory-mode="directoryMode"
              @select-directory="selectDirectory"
              @refresh-directory="refreshDirectory"
            />
          </template>
        </div>
        <MediaSelect
          v-if="directoryMode !== 'local'"
          :selected-directory="selectedDirectory"
          :directory-mode="directoryMode"
          class="flex-1"
          :class="{ 'w-[75vw]': mode === 'sidebar' }"
        />
        <div v-else-if="directoryMode === 'local'" class="flex-1 bg-white h-[100vh] p-8" :class="{ 'w-[75vw]': mode === 'sidebar' }">
          <LocalFileSelector />
        </div>

        <div
          class="pointer-events-none absolute inset-0 flex justify-center items-end"
        >
          <button
            class="pointer-events-auto mb-12 bg-white rounded-full py-2 px-5 flex justify-center items-center space-x-3 text-gray-600 shadow-xl"
            @click="emit('close')"
          >
            <span class="uppercase text-sm font-bold">Close</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="4"
              stroke="currentColor"
              class="w-5 h-5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      </div>
    </PropertiesPopupPanel>
  </div>
</template>
