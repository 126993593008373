import { $voixNuxtApi } from '@voix/voix-layer/composables/voixNuxtApi'

interface QueryArgs {
  filter: string
  orderBy: Array<object>
  first: number
  page: number
}

interface ModelArgs {
  first: number
  page: number
}

// Will return a model or a redirect
// Contains .model and .redirect - one will be null

async function getModels(variables: ModelArgs) {
  const request = await $voixNuxtApi(
    `/api/voix/models`,
  )

  return request
}

async function getModelData(query: string, variables: QueryArgs) {
  const params = new URLSearchParams(Object.assign({}, variables))
    .toString()
  const url = `${query}?${params.toString()}`

  const request = await $voixNuxtApi(url)

  return request
}

export { getModels, getModelData }
