<script setup lang="ts">
// TODO: Have a small editor on the panel with the option of launching a larger editor in the popup.
import { useField } from '@voix/composables/useField'

import PropertiesPopupPanel from '@voix/components/chrome/PropertiesPopupPanel.vue'
import WysiwygEditor from '@voix/components/chrome/wysiwyg/WysiwygEditor.vue'
import VoixFieldHeader from '@voix/voix-layer/components/VoixFieldHeader.vue'

import type { PropType } from 'vue'
import type {
  FieldConfigInterface,
  FieldInterface,
  SliceInterface,
} from '@voix/types'

const props = defineProps({
  element: {
    type: Object as PropType<SliceInterface>,
    required: true,
  },

  fieldData: {
    type: Object as PropType<FieldInterface>,
    default: null,
  },

  fieldConfiguration: {
    type: Object as PropType<FieldConfigInterface>,
    required: true,
  },
})

const { localValue, enabled } = useField(props)
</script>

<template>
  <PropertiesPopupPanel class="min-w-[400px] text-xs text-gray-500">
    <div class="p-4 pb-0">
      <VoixFieldHeader v-model="enabled" :field-configuration="props.fieldConfiguration" />
    </div>

    <WysiwygEditor v-model="localValue" class="m-3" />
  </PropertiesPopupPanel>
</template>
