<script lang="ts">
import { computed, defineComponent } from 'vue'
import { useAdminStore } from '@voix/store/adminStore'

import type {
  FieldConfigInterface,
  FieldInterface,
  SliceInterface,
} from '@voix/types'

import FieldIcon from '../FieldIcon.vue'

export default defineComponent({
  components: {
    FieldIcon,
  },
  props: {
    slice: {
      type: Object as () => SliceInterface,
      required: true,
    },
    fieldName: {
      type: String,
      required: true,
    },
    fieldData: {
      type: Object as () => FieldInterface,
      default: {} as FieldInterface,
    },
    fieldConfiguration: {
      type: Object as () => FieldConfigInterface,
      required: true,
    },
  },
  emits: ['selectElement'],
  setup(props) {
    const adminStore = useAdminStore()

    const selectField = (fieldConfiguration: FieldConfigInterface) => {
      const fullFieldConfiguration = Object.assign({}, fieldConfiguration, {
        name: props.fieldName,
      })
      adminStore.toggleSelectField(props.slice, fullFieldConfiguration)
    }

    const fieldIsSelected = computed(() => {
      return (
        adminStore.selectedFieldSlice
          && adminStore.selectedFieldSlice === props.slice
          && adminStore.selectedField
          && adminStore.selectedField.label === props.fieldConfiguration.label
      )
    })

    return { adminStore, props, selectField, fieldIsSelected }
  },
})
</script>

<template>
  <div>
    <button
      class="w-full outline-none"
      @click.left.exact.stop="selectField(props.fieldConfiguration)"
    >
      <div
        class="w-full flex items-center py-2.5 px-4 transition-colors duration-200"
        :class="[
          {
            'voix-admin-bg-light voix-admin-text-darkest': fieldIsSelected,
            'voix-admin-text hover:voix-admin-text-darkest':
              !fieldIsSelected,
          },
        ]"
      >
        <FieldIcon :type="props.fieldConfiguration.type" />
        <div class="truncate select-none text-xs">
          {{ props.fieldConfiguration.label }}
        </div>
      </div>
    </button>
  </div>
</template>
