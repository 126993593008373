<script setup lang="ts">
import { computed, reactive, ref } from 'vue'
import { useRoute } from 'vue-router'

import { useUtils } from '@voix/composables/useUtils'
import { useAdminStore } from '@voix/store/adminStore'
import { usePageStore } from '@voix/store/pageStore'
import { useUserStore } from '@voix/store/userStore'
import { getCurrentPage } from '@voix/composables/queries/useQueryCurrentPage'
import { deleteRelease } from '@voix/composables/queries/useQueryReleases'

import VoixModal from '@voix/components/chrome/VoixModal.vue'
import PageNavigation from '@voix/components/chrome/pages/PageNavigation.vue'
import VoixIframePageViewer from '@voix/components/viewer/VoixIframePageViewer.vue'
import PropertyInspector from '@voix/components/chrome/PropertyInspector.vue'
import { inject, navigateTo, onBeforeUnmount } from '#imports'

const props = defineProps({
  currentScale: {
    type: Number,
    required: true,
  },
})
const utils = useUtils()
const route = useRoute()
const adminStore = useAdminStore()
const pageStore = usePageStore()
const userStore = useUserStore()

const currentUser = userStore.currentUser
const isBrowser = typeof window !== 'undefined'

const viewers = ref<Array<string>>([])

const query = route.query

setTimeout(() => {
  if (query.editing) {
    if (typeof query.editing === 'string')
      setCurrentPage(query.editing)
  }

  if (viewers.value.length === 0)
    addViewer()
}, 1)

onBeforeUnmount(() => {
  adminStore.unsetSelectedElement()
})

const currentPageQuery: any = ref(null)
inject('currentPageQuery', currentPageQuery)

function setCurrentPage(path: string) {
  getCurrentPage(
    reactive({ path }),
  ).then((query) => {
    currentPageQuery.value = query
    navigateTo({
      name: 'voix-studio',
      query: {
        editing: path,
      },
    })
  })
}

const isViewingRelease = computed(() => adminStore.releaseId !== null)
const draftReleaseId = computed(() => pageStore.currentPage?.draft_release?.id)
const showPendingDraftWarning = computed(() => {
  const pageHasDraft = typeof draftReleaseId.value !== 'undefined'
  const pageInDraftModeAndNotInRelease = pageHasDraft && !isViewingRelease.value
  const pageInDraftAndCurrentReleaseDoesNotMatch = pageHasDraft && isViewingRelease.value && adminStore.releaseId !== draftReleaseId.value

  return (
    (pageInDraftModeAndNotInRelease && !adminStore.isLoading)
    || (pageInDraftAndCurrentReleaseDoesNotMatch && !adminStore.isLoading)
  )
})
const drafterName = computed(() => pageStore.currentPage?.draft_release?.user_name)
const isDrafter = computed(() => currentUser?.id.toString() === pageStore.currentPage?.draft_release?.user_id?.toString())
function requestShowDraftVersion() {
  if (draftReleaseId.value) {
    adminStore.setReleaseId(draftReleaseId.value)
    if (pageStore.currentPage)
      setCurrentPage(pageStore.currentPage.path)
  }
}

function requestDeleteDraft() {
  const draftReleaseId = pageStore.currentPage?.draft_release?.id
  if (draftReleaseId) {
    deleteRelease({
      id: draftReleaseId,
    }).then(() => {
      adminStore.unsetReleaseId()
      if (pageStore.currentPage)
        setCurrentPage(pageStore.currentPage.path)
    })
  }
}

function addViewer() {
  viewers.value.push(utils.guid())
}

function removeViewer(key: number) {
  if (viewers.value.length > 1)
    viewers.value.splice(key, 1)
}
</script>

<template>
  <div>
    <div
      v-if="isBrowser && pageStore.currentPage"
      class="flex items-center overflow-x-scroll overflow-y-hidden h-screen space-x-24 pr-80 transform-gpu duration-300"
      :class="{
        'pl-8': adminStore.currentSection !== 'editor',
        'pl-12': adminStore.currentSection === 'editor',
      }"
    >
      <VoixIframePageViewer
        v-for="(viewer, key) in viewers"
        :key="viewer"
        :key-string="viewer"
        :scale="props.currentScale"
        class="relative z-0"
        @remove="removeViewer(key)"
      />
      <button
        class="rounded-lg voix-admin-bg-light w-32 h-32 flex-none flex justify-center items-center"
        @click="addViewer"
      >
        <svg
          class="w-16 h-16 voix-admin-text"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </button>
    </div>

    <!-- Left Side Page Navigation Editor -->
    <PageNavigation
      class="transform duration-200 ease-out fixed z-20 top-0 left-0 bottom-0 shadow-2xl shadow-gray-500"
      :class="{
        'translate-x-0': !adminStore.isZenMode,
        '-translate-x-48': adminStore.isZenMode,
      }"
    />

    <!-- Right Side Properties Editor -->
    <PropertyInspector
      id="voix-property-inspector"
      class="transform duration-200 ease-out"
      :class="{
        'translate-x-72': adminStore.isZenMode,
      }"
    />

    <VoixModal :show="showPendingDraftWarning" :can-close="false">
      <div class="bg-white rounded p-4 md:w-[500px] text-base">
        <div>There is currently a draft for this page being edited by the user: <span class="font-bold voix-admin-text">{{ drafterName }}</span>. You will need them to save or discard their draft to continue.</div>
        <div class="mt-4 flex justify-end space-x-3 text-sm">
          <button v-if="isDrafter" class="p-2 px-4 rounded bg-gray-200 text-gray-800" @click="requestShowDraftVersion">
            Edit Draft
          </button>
          <button v-if="isDrafter" class="p-2 px-4 rounded bg-red-200 text-red-800" @click="requestDeleteDraft">
            Delete the Draft
          </button>
          <button v-if="!isDrafter" class="p-2 px-4 rounded bg-gray-200 text-gray-800" @click="requestShowDraftVersion">
            View Only
          </button>
          <button v-if="!isDrafter" class="p-2 px-4 rounded bg-gray-200 text-gray-800" @click="navigateTo({ name: 'voix-settings-pages' })">
            Back to Pages
          </button>
        </div>
      </div>
    </VoixModal>
  </div>
</template>
