<script setup lang="ts">
import { computed } from 'vue'
import { useField } from '@voix/composables/useField'
import VoixFieldHeader from '@voix/voix-layer/components/VoixFieldHeader.vue'
import VoixFileSelector from '@voix/voix-layer/components/VoixFileSelector.vue'
import SectionLabel from '@voix/components/chrome/SectionLabel.vue'
import VoixDrop from '@voix/components/chrome/drag-and-drop/VoixDrop.vue'
import { v4 as uuidv4 } from 'uuid'

import { dragEnd, dragStart } from '@voix/composables/useDraggable'

import PropertiesPopupPanel from '@voix/components/chrome/PropertiesPopupPanel.vue'

import type { PropType } from 'vue'
import type {
  FieldConfigInterface,
  FieldInterface,
  SliceInterface,
  VoixFilesFieldValue,
} from '@voix/types'

const props = defineProps({
  element: {
    type: Object as PropType<SliceInterface>,
    required: true,
  },

  fieldData: {
    type: Object as PropType<FieldInterface>,
    default: null,
  },

  fieldConfiguration: {
    type: Object as PropType<FieldConfigInterface>,
    required: true,
  },
})

const { localValue, enabled } = useField(props)

const files = computed(() => {
  const array = localValue.value ? localValue.value : []
  // Add uuid to each file for sorting purposes
  array.forEach((file: VoixFilesFieldValue) => {
    if (!file.id)
      file.id = uuidv4()
  })
  return array
})

function onFileUpload(response: any) {
  if (response.data.value) {
    if (!localValue.value || !localValue.value)
      localValue.value = []

    // See if the file is already in the list
    const existingFile = localValue.value.find((
      file: { path: string, url: string },
    ) => file.url === response.data.value.url)

    if (existingFile)
      return

    localValue.value.push({
      url: response.data.value.url,
      type: response.file.type,
      provider: 'local',
    })
  }
}

function removeFile(file: { path: string, url: string }) {
  if (!localValue.value)
    return

  const index = localValue.value.findIndex((f: { path: string, url: string }) => f.url === file.url)

  if (index === -1)
    return

  localValue.value.splice(index, 1)
}

function isAnImage(file: { provider: string, type: string, url: string }) {
  // Determine if the file is an image based on it's url
  return file.type.startsWith('image/')
}

// When an element is dropped at the top of the data array
function dropTopOfDataArray(id: string) {
  // find the file from localValue.value with the id passed in
  const file = localValue.value.find((f: { id: string }) => f.id === id)
  // Move that file to the top of the array
  if (file) {
    const index = localValue.value.indexOf(file)
    localValue.value.splice(index, 1)
    localValue.value.unshift(file)
  }
}

// When an element is dropped in the middle of the data array on any divider that
// isn't that top one. This could be in between a root level slice or inside a slice
// as a child.
function dropMiddleOfDataArray(id: string, destinationId: string) {
  const file = localValue.value.find((f: { id: string }) => f.id === id)
  // Move that file to after the destination id
  if (file) {
    const index = localValue.value.indexOf(file)
    localValue.value.splice(index, 1)
    const destinationIndex = localValue.value.findIndex((f: { id: string }) => f.id === destinationId)
    localValue.value.splice(destinationIndex + 1, 0, file)
  }
}
</script>

<template>
  <PropertiesPopupPanel class="min-w-[100px] text-xs text-gray-500 p-4 max-h-screen">
    <VoixFieldHeader v-model="enabled" :field-configuration="props.fieldConfiguration" />

    <div class="flex flex-col space-y-4">
      <VoixFileSelector :multiple="fieldConfiguration.multiple" @file-upload="onFileUpload" />
    </div>

    <SectionLabel v-if="files.length > 0" class="mt-4">
      Current Files
    </SectionLabel>

    <VoixDrop
      v-slot="{ item }"
      :entire-data-array="files"
      :data-array="files"
      class="grid grid-cols-3 gap-3"
      @drop-at-top="dropTopOfDataArray"
      @drop-in-middle="dropMiddleOfDataArray"
    >
      <div
        :id="item.id"
        :key="item.id"
        class="relative flex-none rounded overflow-hidden voix-admin-bg-lightest w-20 h-20 flex items-center justify-center"
        draggable="true"
        @dragstart="dragStart($event, `${item.id}`)"
        @dragend="dragEnd"
      >
        <img v-if="isAnImage(item)" :src="item.url" class="w-20 h-20 object-cover">
        <Icon v-else name="heroicons:document" class="w-10 h-10 object-cover voix-admin-text-dark" />

        <button class="absolute top-0 right-0 voix-admin-text m-0.5 bg-white w-5 h-5 rounded-full flex justify-center items-center" @click="removeFile(file)">
          <Icon name="heroicons:trash" class="w-3 h-3" />
        </button>
      </div>
    </VoixDrop>
  </PropertiesPopupPanel>
</template>
