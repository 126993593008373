<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import { useField } from '@voix/composables/useField'
import TextInput from '@voix/components/chrome/controls/TextInput.vue'
import SwitchInput from '@voix/components/chrome/controls/SwitchInput.vue'
import DropdownSelect from '@voix/components/chrome/controls/DropdownSelect.vue'
import PageSelect from '@voix/components/chrome/pages/PageSelect.vue'
import PropertiesPopupPanel from '@voix/components/chrome/PropertiesPopupPanel.vue'
import VoixFieldHeader from '@voix/voix-layer/components/VoixFieldHeader.vue'

import type { PropType } from 'vue'
import type { FieldInterface, SliceInterface } from '@voix/types'
import type { VoixLinkFieldConfigurationInterface } from './types'

const props = defineProps({
  element: {
    type: Object as PropType<SliceInterface>,
    required: true,
  },

  fieldData: {
    type: Object as PropType<FieldInterface>,
    default: null,
  },

  fieldConfiguration: {
    type: Object as PropType<VoixLinkFieldConfigurationInterface>,
    required: true,
  },
})

const { localValue, enabled } = useField(props)

const href = computed({
  get: (): string => {
    return localValue.value ? localValue.value.href : ''
  },
  set(value: string) {
    localValue.value = {
      ...localValue.value,
      href: value,
    }
  },
})

const url = computed({
  get: (): string => {
    return localValue.value?.url ? localValue.value.url : ''
  },
  set(value: string) {
    localValue.value = {
      ...localValue.value,
      url: value,
    }
  },
})

watch(url, () => {
  href.value = url.value
})

const pageId = computed({
  get: (): string => {
    return localValue.value ? localValue.value.page_id : ''
  },
  set(value: string) {
    localValue.value = {
      ...localValue.value,
      page_id: value,
    }
  },
})

// Page URL to set on href when it's changed
const pageUrl = ref('')
watch(pageUrl, () => {
  href.value = pageUrl.value
})

const mode = computed({
  get: (): string => {
    return localValue.value?.mode ? localValue.value.mode : 'page'
  },
  set(value: string) {
    localValue.value = {
      ...localValue.value,
      mode: value,
    }
  },
})

watch(mode, () => {
  if (mode.value === 'url')
    href.value = url.value

  else if (mode.value === 'page')
    href.value = pageUrl.value
})

const text = computed({
  get: (): string => {
    return localValue.value ? localValue.value.text : ''
  },
  set(value: string) {
    localValue.value = {
      ...localValue.value,
      text: value,
    }
  },
})

const relationship = computed({
  get: (): string => {
    return localValue.value?.relationship ? localValue.value.relationship : ''
  },
  set(value: string) {
    localValue.value = {
      ...localValue.value,
      relationship: value,
    }
  },
})
const relationOptions = [
  {
    label: 'Choose a relation',
    value: '',
    key: 'null',
  },
  {
    label: 'No Follow',
    value: 'nofollow',
    key: 'nofollow',
  },
  {
    label: 'No Opener',
    value: 'noopener',
    key: 'noopener',
  },
  { label: 'No Referrer', value: 'noreferrer', key: 'noreferrer' },
  {
    label: 'Alternate',
    value: 'alternate',
    key: 'alternate',
  },
]

const target = computed({
  get: (): string => {
    return localValue.value?.target ? localValue.value.target : '_self'
  },
  set(value: string) {
    localValue.value = {
      ...localValue.value,
      target: value,
    }
  },
})

const targetOptions = [
  {
    label: 'Self',
    value: '_self',
    key: '_self',
  },
  {
    label: 'Blank',
    value: '_blank',
    key: '_blank',
  },
  {
    label: 'Parent',
    value: '_parent',
    key: '_parent',
  },
  {
    label: 'Top',
    value: '_top',
    key: '_top',
  },
]

function switchMode() {
  if (mode.value === 'page')
    mode.value = 'url'
  else
    mode.value = 'page'
}

if (!localValue.value) {
  localValue.value = {
    href: '',
    url: '',
    page_id: '',
    mode: 'page',
    text: '',
    relationship: '',
    target: '_self',
  }
}

if (!localValue.value.href)
  href.value = ''
if (!localValue.value.url)
  url.value = ''
if (!localValue.value.page_id)
  pageId.value = ''
if (!localValue.value.mode)
  mode.value = ''
if (!localValue.value.text)
  text.value = ''
if (!localValue.value.relationship)
  relationship.value = ''
if (!localValue.value.target)
  target.value = '_self'
</script>

<template>
  <PropertiesPopupPanel class="min-w-[400px] text-xs text-gray-500 p-4">
    <VoixFieldHeader v-model="enabled" :field-configuration="props.fieldConfiguration" />

    <div>
      <SwitchInput :model-value="mode === 'page'" label="Use page link (suggested)" @click="switchMode" />

      <TextInput
        id="element-permissions"
        v-model="text"
        :placeholder="props.fieldConfiguration.label"
        class="mt-4"
        label="Label"
      />

      <PageSelect v-if="mode === 'page'" v-model="pageId" v-model:page-url="pageUrl" label="Page" class="mt-4" />

      <TextInput
        v-else
        id="element-permissions"
        v-model="url"
        placeholder="https://example.com"
        class="mt-4"
        label="Url"
      />

      <DropdownSelect
        id="link-relation"
        v-model="relationship"
        label="Relation"
        :options="relationOptions"
        placeholder="Link Relation"
        class="mt-4"
      />

      <DropdownSelect
        id="link-target"
        v-model="target"
        label="Target"
        :options="targetOptions"
        placeholder="Link Target"
        class="mt-4"
      />
    </div>
  </PropertiesPopupPanel>
</template>
