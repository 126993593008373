<script setup lang="ts">
import type { SettingsInterface } from '@voix/types'
import { computed, ref } from 'vue'

import DropdownSelect from '@voix/components/chrome/controls/DropdownSelect.vue'

import { breakpointOptions } from '@voix/composables/useBreakpoints'
import SectionLabel from '../SectionLabel.vue'

const props = defineProps<{
  settings?: Array<SettingsInterface>
}>()

const emit = defineEmits(['update:settings'])

const localSettings = computed({
  get: () => props.settings,
  set: value => emit('update:settings', value),
})

const sliceBreakpointOptions = computed(() =>
  props.settings ? breakpointOptions(props.settings) : [],
)

const selectedBreakpoint = ref<string | null>(null)
const filteredBreakpoint = computed(() => {
  return props.settings?.find(
    bp => bp.breakpoint === selectedBreakpoint.value,
  )
})

function addBreakpoint() {
  if (selectedBreakpoint.value) {
    const settingsClone = localSettings.value ? localSettings.value : []

    settingsClone.push({
      breakpoint: selectedBreakpoint.value,
      padding: {},
      margin: {},
    })
    emit('update:settings', settingsClone)
  }
}

function removeBreakpoint(breakpoint: SettingsInterface | undefined) {
  if (breakpoint) {
    const settingsClone = localSettings.value ? localSettings.value : []
    const breakpointIndex = settingsClone.findIndex(
      bp => bp.breakpoint === breakpoint.breakpoint,
    )

    settingsClone.splice(breakpointIndex, 1)
    emit('update:settings', settingsClone)
  }
}

// Find the default breakpoint:
const defaultBreakpoint = props.settings?.find(
  bp => bp.breakpoint === 'default',
)

// If it doesn't exist, add it:
if (!defaultBreakpoint) {
  selectedBreakpoint.value = 'default'
  addBreakpoint()
}

selectedBreakpoint.value = 'default'
</script>

<template>
  <div>
    <SectionLabel>Breakpoint Settings</SectionLabel>
    <div class="flex justify-between space-x-4">
      <DropdownSelect
        id="grid-breakpoint-to-add" v-model="selectedBreakpoint" :options="sliceBreakpointOptions"
        placeholder="Select a Breakpoint to Add" class="flex-1"
      />
    </div>
    <div class="">
      <div v-if="filteredBreakpoint">
        <button
          v-if="filteredBreakpoint && filteredBreakpoint.breakpoint !== 'default'
          " class="mt-1 bg-gray-200 p-2.5 px-2 rounded flex items-center text-xs text-gray-500 w-full"
          @click="removeBreakpoint(filteredBreakpoint)"
        >
          Disable This Breakpoint ({{ filteredBreakpoint.breakpoint }})
        </button>
        <div class="mt-5">
          <slot :breakpoint="filteredBreakpoint" />
        </div>
      </div>
      <div v-else>
        <button
          class="mt-1 bg-gray-200 p-2.5 px-2 rounded flex items-center text-xs text-gray-500 w-full"
          @click="addBreakpoint"
        >
          Add Breakpoint
        </button>
      </div>
    </div>
  </div>
</template>
