import { useAdminStore } from './../store/adminStore'

function dragStart(event: DragEvent, itemId: string) {
  // We need to set the dataTransfer on the event so that the drag and drop
  // system knows what to do with the event.
  if (event.dataTransfer) {
    event.dataTransfer.dropEffect = 'move'
    event.dataTransfer.effectAllowed = 'move'
    event.dataTransfer.setData('elementId', itemId.toString())

    // Create a ghost element to show the user what they are dragging clearly
    const ghostEle = document.getElementById(
      event.dataTransfer.getData('elementId'),
    )

    if (ghostEle) {
    // Customize the drag image
      event.dataTransfer.setDragImage(ghostEle, 0, 0)
    }

    const adminStore = useAdminStore()
    adminStore.isDragging = true
  }
}

function dragEnd() {
  console.log('here')
  const adminStore = useAdminStore()
  adminStore.isDragging = false
}

export { dragStart, dragEnd }
