<script lang="ts">
import { computed, defineComponent } from 'vue'
import { useBreakpointStore } from '../../store/breakpointStore'
import {
  getApplicableBreakpoints,
  getCurrentBreakpoint,
} from '../../composables/useBreakpoints'

export default defineComponent({
  props: {
    viewerWidth: {
      type: Number,
      required: true,
    },
  },
  emits: ['resize'],
  setup(props) {
    const breakpointStore = useBreakpointStore()

    const currentBreakpoint = computed(() => {
      return getCurrentBreakpoint(props.viewerWidth)
    })

    const applicableBreakpoints = computed(() => {
      return getApplicableBreakpoints(props.viewerWidth)
    })

    const breakpoints = computed(() => {
      return breakpointStore.getAllBreakpoints.filter(
        breakpoint => breakpoint.name !== 'default',
      )
    })

    return { currentBreakpoint, applicableBreakpoints, breakpoints }
  },
})
</script>

<template>
  <div v-if="currentBreakpoint" class="flex justify-center items-start">
    <div
      class="px-2 voix-admin-border voix-admin-text"
      :class="{
        'border-b-2 ': currentBreakpoint.name === 'default',
        'opacity-50': currentBreakpoint.name !== 'default',
      }"
    >
      <button @click="$emit('resize', 380)">
        <svg
          class="w-6 h-6 "
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
          />
        </svg>
        <span class="block mt-1 text-[8px] uppercase voix-admin-text">ALL</span>
      </button>
    </div>
    <div
      v-for="(breakpoint, key) in breakpoints"
      :key="key"
      class="px-2 voix-admin-border voix-admin-text"
      :class="{
        'border-b-2': breakpoint.name === currentBreakpoint.name,
        'opacity-50': breakpoint.name !== currentBreakpoint.name,
      }"
    >
      <button @click="$emit('resize', breakpoint.minWidth)">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="breakpoint.icon" />

        <span class="block mt-1 text-[8px] uppercase voix-admin-text">
          {{ breakpoint.name }}
        </span>
      </button>
    </div>
  </div>
</template>
