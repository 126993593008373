<script setup lang="ts">
import { computed } from 'vue'
import type { PropType } from 'vue'
import type { VoixMediaDirectory } from '@voix/types'

const props = defineProps({
  directory: {
    type: Object as PropType<VoixMediaDirectory>,
    required: true,
  },
  selectedDirectory: {
    type: String,
    required: false,
    default: null,
  },
  directoryMode: {
    type: String,
    required: true,
  },
})

const emit = defineEmits(['selectDirectory'])

const isTheSelectedDirectory = computed(() => {
  return (
    props.selectedDirectory
      && props.selectedDirectory === props.directory.id
  )
})

function selectDirectory(directory: VoixMediaDirectory) {
  emit('selectDirectory', directory)
}
</script>

<template>
  <div class="select-none">
    <div
      class="cursor-pointer flex  px-1 py-1 rounded"
      :class="{
        'space-x-2': directoryMode === 'directories',
        'voix-admin-text-lightest voix-admin-text': directoryMode === 'directories' && isTheSelectedDirectory,
        'px-3 py-1 rounded-full bg-gray-200 text-gray-600': directoryMode === 'tags' && !isTheSelectedDirectory,
        'px-3 py-1 rounded-full bg-emerald-200 text-emerald-800': directoryMode === 'tags' && isTheSelectedDirectory,
      }"
      @click="selectDirectory(props.directory)"
    >
      <div class="flex-none">
        <svg
          v-if="directoryMode === 'directories'"
          xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4"
          :class="{
            ' voix-admin-text': isTheSelectedDirectory,
          }"
        >
          <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12.75V12A2.25 2.25 0 014.5 9.75h15A2.25 2.25 0 0121.75 12v.75m-8.69-6.44l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z" />
        </svg>
      </div>

      <span class="truncate">{{ props.directory.title }}</span>
    </div>
  </div>
</template>
