<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref, watch } from 'vue'

import { Editor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import Typography from '@tiptap/extension-typography'
import Link from '@tiptap/extension-link'
import TextAlign from '@tiptap/extension-text-align'
import Underline from '@tiptap/extension-underline'
import Youtube from '@tiptap/extension-youtube'
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import type { Level } from '@tiptap/extension-heading'

import TextInput from '@voix/components/chrome/controls/TextInput.vue'
import DropdownSelect from '@voix/components/chrome/controls/DropdownSelect.vue'
import SwitchInput from '../controls/SwitchInput.vue'
import { FontSize } from './extensions/font-size'

const props = defineProps<{
  modelValue: string | null
}>()
const emit = defineEmits(['update:modelValue'])

const editor = ref<Editor>()

onMounted(() => {
  if (!props.modelValue)
    emit('update:modelValue', '')

  editor.value = new Editor({
    extensions: [
      StarterKit.configure({
        heading: {
          levels: [1, 2, 3],
        },
      }),
      Typography,
      Underline,
      Link.configure({
        protocols: ['mailto'],
        openOnClick: false,
        linkOnPaste: false,
        autolink: false,
      }),
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      FontSize.configure({
        types: ['heading', 'paragraph'],
      }),
      Youtube.configure({
        controls: false,
      }),
      Table.configure({
        resizable: true,
        HTMLAttributes: {
          class: 'table-auto',
        },
      }),
      TableRow,
      TableHeader,
      TableCell,
    ],
    content: props.modelValue,
    editorProps: {
      attributes: {
        class:
            'overflow-x-scroll  p-4 border-b-2 border-l-2 border-r-2 border-t border-gray-200 rounded-b focus:outline-none focus:ring-1 focus:ring-gray-200 focus:voix-admin-border-lightest',
      },
    },

    // triggered on every change
    onUpdate: ({ editor }) => {
      const html = editor.getHTML()
      // send the content to an API here
      emit('update:modelValue', html)
    },
  })
})

onBeforeUnmount(() => {
  if (editor.value)
    editor.value.destroy()
})

const showHeadlinesDropdown = ref(false)

function toggleHeading(level: Level) {
  if (editor.value) {
    editor.value.chain().focus().toggleHeading({ level }).run()
    showHeadlinesDropdown.value = false
  }
}

const showTextSizeDropdown = ref(false)
const newTextSize = ref(15)
const newTextSizeUnit = ref('px')

function toggleTextSize(level: Level) {
  if (editor.value) {
    editor.value.chain().focus().toggleTextSize({ level }).run()
    showTextSizeDropdown.value = false
  }
}

const showLinkDropdown = ref(false)
const newLinkUrl = ref('')
const newLinkTarget = ref('_self')

function toggleShowLinkDropdown() {
  // const previousUrl = this.editor.getAttributes('link').href
  // const previousTarget = this.editor.getAttributes('link').target

  if (editor.value) {
    newLinkUrl.value = editor.value.getAttributes('link').href
    newLinkTarget.value = editor.value.getAttributes('link').target

    showLinkDropdown.value = !showLinkDropdown.value
  }
}

function addLink() {
  if (editor.value && newLinkUrl.value) {
    editor.value
      .chain()
      .focus()
      .setLink({ href: newLinkUrl.value, target: newLinkTarget.value })
      .run()
    newLinkUrl.value = ''
    newLinkTarget.value = ''

    showLinkDropdown.value = false
  }
}

function removeLink() {
  if (editor.value) {
    editor.value.chain().focus().unsetLink().run()

    showLinkDropdown.value = false
  }
}

const showAlignmentDropdown = ref(false)

function addVideo() {
  if (editor.value) {
    // eslint-disable-next-line no-alert
    const url = prompt('Enter YouTube URL')

    if (url) {
      editor.value.commands.setYoutubeVideo({
        src: url,
        width: 640,
        height: 480,
      })
    }
  }
}

const viewSource = ref(false)
const sourceCode = ref('')
watch(viewSource, () => {
  if (editor.value) {
    if (viewSource.value)
      sourceCode.value = editor.value.getHTML()

    else
      editor.value.commands.setContent(sourceCode.value)
  }
})

function updateSourceCode(newValue: string) {
  sourceCode.value = newValue
  if (editor.value)
    editor.value.commands.setContent(newValue)

  emit('update:modelValue', newValue)
}

const showTableControlsDropdown = ref(false)
const showTableAddDropdown = ref(false)
const addTableRows = ref(0)
const addTableCols = ref(0)
const addTableWithHeader = ref(true)
const tableCommand = ref('')

// TODO: Change this to a computed property based on "can" methods
const tableCommandOptions = ref([
  {
    label: 'Add Column Before',
    value: 'addColumnBefore',
    key: 'addColumnBefore',
  },
  {
    label: 'Add Column After',
    value: 'addColumnAfter',
    key: 'addColumnAfter',
  },
  { label: 'Delete Column', value: 'deleteColumn', key: 'deleteColumn' },
  { label: 'Add Row Above', value: 'addRowBefore', key: 'addRowBefore' },
  { label: 'Add Row Below', value: 'addRowAfter', key: 'addRowAfter' },
  { label: 'Delete Row', value: 'deleteRow', key: 'deleteRow' },
  { label: 'Delete Table', value: 'deleteTable', key: 'deleteTable' },
  { label: 'Merge Cells', value: 'mergeCells', key: 'mergeCells' },
  { label: 'Split Cell', value: 'splitCell', key: 'splitCell' },
  {
    label: 'Toggle Header Column',
    value: 'toggleHeaderColumn',
    key: 'toggleHeaderColumn',
  },
  {
    label: 'Toggle Header Row',
    value: 'toggleHeaderRow',
    key: 'toggleHeaderRow',
  },
  {
    label: 'Toggle Header Cell',
    value: 'toggleHeaderCell',
    key: 'toggleHeaderCell',
  },
  { label: 'Merge or Split', value: 'mergeOrSplit', key: 'mergeOrSplit' },
])

function toggleTableDropdown() {
  if (editor.value) {
    if (showTableAddDropdown.value) {
      cancelAddTable()
    }
    else if (!editor.value.can().deleteTable()) {
      showTableAddDropdown.value = true
      addTableCols.value = 3
      addTableRows.value = 3
    }
    else {
      showTableControlsDropdown.value = !showTableControlsDropdown.value
    }
  }
}

function cancelAddTable() {
  showTableAddDropdown.value = false
  addTableCols.value = 0
  addTableRows.value = 0
}

function addTable() {
  if (editor.value) {
    editor.value.commands.insertTable({
      rows: addTableRows.value,
      cols: addTableCols.value,
      withHeaderRow: addTableWithHeader.value,
    })
  }

  showTableControlsDropdown.value = true
  cancelAddTable()
}

function executeTableCommand() {
  if (editor.value)
    editor.value.commands[tableCommand.value]().run()
}
</script>

<template>
  <div v-if="editor" class="grid">
    <ul
      class="flex rounded-t border-t-2 border-l-2 border-r-2 border-gray-200 divide-x divide-gray-200 bg-gray-200"
    >
      <li>
        <button
          class="w-9 h-8 flex items-center justify-center focus:voix-admin-bg-light focus-voix-admin-text-dark transition-all duration-100"
          :class="{
            'voix-admin-bg-lightest text-white': editor.isActive('bold'),
            'bg-gray-100 hover:bg-gray-300 text-gray-500 hover:text-gray-800 ':
              !editor.isActive('bold'),
          }"
          @click="editor.chain().focus().toggleBold().run()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="w-4 h-4"
          >
            <path d="M6 4h8a4 4 0 0 1 4 4 4 4 0 0 1-4 4H6z" />
            <path d="M6 12h9a4 4 0 0 1 4 4 4 4 0 0 1-4 4H6z" />
          </svg>
        </button>
      </li>
      <li>
        <button
          class="w-9 h-8 flex items-center justify-center focus:voix-admin-bg-light focus-voix-admin-text-dark transition-all duration-100"
          :class="{
            'voix-admin-bg-lightest text-white': editor.isActive('italic'),
            'bg-gray-100 hover:bg-gray-300 text-gray-500 hover:text-gray-800 ':
              !editor.isActive('italic'),
          }"
          @click="editor.chain().focus().toggleItalic().run()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="w-4 h-4"
          >
            <line x1="19" y1="4" x2="10" y2="4" />
            <line x1="14" y1="20" x2="5" y2="20" />
            <line x1="15" y1="4" x2="9" y2="20" />
          </svg>
        </button>
      </li>
      <li>
        <button
          class="w-9 h-8 flex items-center justify-center focus:voix-admin-bg-light focus-voix-admin-text-dark transition-all duration-100"
          :class="{
            'voix-admin-bg-lightest text-white': editor.isActive('underline'),
            'bg-gray-100 hover:bg-gray-300 text-gray-500 hover:text-gray-800 ':
              !editor.isActive('underline'),
          }"
          @click="editor.chain().focus().toggleUnderline().run()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="w-4 h-4"
          >
            <path d="M6 3v7a6 6 0 0 0 6 6 6 6 0 0 0 6-6V3" />
            <line x1="4" y1="21" x2="20" y2="21" />
          </svg>
        </button>
      </li>

      <!-- Strikethrough -->
      <li>
        <button
          class="w-9 h-8 flex items-center justify-center border-r-4 border-gray-200 focus:voix-admin-bg-light focus-voix-admin-text-dark transition-all duration-100"
          :class="{
            'voix-admin-bg-lightest text-white': editor.isActive('strike'),
            'bg-gray-100 hover:bg-gray-300 text-gray-500 hover:text-gray-800 ':
              !editor.isActive('strike'),
          }"
          @click="editor.chain().focus().toggleStrike().run()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            width="15"
            height="15"
          >
            <path
              fill="currentColor"
              d="M161.3 144c3.2-17.2 14-30.1 33.7-38.6c21.1-9 51.8-12.3 88.6-6.5c11.9 1.9 48.8 9.1 60.1 12c17.1 4.5 34.6-5.6 39.2-22.7s-5.6-34.6-22.7-39.2c-14.3-3.8-53.6-11.4-66.6-13.4c-44.7-7-88.3-4.2-123.7 10.9c-36.5 15.6-64.4 44.8-71.8 87.3c-.1 .6-.2 1.1-.2 1.7c-2.8 23.9 .5 45.6 10.1 64.6c4.5 9 10.2 16.9 16.7 23.9H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H270.1c-.1 0-.3-.1-.4-.1l-1.1-.3c-36-10.8-65.2-19.6-85.2-33.1c-9.3-6.3-15-12.6-18.2-19.1c-3.1-6.1-5.2-14.6-3.8-27.4zM348.9 337.2c2.7 6.5 4.4 15.8 1.9 30.1c-3 17.6-13.8 30.8-33.9 39.4c-21.1 9-51.7 12.3-88.5 6.5c-18-2.9-49.1-13.5-74.4-22.1c-5.6-1.9-11-3.7-15.9-5.4c-16.8-5.6-34.9 3.5-40.5 20.3s3.5 34.9 20.3 40.5c3.6 1.2 7.9 2.7 12.7 4.3l0 0 0 0c24.9 8.5 63.6 21.7 87.6 25.6l0 0 .2 0c44.7 7 88.3 4.2 123.7-10.9c36.5-15.6 64.4-44.8 71.8-87.3c3.6-21 2.7-40.4-3.1-58.1H335.1c7 5.6 11.4 11.2 13.9 17.2z"
            />
          </svg>
        </button>
      </li>

      <!-- Headline -->
      <li class="relative">
        <button
          class="w-9 h-8 flex items-center justify-center border-r-4 border-gray-200 focus:voix-admin-bg-light focus-voix-admin-text-dark transition-all duration-100 font-bold text-sm"
          :class="{
            'voix-admin-bg-light text-white':
              showHeadlinesDropdown || editor.isActive('heading', { level: 1 }),
            'bg-gray-100 hover:bg-gray-300 text-gray-500 hover:text-gray-800 ':
              showHeadlinesDropdown || !editor.isActive('heading'),
          }"
          @click="showHeadlinesDropdown = !showHeadlinesDropdown"
        >
          H
        </button>

        <ul
          v-if="showHeadlinesDropdown"
          class="absolute z-50 top-0 left-0 right-0 mt-8"
        >
          <li>
            <button
              class="focus:voix-admin-bg-light focus-voix-admin-text-dark transition-all duration-100 p-2 w-8"
              :class="{
                'voix-admin-bg-lightest text-white': editor.isActive('heading', {
                  level: 1,
                }),
                'bg-gray-100 hover:bg-gray-300 text-gray-500 hover:text-gray-800 ':
                  !editor.isActive('heading', { level: 1 }),
              }"
              @click="toggleHeading(1)"
            >
              H1
            </button>
          </li>
          <li>
            <button
              class="focus:voix-admin-bg-light focus-voix-admin-text-dark transition-all duration-100 p-2"
              :class="{
                'voix-admin-bg-lightest text-white': editor.isActive('heading', {
                  level: 2,
                }),
                'bg-gray-100 hover:bg-gray-300 text-gray-500 hover:text-gray-800 ':
                  !editor.isActive('heading', { level: 2 }),
              }"
              @click="toggleHeading(2)"
            >
              H2
            </button>
          </li>
          <li>
            <button
              class="focus:voix-admin-bg-light focus-voix-admin-text-dark transition-all duration-100 p-2"
              :class="{
                'voix-admin-bg-lightest text-white': editor.isActive('heading', {
                  level: 3,
                }),
                'bg-gray-100 hover:bg-gray-300 text-gray-500 hover:text-gray-800 ':
                  !editor.isActive('heading', { level: 3 }),
              }"
              @click="toggleHeading(3)"
            >
              H3
            </button>
          </li>
        </ul>
      </li>
      <div v-if="showHeadlinesDropdown" class="absolute z-10 inset-0" @click="showHeadlinesDropdown = false" />

      <!-- Font Size -->
      <li class="relative">
        <button
          class="w-9 h-8 flex items-center justify-center border-r-4 border-gray-200 focus:voix-admin-bg-light focus-voix-admin-text-dark transition-all duration-100 font-bold text-sm"
          :class="{
            'voix-admin-bg-light text-white':
              showTextSizeDropdown || editor.isActive('heading', { level: 1 }),
            'bg-gray-100 hover:bg-gray-300 text-gray-500 hover:text-gray-800 ':
              showTextSizeDropdown || !editor.isActive('heading'),
          }"
          @click="showTextSizeDropdown = !showTextSizeDropdown"
        >
          <Icon name="majesticons:font-size-line" class="w-5 h-5" />
        </button>

        <div
          v-if="showTextSizeDropdown"
          class="absolute z-50 top-0 left-0 -translate-x-[75px] w-[350px] mt-8 bg-white/50 rounded p-4 shadow"
        >
          <div class="flex items-center space-x-1 bg-white">
            <TextInput
              id="voix-wysiwig-font-size-id"
              v-model="newTextSize"
              placeholder="14"
              label="Text Size (number)"
            />
            <DropdownSelect
              id="voix-wysiwyg-font-size-unit-id"
              v-model="newTextSizeUnit"
              label="Text Size Unit"
              :options="[
                {
                  label: 'px',
                  value: 'px',
                  key: 'px',
                },
                {
                  label: 'em',
                  value: 'em',
                  key: 'em',
                },
                {
                  label: 'rem',
                  value: 'rem',
                  key: 'rem',
                },
              ]"
              placeholder="Select a Unit Size"
            />
          </div>
          <div class="flex space-x-1 mt-2">
            <button
              class="focus:voix-admin-bg-light focus-voix-admin-text-dark transition-all duration-100 p-2 px-4 "
              :class="{
                'voix-admin-bg-lightest text-white': editor.isActive('heading', {
                  level: 1,
                }),
                'bg-gray-200 hover:voix-admin-text-lightest text-gray-600 hover:text-gray-800 ':
                  !editor.isActive('heading', { level: 1 }),
              }"
              @click="editor.chain().focus().setFontSize(`${newTextSize}${newTextSizeUnit}`).run()"
            >
              Set
            </button>
            <button
              class="focus:voix-admin-bg-light focus-voix-admin-text-dark transition-all duration-100  p-2 px-4 "
              :class="{
                'voix-admin-bg-lightest text-white': editor.isActive('heading', {
                  level: 3,
                }),
                'bg-gray-200 hover:voix-admin-text-lightest text-gray-600 hover:text-gray-800 ':
                  !editor.isActive('heading', { level: 3 }),
              }"
              @click="editor.chain().focus().unsetFontSize().run()"
            >
              Clear
            </button>
          </div>
        </div>
      </li>
      <div v-if="showTextSizeDropdown" class="absolute z-10 inset-0" @click="showTextSizeDropdown = false" />

      <!-- Links -->
      <li class="relative min-w-[20px]">
        <button
          class="w-9 h-8 flex items-center justify-center focus:voix-admin-bg-light focus-voix-admin-text-dark transition-all duration-100"
          :class="{
            'voix-admin-bg-light text-white':
              showLinkDropdown || editor.isActive('link'),
            'bg-gray-100 hover:bg-gray-300 text-gray-500 hover:text-gray-800 ':
              showLinkDropdown || !editor.isActive('link'),
          }"
          @click="toggleShowLinkDropdown"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            class="w-4 h-4"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
            />
          </svg>
        </button>

        <div
          v-if="showLinkDropdown"
          class="absolute z-50 top-0 left-0 mt-8 bg-white w-56 shadow p-4 rounded"
        >
          <TextInput
            id="link-url"
            v-model="newLinkUrl"
            placeholder="https://example.com"
            label="Link URL"
          />
          <DropdownSelect
            id="link-target"
            v-model="newLinkTarget"
            class="mt-4"
            label="Link Target"
            :options="[
              {
                label: 'Same Window / Tab',
                value: '_self',
                key: '_self',
              },
              {
                label: 'New Window / Tab',
                value: '_blank',
                key: '_blank',
              },
              {
                label: 'Top Window / Tab',
                value: '_top',
                key: '_top',
              },
            ]"
          />
          <div class="mt-4 flex space-x-2">
            <button
              class="focus:voix-admin-bg-light focus-voix-admin-text-dark transition-all duration-100 p-2 bg-gray-100 hover:bg-gray-300 text-gray-500 hover:text-gray-800"
              @click="addLink"
            >
              Add Link
            </button>
            <button
              class="focus:voix-admin-bg-light focus-voix-admin-text-dark transition-all duration-100 p-2 bg-gray-100 hover:bg-gray-300 text-gray-500 hover:text-gray-800"
              @click="removeLink"
            >
              Remove Link
            </button>
          </div>
        </div>
      </li>
      <div v-if="showLinkDropdown" class="absolute z-10 inset-0" @click="showLinkDropdown = false" />

      <!-- Unordered List -->
      <li>
        <button
          class="w-9 h-8 flex items-center justify-center border-l-4 border-gray-200 focus:voix-admin-bg-light focus-voix-admin-text-dark transition-all duration-100"
          :class="{
            'voix-admin-bg-lightest text-white': editor.isActive('bulletList'),
            'bg-gray-100 hover:bg-gray-300 text-gray-500 hover:text-gray-800 ':
              !editor.isActive('bulletList'),
          }"
          @click="editor.chain().focus().toggleBulletList().run()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            class="w-5 h-5"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
            />
          </svg>
        </button>
      </li>
      <!-- Ordered List -->
      <li>
        <button
          class="w-9 h-8 flex items-center justify-center border-r-4 border-gray-200 focus:voix-admin-bg-light focus-voix-admin-text-dark transition-all duration-100"
          :class="{
            'voix-admin-bg-lightest text-white': editor.isActive('orderedList'),
            'bg-gray-100 hover:bg-gray-300 text-gray-500 hover:text-gray-800 ':
              !editor.isActive('orderedList'),
          }"
          @click="editor.chain().focus().toggleOrderedList().run()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            fill="currentColor"
            class="w-4 h-4"
          >
            <path
              d="M24 56c0-13.3 10.7-24 24-24H80c13.3 0 24 10.7 24 24V176h16c13.3 0 24 10.7 24 24s-10.7 24-24 24H40c-13.3 0-24-10.7-24-24s10.7-24 24-24H56V80H48C34.7 80 24 69.3 24 56zM86.7 341.2c-6.5-7.4-18.3-6.9-24 1.2L51.5 357.9c-7.7 10.8-22.7 13.3-33.5 5.6s-13.3-22.7-5.6-33.5l11.1-15.6c23.7-33.2 72.3-35.6 99.2-4.9c21.3 24.4 20.8 60.9-1.1 84.7L86.8 432H120c13.3 0 24 10.7 24 24s-10.7 24-24 24H32c-9.5 0-18.2-5.6-22-14.4s-2.1-18.9 4.3-25.9l72-78c5.3-5.8 5.4-14.6 .3-20.5zM224 64H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H224c-17.7 0-32-14.3-32-32s14.3-32 32-32zm0 160H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H224c-17.7 0-32-14.3-32-32s14.3-32 32-32zm0 160H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H224c-17.7 0-32-14.3-32-32s14.3-32 32-32z"
            />
          </svg>
        </button>
      </li>

      <li class="relative">
        <button
          class="w-9 h-8 flex items-center justify-center border-r-4 border-gray-200 focus:voix-admin-bg-light focus-voix-admin-text-dark transition-all duration-100 font-bold text-sm"
          :class="{
            'voix-admin-bg-light text-white': showAlignmentDropdown,
            'bg-gray-100 hover:bg-gray-300 text-gray-500 hover:text-gray-800 ':
              !showAlignmentDropdown,
          }"
          @click="showAlignmentDropdown = !showAlignmentDropdown"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            fill="currentColor"
            class="w-4 h-4"
          >
            <path
              d="M448 64c0-17.7-14.3-32-32-32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32zm0 256c0-17.7-14.3-32-32-32H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H416c17.7 0 32-14.3 32-32zM0 192c0 17.7 14.3 32 32 32H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H32c-17.7 0-32 14.3-32 32zM448 448c0-17.7-14.3-32-32-32H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H416c17.7 0 32-14.3 32-32z"
            />
          </svg>
        </button>

        <ul
          v-if="showAlignmentDropdown"
          class="absolute z-50 top-0 left-0 right-0 mt-8"
        >
          <!-- Align Left -->
          <li>
            <button
              class="w-8 h-8 flex items-center justify-center focus:voix-admin-bg-light focus-voix-admin-text-dark transition-all duration-100"
              :class="{
                'voix-admin-bg-lightest text-white': editor.isActive({
                  textAlign: 'left',
                }),
                'bg-gray-100 hover:bg-gray-300 text-gray-500 hover:text-gray-800 ':
                  !editor.isActive({ textAlign: 'left' }),
              }"
              @click="editor.chain().focus().setTextAlign('left').run()"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                fill="currentColor"
                class="w-4 h-4"
              >
                <path
                  d="M288 64c0 17.7-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32H256c17.7 0 32 14.3 32 32zm0 256c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H256c17.7 0 32 14.3 32 32zM0 192c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 448c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"
                />
              </svg>
            </button>
          </li>
          <!-- Align Center -->
          <li>
            <button
              class="w-8 h-8 flex items-center justify-center focus:voix-admin-bg-light focus-voix-admin-text-dark transition-all duration-100"
              :class="{
                'voix-admin-bg-lightest text-white': editor.isActive({
                  textAlign: 'center',
                }),
                'bg-gray-100 hover:bg-gray-300 text-gray-500 hover:text-gray-800 ':
                  !editor.isActive({ textAlign: 'center' }),
              }"
              @click="editor.chain().focus().setTextAlign('center').run()"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                fill="currentColor"
                class="w-4 h-4"
              >
                <path
                  d="M352 64c0-17.7-14.3-32-32-32H128c-17.7 0-32 14.3-32 32s14.3 32 32 32H320c17.7 0 32-14.3 32-32zm96 128c0-17.7-14.3-32-32-32H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H416c17.7 0 32-14.3 32-32zM0 448c0 17.7 14.3 32 32 32H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H32c-17.7 0-32 14.3-32 32zM352 320c0-17.7-14.3-32-32-32H128c-17.7 0-32 14.3-32 32s14.3 32 32 32H320c17.7 0 32-14.3 32-32z"
                />
              </svg>
            </button>
          </li>
          <!-- Align Right -->
          <li>
            <button
              class="w-8 h-8 flex items-center justify-center focus:voix-admin-bg-light focus-voix-admin-text-dark transition-all duration-100"
              :class="{
                'voix-admin-bg-lightest text-white': editor.isActive({
                  textAlign: 'right',
                }),
                'bg-gray-100 hover:bg-gray-300 text-gray-500 hover:text-gray-800 ':
                  !editor.isActive({ textAlign: 'right' }),
              }"
              @click="editor.chain().focus().setTextAlign('right').run()"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                fill="currentColor"
                class="w-4 h-4"
              >
                <path
                  d="M448 64c0 17.7-14.3 32-32 32H192c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32zm0 256c0 17.7-14.3 32-32 32H192c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32zM0 192c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 448c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"
                />
              </svg>
            </button>
          </li>
        </ul>
      </li>

      <div v-if="showAlignmentDropdown" class="absolute z-10 inset-0" @click="showAlignmentDropdown = false" />

      <!-- Horizontal Rule -->
      <li>
        <button
          class="w-9 h-8 flex items-center justify-center border-r-4 border-gray-200 focus:voix-admin-bg-light focus-voix-admin-text-dark transition-all duration-100"
          :class="{
            'voix-admin-bg-lightest text-white': editor.isActive('strike'),
            'bg-gray-100 hover:bg-gray-300 text-gray-500 hover:text-gray-800 ':
              !editor.isActive('strike'),
          }"
          @click="editor.chain().focus().setHorizontalRule().run()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="w-4 h-4"
          >
            <line x1="5" y1="12" x2="19" y2="12" />
          </svg>
        </button>
      </li>

      <!-- YouTube -->
      <li>
        <button
          class="w-9 h-8 flex items-center justify-center focus:voix-admin-bg-light focus-voix-admin-text-dark transition-all duration-100 bg-gray-100 hover:bg-gray-300 text-gray-500 hover:text-gray-800"
          @click="addVideo"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="w-4 h-4"
          >
            <path
              d="M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z"
            />
            <polygon
              points="9.75 15.02 15.5 11.75 9.75 8.48 9.75 15.02"
            />
          </svg>
        </button>
      </li>

      <!-- Tables -->
      <li class="relative">
        <button
          class="w-9 h-8 flex items-center justify-center border-r-4 border-gray-200 focus:voix-admin-bg-light focus-voix-admin-text-dark transition-all duration-100 font-bold text-sm"
          :class="{
            'voix-admin-bg-light text-white': showTableControlsDropdown,
            'bg-gray-100 hover:bg-gray-300 text-gray-500 hover:text-gray-800 ':
              !showTableControlsDropdown,
          }"
          @click="toggleTableDropdown"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 01-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0112 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5"
            />
          </svg>
        </button>

        <div
          v-if="showTableAddDropdown"
          class="absolute z-50 top-0 left-0 w-56 mt-8 bg-white border border-gray-200 rounded shadow p-2"
        >
          <TextInput
            id="add-table-row-input"
            v-model="addTableRows"
            type="number"
            label="Rows"
            min="1"
          />
          <TextInput
            id="add-table-cols-input"
            v-model="addTableCols"
            type="number"
            label="Columns"
            min="1"
            class="mt-4"
          />

          <SwitchInput
            id="add-table-header-input"
            v-model="addTableWithHeader"
            label="Include Table Header"
            class="mt-4"
          />

          <div class="flex space-x-2 mt-4">
            <button
              class="focus:voix-admin-bg-light focus-voix-admin-text-dark transition-all duration-100 p-2 bg-gray-100 hover:bg-gray-300 text-gray-500 hover:text-gray-800"
              @click="addTable"
            >
              Add Table
            </button>
            <button
              class="focus:voix-admin-bg-light focus-voix-admin-text-dark transition-all duration-100 p-2 bg-gray-100 hover:bg-gray-300 text-gray-500 hover:text-gray-800"
              @click="cancelAddTable"
            >
              Cancel
            </button>
          </div>
        </div>

        <ul
          v-if="showTableControlsDropdown && editor.can().deleteTable()"
          class="absolute z-50 top-0 left-0 w-56 mt-8 bg-white border border-gray-200 rounded shadow p-4"
        >
          <li class="w-full">
            <DropdownSelect
              id="table-commands"
              v-model="tableCommand"
              :options="tableCommandOptions"
              label="Table Commands"
              class="w-full"
              @change="executeTableCommand"
            />
          </li>
        </ul>
      </li>
      <div v-if="showTableAddDropdown" class="absolute z-10 inset-0" @click="showTableAddDropdown = false" />

      <!-- View Source -->
      <li>
        <button
          class="w-9 h-8 flex items-center justify-center focus:voix-admin-bg-light focus-voix-admin-text-dark transition-all duration-100 bg-gray-100 hover:bg-gray-300 text-gray-500 hover:text-gray-800"
          @click="viewSource = !viewSource"
        >
          <Icon name="heroicons:code-bracket-20-solid" class="w-4 h-4" />
        </button>
      </li>
    </ul>
    <div v-if="!viewSource" class="prose">
      <EditorContent :editor="editor" />
    </div>
    <div v-else class="prose">
      <div
        contenteditable="true"
        class="w-[650px] p-2 border border-gray-200 rounded shadow font-mono"
        @input="updateSourceCode($event.target.innerText)"
      >
        {{ sourceCode }}
      </div>
    </div>
    <div />
  </div>
</template>

<style lang="scss">
  .ProseMirror {
    table {
      border-collapse: collapse;
      table-layout: fixed;
      width: 100%;
      margin: 0;
      overflow: hidden;

      td,
      th {
        min-width: 1em;
        border: 2px solid #ced4da;
        padding: 3px 5px;
        vertical-align: top;
        box-sizing: border-box;
        position: relative;

        > * {
          margin-bottom: 0;
        }
      }

      th {
        font-weight: bold;
        text-align: left;
        background-color: #f1f3f5;
      }

      .selectedCell:after {
        z-index: 2;
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(200, 200, 255, 0.4);
        pointer-events: none;
      }

      .column-resize-handle {
        position: absolute;
        right: -2px;
        top: 0;
        bottom: -2px;
        width: 4px;
        background-color: #adf;
        pointer-events: none;
      }

      p {
        margin: 0;
      }
    }
  }

  .tableWrapper {
    padding: 1rem 0;
    overflow-x: auto;
  }

  .resize-cursor {
    cursor: ew-resize;
    cursor: col-resize;
  }
</style>
