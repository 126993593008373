<script setup lang="ts">
import { computed, ref } from 'vue'

import type {
  SliceSettingsInterface,
  SpacingPropertiesInterface,
} from '@voix/types'

import MarginPaddingInput from '@voix/components/chrome/controls/MarginPaddingInput.vue'
import PanelSection from '../PanelSection.vue'

const props = defineProps<{
  settings: SliceSettingsInterface
}>()

const emit = defineEmits(['update'])

function updateSettings(newValue: object) {
  emit('update', {
    ...props.settings,
    ...newValue,
  })
}

const enableMarginPadding = ref(false)

const marginSettings = computed({
  get: (): SpacingPropertiesInterface => {
    if (!props.settings.margin) {
      return {
        top: '',
        right: '',
        bottom: '',
        left: '',
      }
    }

    return props.settings.margin
  },
  set(value: SpacingPropertiesInterface) {
    updateSettings({ margin: value })
  },
})

const paddingSettings = computed({
  get: (): SpacingPropertiesInterface => {
    if (!props.settings.padding) {
      return {
        top: '',
        right: '',
        bottom: '',
        left: '',
      }
    }

    return props.settings.padding
  },
  set(value: SpacingPropertiesInterface) {
    updateSettings({ padding: value })
  },
})

const showMarginPadding = computed(() => {
  if (
    enableMarginPadding.value
      || marginSettings.value['top' || 'right' || 'bottom' || 'left']
      || paddingSettings.value['top' || 'right' || 'bottom' || 'left']
  )
    return true

  return false
})

function clearMarginPadding() {
  updateSettings({
    margin: {
      top: '',
      right: '',
      bottom: '',
      left: '',
    },
    padding: {
      top: '',
      right: '',
      bottom: '',
      left: '',
    },
  })

  enableMarginPadding.value = false
}
</script>

<template>
  <PanelSection
    id="padding-and-margin"
    name="Padding and Margin"
    :start-open="showMarginPadding"
  >
    <div class="flex flex-col items-center">
      <MarginPaddingInput
        v-model:padding="paddingSettings"
        v-model:margin="marginSettings"
        class="w-full"
      />

      <button
        v-if="showMarginPadding"
        class="mt-4 bg-gray-100 p-2.5 px-2 rounded flex items-center text-xs text-gray-500 w-full"
        @click="clearMarginPadding"
      >
        Clear Margin & Padding
      </button>
    </div>
  </PanelSection>
</template>
