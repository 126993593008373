<script setup lang="ts">
import type { SpacingPropertiesInterface } from '@voix/types'

import { computed } from 'vue'
import { useVModel } from '@vueuse/core'

const props = defineProps<{
  padding: SpacingPropertiesInterface
  margin: SpacingPropertiesInterface
}>()

const emit = defineEmits(['update:padding', 'update:margin'])

const paddingData = useVModel(props, 'padding', emit)

const paddingTop = computed({
  get: (): string => {
    return paddingData.value.top?.toString() ?? ''
  },
  set(value: string) {
    paddingData.value = {
      ...paddingData.value,
      top: value,
    }
  },
})

const paddingRight = computed({
  get: (): string => {
    return paddingData.value.right?.toString() ?? ''
  },
  set(value: string) {
    paddingData.value = {
      ...paddingData.value,
      right: value,
    }
  },
})

const paddingBottom = computed({
  get: (): string => {
    return paddingData.value.bottom?.toString() ?? ''
  },
  set(value: string) {
    paddingData.value = {
      ...paddingData.value,
      bottom: value,
    }
  },
})

const paddingLeft = computed({
  get: (): string => {
    return paddingData.value.left?.toString() ?? ''
  },
  set(value: string) {
    paddingData.value = {
      ...paddingData.value,
      left: value,
    }
  },
})

const marginData = useVModel(props, 'margin', emit)

const marginTop = computed({
  get: (): string => {
    return marginData.value.top?.toString() ?? ''
  },
  set(value: string) {
    marginData.value = {
      ...marginData.value,
      top: value,
    }
  },
})

const marginRight = computed({
  get: (): string => {
    return marginData.value.right?.toString() ?? ''
  },
  set(value: string) {
    marginData.value = {
      ...marginData.value,
      right: value,
    }
  },
})

const marginBottom = computed({
  get: (): string => {
    return marginData.value.bottom?.toString() ?? ''
  },
  set(value: string) {
    marginData.value = {
      ...marginData.value,
      bottom: value,
    }
  },
})

const marginLeft = computed({
  get: (): string => {
    return marginData.value.left?.toString() ?? ''
  },
  set(value: string) {
    marginData.value = {
      ...marginData.value,
      left: value,
    }
  },
})
</script>

<template>
  <div class="grid grid-cols-5 text-xs bg-gray-200 rounded-lg">
    <div class="col-span-1 col-start-3 flex justify-center py-2">
      <input
        v-model="marginTop"
        type="text"
        class="p-1 rounded w-12 text-center text-gray-500 text-2xs border-0"
      >
    </div>
    <div class="col-span-1 row-start-2 flex items-center justify-center">
      <input
        v-model="marginLeft"
        type="text"
        class="p-1 rounded w-10 text-center text-gray-500 text-2xs border-0"
      >
    </div>
    <div class="col-span-3 bg-gray-300 grid grid-cols-3 rounded-lg">
      <div class="col-span-1 col-start-2 flex justify-center py-1.5">
        <input
          v-model="paddingTop"
          type="text"
          class="p-1 rounded w-12 text-center text-gray-500 text-2xs border-0"
        >
      </div>
      <div class="col-span-1 row-start-2 flex justify-center items-center">
        <input
          v-model="paddingLeft"
          type="text"
          class="p-1 rounded w-10 text-center text-gray-500 text-2xs border-0"
        >
      </div>
      <div
        class="col-span-1 row-start-2 flex justify-center items-center bg-gray-400 rounded py-4"
      >
        &nbsp;
      </div>
      <div class="col-span-1 row-start-2 flex justify-center items-center">
        <input
          v-model="paddingRight"
          type="text"
          class="p-1 rounded w-10 text-center text-gray-500 text-2xs border-0"
        >
      </div>
      <div class="col-span-1 col-start-2 flex justify-center py-1.5">
        <input
          v-model="paddingBottom"
          type="text"
          class="p-1 rounded w-12 text-center text-gray-500 text-2xs border-0"
        >
      </div>
    </div>
    <div class="col-span-1 flex items-center justify-center">
      <input
        v-model="marginRight"
        type="text"
        class="p-1 rounded w-10 text-center text-gray-500 text-2xs border-0"
      >
    </div>
    <div class="col-span-1 col-start-3 flex justify-center py-2">
      <input
        v-model="marginBottom"
        type="text"
        class="p-1 rounded w-12 text-center text-gray-500 text-2xs border-0"
      >
    </div>
  </div>
</template>
