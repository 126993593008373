<script setup  lang="ts">
import SectionLabel from '@voix/components/chrome/SectionLabel.vue'
import { computed, ref, useSlots, watch } from 'vue'
import type { Ref } from 'vue'

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  modelValue: {
    type: Number,
    default: 0,
  },
  min: {
    type: Number,
    default: 0,
  },
  max: {
    type: Number,
    default: 100,
  },
  step: {
    type: Number,
    default: 1,
  },
  label: {
    type: String,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  border: {
    type: Boolean,
    default: true,
  },
  showValue: {
    type: Boolean,
    default: true,
  },
})

const slots = useSlots()

const modelValue = defineModel()
const tempValue = ref(modelValue.value)

function setValue(value: number) {
  modelValue.value = value
}
watch(modelValue, (value) => {
  tempValue.value = value
})

const input: Ref<HTMLInputElement | null> = ref(null)

const percentWidth = computed(() => (tempValue.value - props.min) / (props.max - props.min) * 100)
</script>

<template>
  <div

    :class="{
      'opacity-50': props.disabled,
    }"
  >
    <SectionLabel v-if="props.label">
      {{ props.label }}
    </SectionLabel>
    <div class="flex itemes-center space-x-2">
      <div
        class="relative h-4 flex-1 px-1"
      >
        <input
          :id="props.id"
          ref="input"
          v-model="tempValue"
          type="range"
          class="relative z-10 w-full appearance-none bg-transparent h-2 rounded-full "
          :disabled="props.disabled"
          :min="props.min"
          :max="props.max"
          @change="setValue($event.target.value)"
        >
        <div class="absolute inset-0 flex items-center">
          <div class="absolute left-0 right-0 bg-gray-200 rounded-full h-2 w-full" />
          <div class="absolute left-0 voix-admin-bg-lightest rounded-full h-2 px-2" :style="{ width: `${percentWidth}%` }" />
        </div>
      </div>
      <div class="rounded-sm bg-gray-200 voix-admin-text py-0.5 px-2 text-2xs font-bold w-10 text-center">
        {{ tempValue }}
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
input[type="range"]::-webkit-slider-thumb {
    @apply appearance-none bg-gray-300 h-3 w-3 rounded-full ring-2 ring-gray-500 focus:outline-4 outline-gray-500/50;
}
</style>
