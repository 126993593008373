import { useAdminStore } from '@voix/store/adminStore'
import { $voixNuxtApi } from '@voix/voix-layer/composables/voixNuxtApi'

interface MediaUpdateArgs {
  media: object
}

async function mediaSelected(variables: MediaUpdateArgs) {
  const adminStore = useAdminStore()
  adminStore.isLoading = true

  const request = await $voixNuxtApi(
    `/api/voix/media/${variables.client}/selected`,
    {
      method: 'POST',
      body: variables,
    },
  )

  adminStore.isLoading = false

  return request
}

export {
  mediaSelected,
}
