<script setup lang="ts">
import type { Ref } from 'vue'
import { ref } from 'vue'
import type { HeadTagInterface } from '@voix/types'
import PageHeadTagsIndex from './PageHeadTagsIndex.vue'
import PageHeadTagEdit from './PageHeadTagEdit.vue'
import PageHeadTagNew from './PageHeadTagNew.vue'

const props = defineProps({
  page: {
    type: Object as any,
    required: true,
  },
})

const newHeadTag = ref(false)
const headTag: Ref<HeadTagInterface | null> = ref(null)

function selectHeadTag(tag: HeadTagInterface) {
  headTag.value = tag
}

function deselectHeadTag() {
  headTag.value = null
}

function requestNewHeadTag() {
  newHeadTag.value = true
}

function cancelNewHeadTag() {
  newHeadTag.value = false
}
</script>

<template>
  <div>
    <PageHeadTagsIndex v-if="!headTag && !newHeadTag" :page-id="props.page.id" @new="requestNewHeadTag" @select="selectHeadTag" />
    <PageHeadTagEdit v-if="headTag" :head-tag="headTag" @back="deselectHeadTag" />
    <PageHeadTagNew v-if="newHeadTag" :page-id="props.page.id" @back="cancelNewHeadTag" />
  </div>
</template>
