<script lang="ts">
import { computed, defineComponent, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useAdminStore } from '@voix/store/adminStore'
import { useUserStore } from '@voix/store/userStore'
import { usePageStore } from '@voix/store/pageStore'
import { requestSave, requestSaveDraft } from '@voix/composables/usePage'
import { getCurrentPage } from '@voix/composables/queries/useQueryCurrentPage'
import { deleteRelease } from '@voix/composables/queries/useQueryReleases'

import { useLayoutStore } from '@voix/store/layoutStore'
import SaveRevision from '../revisions/SaveRevision.vue'

import PanelSection from '../PanelSection.vue'
import TextInput from '../controls/TextInput.vue'
import DropdownSelect from '../controls/DropdownSelect.vue'
import PageHeadTags from '../../page-head-tags/PageHeadTags.vue'
import PageNavigationElements from './PageNavigationElements.vue'
import AddSlice from './AddSlice.vue'

export default defineComponent({
  components: {
    AddSlice,
    PageNavigationElements,
    SaveRevision,
    PanelSection,
    TextInput,
    DropdownSelect,
    PageHeadTags,
  },

  setup() {
    const draggingOverPage = ref(false)

    const adminStore = useAdminStore()
    const userStore = useUserStore()
    const layoutStore = useLayoutStore()
    const pageStore = usePageStore()
    const router = useRouter()

    const goToPageSelection = () => {
      pageStore.unsetCurrentPage()
      router.push({
        name: 'voix-settings-pages',
      })
    }

    const layout = computed({
      get: () => {
        return pageStore.currentPage?.layout
      },
      set: (newValue) => {
        pageStore.updateLayout(newValue)
      },
    })

    const layoutOptions = computed(() => {
      const layouts: Array<any> = []
      Object.keys(layoutStore.layouts).forEach((key) => {
        layouts.push({
          label: layoutStore.layouts[key].label,
          value: key,
          key,
        })
      })

      return layouts
    })

    const showHead = ref(false)

    const isDrafter = computed(() => userStore?.currentUser?.id.toString() === pageStore.currentPage?.draft_release?.user_id?.toString())

    const requestDeleteDraft = () => {
      const draftReleaseId = pageStore.currentPage?.draft_release?.id
      if (draftReleaseId) {
        deleteRelease({
          id: draftReleaseId,
        }).then(() => {
          adminStore.unsetReleaseId()
          if (pageStore.currentPage) {
            getCurrentPage(
              reactive({
                path: pageStore.currentPage.path,
              }),
            )
          }
        })
      }
    }

    return {
      draggingOverPage,
      adminStore,
      layoutOptions,
      pageStore,
      layout,
      showHead,
      isDrafter,
      goToPageSelection,
      requestSave,
      requestSaveDraft,
      requestDeleteDraft,
    }
  },
})
</script>

<template>
  <div
    class="bg-gray-100 w-[280px] text-gray-600 text-xs flex flex-col justify-between"
  >
    <div v-if="pageStore.currentPage" class="w-full overflow-y-auto">
      <div
        class="fixed z-20 top-0 left-0 bg-white ml-[280px] w-[50vw] max-w-xl shadow-xl overflow-y-scroll max-h-screen duration-300"
        :class="{
          'opacity-0 pointer-events-none': !showHead,
          'opacity-100 pointer-events-auto': showHead,
          '-translate-x-[560px]': adminStore.isZenMode,
        }"
      >
        <PageHeadTags v-if="showHead" :page="pageStore.currentPage" />
      </div>
      <div class="mb-12">
        <button
          class="group pt-5 pb-4 px-3 w-full flex space-x-2 text-gray-400 bg-gray-200 border-b border-gray-300 hover:voix-admin-bg-lightest duration-200"
          @click="goToPageSelection"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-4 h-4 group-hover:-translate-x-1 duration-200 transform group-hover:voix-admin-text"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
            />
          </svg>

          <span
            class="uppercase font-semibold text-2xs group-hover:voix-admin-text duration-200 transform"
          >Back to page selection</span>
        </button>
      </div>
      <div class="px-2">
        <div>
          <PanelSection
            id="page-navigation-page-settings"
            name="Page Settings"
            :start-open="true"
          >
            <div class="px-2">
              <TextInput
                id="page-title"
                v-model="pageStore.currentPage.title"
                placeholder="Page Label"
                label="Page Label"
                class="text-xs"
              />
            </div>
            <div class="px-2">
              <TextInput
                id="page-path"
                v-model="pageStore.currentPage.path"
                placeholder="Page Path"
                label="Page Path"
                class="text-xs"
              />
            </div>
            <div class="mt-4 px-2">
              <DropdownSelect
                id="text-field-font"
                v-model="layout"
                :options="layoutOptions"
                label="Layout"
                placeholder="Select a layout"
              />
            </div>

            <div class="mt-4 px-2">
              <button
                class="flex items-center space-x-2 mb-4 w-full py-2 px-3 rounded transition-colors duration-200 bg-gray-200 hover:voix-admin-bg hover:text-white"
                @click="showHead = !showHead"
              >
                <span>Manage Head Tags</span>
              </button>
            </div>
          </PanelSection>
        </div>
        <PanelSection
          v-if="pageStore.currentPage"
          id="page-navigation-page-elements"
          name="Page Elements"
        >
          <div
            v-if="pageStore.currentPage"
            class="relative text-xs font-normal text-gray-500"
          >
            <AddSlice :to="pageStore.currentPage.elements">
              <button
                class="flex items-center space-x-2 mb-4 w-full py-1.5 px-2 rounded-full transition-colors duration-200 bg-gray-200 hover:voix-admin-bg hover:text-white"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>

                <span>Add New Slice</span>
              </button>
            </AddSlice>

            <PageNavigationElements
              class="mt-4"
              :elements="pageStore.currentPage.elements"
            />
          </div>
        </PanelSection>
      </div>
    </div>

    <div class="px-2 py-1">
      <SaveRevision
        v-if="pageStore.currentPage"
        :can-draft="true"
        :is-drafter="isDrafter"
        :draft-release="pageStore.currentPage.draft_release"
        label="Save Draft"
        @save="requestSave"
        @save-draft="requestSaveDraft"
        @delete-draft="requestDeleteDraft"
      />
    </div>

    <div
      v-if="!pageStore.currentPage"
      class="flex flex-col justify-center items-center p-4 w-full h-screen"
    >
      <div
        class="p-4 rounded-lg text-gray-300 border-dashed border-2 border-gray-200 text-center"
      >
        Loading the page
      </div>
    </div>
  </div>
</template>
