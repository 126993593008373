<script setup lang="ts">
import { computed, provide, ref, watch } from 'vue'

import { useField } from '@voix/composables/useField'

import type { PropType } from 'vue'
import type {
  BreakpointInterface,
  FieldInterface,
  SliceInterface,
  VoixMediaImage,
  VoixMediaVideo,
} from '@voix/types'

import PropertiesPopupPanel from '@voix/components/chrome/PropertiesPopupPanel.vue'
import { usePageStore } from '@voix/store/pageStore'
import VoixFieldHeader from '@voix/voix-layer/components/VoixFieldHeader.vue'

import type { MediaFieldConfigInterface } from './types'

import ImageConfiguration from './ImageConfiguration.vue'
import MediaManager from './media-manager/MediaManager.vue'

import { useCookie } from '#app'
import { useNuxtApp } from '#imports'

const props = defineProps({
  element: {
    type: Object as PropType<SliceInterface>,
    required: true,
  },

  fieldData: {
    type: Object as PropType<FieldInterface>,
    default: null,
  },

  fieldConfiguration: {
    type: Object as PropType<MediaFieldConfigInterface>,
    required: true,
  },
})

const pageStore = usePageStore()

const { localValue, enabled } = useField(props)
const localFieldData = computed({
  get() {
    if (localValue.value)
      return localValue.value

    return []
  },
  set(value) {
    localValue.value = value
  },
})

// Listen for changes to the title property in any breakpoint
// and update the title on other breakpoints that were equal
// to the old value or blank
function titleUpdated(newValue: any, oldValue: any) {
  if (oldValue !== newValue) {
    localFieldData.value.forEach((breakpoint: any) => {
      if (breakpoint.title === oldValue || !breakpoint.title)
        breakpoint.title = newValue
    })
  }
}

const showMediaManager = ref(false)

// Don't confuse Provider with Vue's provide / inject
// This is the current provider source of the media and is changed
// with the source selector in the media manager
const rememberMediaProvider = useCookie('voix-media-provider')

const { $voix } = useNuxtApp()

const providers: Array<{ label: string, key: string, value: string }> = []
Object.keys($voix.options.mediaProviderConfig).forEach((providerKey) => {
  if ($voix.options.mediaProviderConfig[providerKey]) {
    const name = $voix.options.mediaProviderConfig[providerKey].name
    const provider = $voix.options.mediaProviderConfig[providerKey].provider
    if (name && provider) {
      providers.push({
        label: name,
        value: provider,
        key: provider,
      })
    }
  }
})

function updateField() {
  if (props.element.id) {
    pageStore.updateField(
      props.element.id,
      props.fieldConfiguration.name,
      localValue.value,
    )
  }
}

const provider = ref(rememberMediaProvider.value ? rememberMediaProvider.value : providers[0].value)
provide('provider', provider)

provide('fieldConfiguration', props.fieldConfiguration)
provide(
  'selectMedia',
  (breakpoint: string, media: VoixMediaImage | VoixMediaVideo) => {
    if (breakpoint && media) {
      const baseUrl = media.final_url ? media.final_url : media.url

      if (props.fieldConfiguration.breakpoints) {
        const width = Object.prototype.hasOwnProperty.call(props.fieldConfiguration.breakpoints, breakpoint)
          ? props.fieldConfiguration.breakpoints[breakpoint].width
          : null
        const height = Object.prototype.hasOwnProperty.call(props.fieldConfiguration.breakpoints, breakpoint)
          ? props.fieldConfiguration.breakpoints[breakpoint].height
          : null

        const newBreakpoint = {
          breakpoint,
          provider: provider.value,
          type: media.type,
          url: baseUrl,
          thumb: media.thumb,
          width,
          height,
          modifiers: '{}',
          created_at: media.created_at,
          uploaded_by: media.uploaded_by,
        }

        let existingBreakpoint = null

        if (
          typeof localFieldData.value === 'object'
            && localFieldData.value.length >= 0
        ) {
          existingBreakpoint = localFieldData.value.find(
            (bp: any) => bp.breakpoint === newBreakpoint.breakpoint,
          )
        }
        if (existingBreakpoint) {
          localFieldData.value[localValue.value.indexOf(existingBreakpoint)]
            = newBreakpoint
          localValue.value = localFieldData.value
        }
        else {
          if (
            typeof localFieldData.value !== 'object'
              || !localFieldData.value.length
          )
            localFieldData.value = []

          localFieldData.value.push(newBreakpoint)
          localValue.value = localFieldData.value
        }
      }

      updateField()

      showMediaManager.value = false
    }
  },
)

provide('duplicateMedia', (fromBreakpoint: string, toBreakpoint: string) => {
  // Get the data of the breakpoint we're duplicating
  const fromBreakpointData = localValue.value.find((bp: any) => {
    return bp.breakpoint === fromBreakpoint
  })

  // Try and find the breakpoint if it's there and clear it out if it exists
  const toBreakpointData = localValue.value.find((bp: any) => {
    return bp.breakpoint === toBreakpoint
  })

  if (toBreakpointData)
    localValue.value.splice(localValue.value.indexOf(toBreakpointData), 1)

  // Create the new breakpoint from the fromBreakpointData and rename the breakpoint
  const newBreakpoint = Object.assign({}, fromBreakpointData)
  newBreakpoint.breakpoint = toBreakpoint
  delete newBreakpoint.id

  // Shove it in the data and update the field
  localValue.value.push(newBreakpoint)

  updateField()
})

provide('clearMedia', (breakpoint: string) => {
  const theBreakpointData = localValue.value.find((bp: any) => {
    return bp.breakpoint === breakpoint
  })
  if (breakpoint && theBreakpointData)
    localValue.value.splice(localValue.value.indexOf(theBreakpointData), 1)

  if (localValue.value.length === 0 && props.fieldConfiguration.default)
    localValue.value = props.fieldConfiguration.default

  updateField()
})

provide('applyModification', (key: string, newValue: string | number, breakpointToModify: string) => {
  const theBreakpointData = localValue.value.find(
    (bp: any) => bp.breakpoint === breakpointToModify,
  )

  if (theBreakpointData) {
    if (!theBreakpointData.modifiers)
      theBreakpointData.modifiers = {}
    else if (typeof theBreakpointData.modifiers === 'string')
      theBreakpointData.modifiers = JSON.parse(theBreakpointData.modifiers)

    if (!newValue)
      delete theBreakpointData.modifiers[key]
    else
      theBreakpointData.modifiers[key] = newValue
  }

  theBreakpointData.modifiers = JSON.stringify(theBreakpointData.modifiers)

  updateField()
})

provide('getModification', (key: string, breakpointToModify: string) => {
  const theBreakpointData = localValue.value.find(
    (bp: any) => bp.breakpoint === breakpointToModify,
  )

  if (theBreakpointData) {
    if (!theBreakpointData.modifiers)
      theBreakpointData.modifiers = {}

    return theBreakpointData.modifiers[key]
  }

  return null
})

const breakpointsWithImage = computed(() => {
  const breakpoints: { [key: string]: BreakpointInterface } = {}

  if (props.fieldConfiguration.breakpoints) {
    for (const [key] of Object.entries(
      props.fieldConfiguration.breakpoints,
    )) {
      if (localValue.value && typeof localValue.value === 'object') {
        const breakpointData = localValue.value?.find((bp: any) => {
          if (bp)
            return bp.breakpoint === key

          return false
        })
        if (breakpointData && breakpointData.url)
          breakpoints[key] = breakpointData
      }
    }
  }

  return breakpoints
})
</script>

<template>
  <PropertiesPopupPanel class="min-w-[400px] text-xs text-gray-500 p-4">
    <div class="flex justify-between space-x-4">
      <VoixFieldHeader v-model="enabled" :field-configuration="props.fieldConfiguration" />
      <button
        class="mt-1 p-2 px-4 rounded text-xs voix-admin-text border-2 voix-admin-border-lightest0"
        @click="showMediaManager = !showMediaManager"
      >
        {{ showMediaManager ? 'Close Media Manager' : 'Select New Media' }}
      </button>
    </div>

    <div class="flex flex-col space-y-8">
      <ImageConfiguration
        v-for="(breakpoint, key) in breakpointsWithImage" :key="key" v-model="localFieldData"
        :field-configuration="props.fieldConfiguration" :breakpoint-name="key.toString()" :breakpoint="breakpoint"
        @title-updated="titleUpdated"
      />

      <MediaManager v-if="showMediaManager" @close="showMediaManager = false" />
    </div>
  </PropertiesPopupPanel>
</template>
