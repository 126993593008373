<script setup lang="ts">
import type { SliceConfigInterface, SliceInterface } from '@voix/types'
import { computed, provide, ref } from 'vue'
import type { PropType } from 'vue'
import { useSliceStore } from '@voix/store/sliceStore'
import TextInput from '../controls/TextInput.vue'
import SlicePreviewDirectory from './SlicePreviewDirectory.vue'

const props = defineProps({
  parentSlice: {
    type: Object as PropType<SliceInterface | null>,
    default: null,
  },
  allowedElements: {
    type: Array as PropType<Array<string>>,
    default: () => [],
  },
})

const emit = defineEmits(['deselect', 'select', 'selectGroupSlice'])

const sliceStore = useSliceStore()

const filter = ref('')
const selectedSlice = ref<SliceConfigInterface | string | null>(null)

const sliceOptions = computed(() => {
  const slices: Record<string, SliceConfigInterface> = sliceStore.slices

  // If there are entries in allowedSlices we will filter out only slices
  // that have that name or componentName. Otherwise we will use all slices
  const allowedSlices: Record<string, SliceConfigInterface>
      = props.allowedElements.length > 0
        ? Object.entries(slices).reduce((acc, [key, slice]) => {
          if (
            props.allowedElements.includes(
              slice.name.label,
            )
            || props.allowedElements.includes(
              key,
            )
          )
            acc[key] = slice

          return acc
        }, {})
        : slices

  // Filter the slices based on the filter value
  const filteredSlices = Object.entries(allowedSlices).filter((slice) => {
    return (
      slice[1].name.label
        .toLowerCase()
        .includes(filter.value.toLowerCase())
      || slice[1].name.group?.toLowerCase().includes(filter.value.toLowerCase())
    )
  })

  // Convert the filtered slices into an object
  const filteredSlicesObjs = Object.fromEntries(filteredSlices)

  // @todo used to work based on folder structure
  // need to refactor to not use ".vue" and instead use categories / tags
  const sliceTree: { [key: string]: any } = {}
  Object.entries(filteredSlicesObjs).forEach((slice) => {
    const hasGroup = Object.prototype.hasOwnProperty.call(slice[1].name, 'group')
    const categoryKey = hasGroup ? slice[1].name.group : 'Global'
    const sliceKey = `${slice[0]}`

    if (categoryKey) {
      if (!Object.prototype.hasOwnProperty.call(sliceTree, categoryKey as PropertyKey))
        sliceTree[categoryKey] = {}

      sliceTree[categoryKey][sliceKey] = slice[1]
    }
  })

  return sliceTree
})

function selectGroupSlice() {
  selectedSlice.value = 'group'
  emit('selectGroupSlice')
}

function selectSlice(component: string) {
  if (selectedSlice.value === component) {
    selectedSlice.value = null
    emit('deselect')
  }
  else {
    selectedSlice.value = component
    emit('select', component)
  }
}

provide('selectSlice', selectSlice)
provide('selectedSlice', selectedSlice)
</script>

<template>
  <div>
    <div class="mb-2 pb-2 border-b border-gray-100">
      <div class="max-w-xs">
        <TextInput
          id="addSliceFilter"
          v-model="filter"
          placeholder="Filter Slices"
        />
      </div>
    </div>

    <div>
      <div

        class="mt-12 mb-3 text-sm uppercase font-bold"
      >
        System Slices
      </div>

      <div class="grid grid-cols-3 gap-4">
        <button
          class="voix-admin-bg-lightest voix-admin-text flex justify-center items-center text-center leading-6 h-32 rounded-lg text-lg font-voix-studio font-bold duration-300 transition-all"
          :class="{ 'shadow-lg scale-110 z-10': selectedSlice === 'group' }"
          @click="selectGroupSlice"
        >
          New Group Slice
        </button>
      </div>
    </div>
    <div>
      <SlicePreviewDirectory :directory="sliceOptions" />
    </div>
  </div>
</template>
