<script setup lang="ts">
import type { VoixMediaTag } from '@voix/types'

const props = defineProps({
  tags: {
    type: Array<VoixMediaTag>,
    required: true,
  },
})
</script>

<template>
  <div v-if="props.tags.length > 0">
    <div class="justify-between flex text-2xs text-gray-500 opacity-70">
      <div>Tags</div>
    </div>
    <div class="flex flex-wrap">
      <div
        v-for="tag in props.tags"
        :key="tag.name"
        class="bg-gray-200 text-gray-600 rounded-full p-1 px-4 text-2xs font-medium mr-2 mb-2"
      >
        {{ tag.name }}
      </div>
    </div>
  </div>
</template>
