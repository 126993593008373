<script setup lang="ts">
import { computed } from 'vue'
import ColorProperties from '@voix/components/chrome/property-panels/ColorProperties.vue'
import SpacingProperties from '@voix/components/chrome/property-panels/SpacingProperties.vue'

import type { SettingsInterface } from '@voix/types'
import { usePageStore } from '@voix/store/pageStore'
import BreakpointProperties from '../controls/BreakpointProperties.vue'

const props = defineProps<{
  elementId: string | number
  sliceSettings?: Array<SettingsInterface>
}>()

function updateSettings(settings: SettingsInterface[]) {
  const pageStore = usePageStore()
  pageStore.updateElementSettings(props.elementId.toString(), settings)
}

const localSettings = computed({
  get: () => props.sliceSettings,
  set: (value) => {
    if (value)
      updateSettings(value)
  },
})

function updateBreakpointSettings(breakpoint: SettingsInterface, settings: SettingsInterface) {
  const settingsClone = localSettings.value ? localSettings.value : []
  const index = settingsClone.findIndex(
    bp => bp.breakpoint === breakpoint.breakpoint,
  )
  settingsClone[index] = settings

  updateSettings(settingsClone)
}
</script>

<template>
  <BreakpointProperties v-slot="slotProps" v-model:settings="localSettings">
    <ColorProperties
      :settings="slotProps.breakpoint"
      @update="updateBreakpointSettings(slotProps.breakpoint, $event)"
    />

    <SpacingProperties
      :settings="slotProps.breakpoint"
      @update="updateBreakpointSettings(slotProps.breakpoint, $event)"
    />
  </BreakpointProperties>
</template>
