<script setup lang="ts">
import { computed } from 'vue'
import type { PropType } from 'vue'

import type {
  ElementInterface,
  FieldInterface,
  SliceInterface,
} from '@voix/types'

import { useSliceStore } from '@voix/store/sliceStore'
import PanelSection from '../PanelSection.vue'
import PageNavigationField from '../pages/PageNavigationField.vue'

const props = defineProps({
  element: {
    type: Object as PropType<ElementInterface>,
    required: true,
  },
})

const sliceStore = useSliceStore()
const fieldConfiguration = computed(() => {
  return sliceStore.slices[props.element.component]
})

function getFieldData(fieldConfig: FieldInterface) {
  if (
    props
      && props.element
      && props.element.type === 'slice'
      && props.element.fields
  )
    return props.element.fields[fieldConfig.name]
}
</script>

<template>
  <PanelSection
    v-if="
      element
        && element.type === 'slice'
        && fieldConfiguration
        && fieldConfiguration.fields
    "
    id="slice-fields"
    name="Fields"
  >
    <div
      class="voix-admin-bg-lightest rounded overflow-hidden flex flex-col divide-y divide-gray-200"
    >
      <div
        v-for="(fieldConfig, fieldName) in fieldConfiguration.fields"
        :key="fieldName"
      >
        <PageNavigationField
          v-if="fieldConfig"
          :slice="props.element as SliceInterface"
          :field-name="fieldName.toString()"
          :field-data="getFieldData(fieldConfig)"
          :field-configuration="fieldConfig"
        />
      </div>
    </div>
  </PanelSection>
</template>
