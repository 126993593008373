<script setup lang="ts">
import type {
  FieldConfigInterface,
  FieldInterface,
  SliceInterface,
} from '@voix/types'
import type { PropType } from 'vue'
import { computed } from 'vue'
import { usePageStore } from '@voix/store/pageStore'

import type { WritableComputedRef } from 'nuxt/dist/app/compat/capi'
import FieldBreakpointManager from './FieldBreakpointManager.vue'

const props = defineProps({
  element: {
    type: Object as PropType<SliceInterface>,
    required: true,
  },

  fieldData: {
    type: Object as PropType<FieldInterface>,
    default: null,
  },

  fieldConfiguration: {
    type: Object as PropType<FieldConfigInterface>,
    required: true,
  },
})
// This is replaced by the voixFieldTransform into the import of all the field components

      import TextFieldEditor from '@voix/components/fields/text/Editor.vue'
      import NumberFieldEditor from '@voix/components/fields/number/Editor.vue'
      import TextareaFieldEditor from '@voix/components/fields/textarea/Editor.vue'
      import MediaFieldEditor from '@voix/components/fields/media/Editor.vue'
      import WysiwygFieldEditor from '@voix/components/fields/wysiwyg/Editor.vue'
      import SelectFieldEditor from '@voix/components/fields/select/Editor.vue'
      import CheckboxFieldEditor from '@voix/components/fields/checkbox/Editor.vue'
      import LinkFieldEditor from '@voix/components/fields/link/Editor.vue'
      import DatetimeFieldEditor from '@voix/components/fields/datetime/Editor.vue'
      import ColorFieldEditor from '@voix/components/fields/color/Editor.vue'
      import FilesFieldEditor from '@voix/components/fields/files/Editor.vue'
const fieldsWithBreakpoints: Array<string> = []

const localValue: WritableComputedRef<FieldInterface> = computed({
  get() {
    if (props.fieldData)
      return props.fieldData.value

    return ''
  },
  set(value) {
    if (props.element && props.element.id) {
      const pageStore = usePageStore()
      pageStore.updateField(
        props.element.id,
        props.fieldConfiguration.name,
        value,
      )
    }
  },
})

function hasBreakpoints(type: string) {
  return fieldsWithBreakpoints.includes(type)
}

function addBreakpoint(newBreakpoint: any) {
  localValue.value.properties.push(newBreakpoint)
}
</script>

<template>
  <slot name="field" />

  <!-- Injection point for all field types. voixFieldTransform performs this action at build time -->
  
      <component 
      v-if="fieldConfiguration.type === 'text'"
      :is="TextFieldEditor"     
      :element="element"
      :field-configuration="fieldConfiguration"
      :field-data="fieldData" />
      <component 
      v-if="fieldConfiguration.type === 'number'"
      :is="NumberFieldEditor"     
      :element="element"
      :field-configuration="fieldConfiguration"
      :field-data="fieldData" />
      <component 
      v-if="fieldConfiguration.type === 'textarea'"
      :is="TextareaFieldEditor"     
      :element="element"
      :field-configuration="fieldConfiguration"
      :field-data="fieldData" />
      <component 
      v-if="fieldConfiguration.type === 'media'"
      :is="MediaFieldEditor"     
      :element="element"
      :field-configuration="fieldConfiguration"
      :field-data="fieldData" />
      <component 
      v-if="fieldConfiguration.type === 'wysiwyg'"
      :is="WysiwygFieldEditor"     
      :element="element"
      :field-configuration="fieldConfiguration"
      :field-data="fieldData" />
      <component 
      v-if="fieldConfiguration.type === 'select'"
      :is="SelectFieldEditor"     
      :element="element"
      :field-configuration="fieldConfiguration"
      :field-data="fieldData" />
      <component 
      v-if="fieldConfiguration.type === 'checkbox'"
      :is="CheckboxFieldEditor"     
      :element="element"
      :field-configuration="fieldConfiguration"
      :field-data="fieldData" />
      <component 
      v-if="fieldConfiguration.type === 'link'"
      :is="LinkFieldEditor"     
      :element="element"
      :field-configuration="fieldConfiguration"
      :field-data="fieldData" />
      <component 
      v-if="fieldConfiguration.type === 'datetime'"
      :is="DatetimeFieldEditor"     
      :element="element"
      :field-configuration="fieldConfiguration"
      :field-data="fieldData" />
      <component 
      v-if="fieldConfiguration.type === 'color'"
      :is="ColorFieldEditor"     
      :element="element"
      :field-configuration="fieldConfiguration"
      :field-data="fieldData" />
      <component 
      v-if="fieldConfiguration.type === 'files'"
      :is="FilesFieldEditor"     
      :element="element"
      :field-configuration="fieldConfiguration"
      :field-data="fieldData" />

  <!-- Breakpoint Manager -->
  <FieldBreakpointManager
    v-if="hasBreakpoints(fieldConfiguration.type)"
    :field="fieldData"
    :field-configuration="fieldConfiguration"
    :value="localValue"
    @add-field-breakpoint="addBreakpoint"
  >
    <!-- Field Properties  -->
    <!-- eslint-disable-next-line vue/no-unused-vars -->
    <template #breakpoint="breakpoint">
      <!--
      Injection point for all breakpoint properties from the various field types.
      voixFieldTransform performs this action at build time
      -->
      
    </template>
  </FieldBreakpointManager>
</template>
