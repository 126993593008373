<script setup lang="ts">
import AdministrationChrome from '@voix/voix-layer/components/administration/AdministrationChrome.vue'
import AdminButton from '@voix/voix-layer/components/administration/AdministrationButton.vue'
import AdminAlert from '@voix/voix-layer/components/administration/AdministrationAlert.vue'
import DropdownSelect from '@voix/components/chrome/controls/DropdownSelect.vue'
import FormSection from '@voix/voix-layer/components/administration/AdministrationFormSection.vue'
import FormField from '@voix/voix-layer/components/administration/AdministrationFormField.vue'
import SubmissionGroup from '@voix/voix-layer/components/administration/AdministrationSubmissionGroup.vue'

import { computed, ref, watchEffect } from 'vue'
import {
  deleteHeadTag,
  updateHeadTag,
} from '@voix/composables/queries/useQueryHeadTags'
import { useHeadTagUtils } from '@voix/composables/useHeadTagUtils'
import type { HeadTagInterface } from '@voix/types'

const props = defineProps({
  headTag: {
    type: Object as () => HeadTagInterface,
    required: true,
  },
})

const emit = defineEmits(['back'])

const localHeadTag = ref({
  id: props.headTag.id,
  type: props.headTag.type,
  content: props.headTag.content,
  is_partytown: props.headTag.is_partytown,
  position: props.headTag.position,
})

const { availableTags } = useHeadTagUtils()

const selectedType = computed(() => {
  return availableTags.find(tag => tag.key === localHeadTag.value.type)
})

function requestSave() {
  updateHeadTag({
    id: localHeadTag.value.id,
    type: localHeadTag.value.type,
    content: localHeadTag.value.content,
    is_partytown: localHeadTag.value.is_partytown,
    position: localHeadTag.value.position,
  }).then(() => {
    goBack()
  })
}

watchEffect(() => {
  if (localHeadTag.value.type === 'script' || localHeadTag.value.type === 'externalScript')
    localHeadTag.value.is_partytown = true
})

const confirmDelete = ref('')
const confirmationString = 'delete head tag'
const deleteConfirmed = computed(() => {
  return confirmDelete.value === confirmationString
})

function goBack() {
  emit('back')
}

function requestCancel() {
  goBack()
}

function requestDelete() {
  if (deleteConfirmed.value) {
    deleteHeadTag({
      id: localHeadTag.value.id,
    }).then(() => {
      goBack()
    })
  }
}
</script>

<template>
  <AdministrationChrome
    title="Manage Head Tag"
    :links="[
      {
        label: 'Back to Head Tags',
        emit: 'back',
      },
    ]"
    @back="goBack"
  >
    <FormSection
      title="Head Tag Settings"
    >
      <FormField label="Tag Type">
        <select
          v-model="localHeadTag.type"
          class="w-full border border-gray-300 rounded-md px-3 py-2"
        >
          <option v-for="tag in availableTags" :key="tag.key" :value="tag.key">
            {{ tag.label }}
          </option>
        </select>

        <AdminAlert v-if="selectedType" class="mt-4">
          {{ selectedType.description }}
        </AdminAlert>
      </FormField>

      <FormField label="Content">
        <textarea
          v-model="localHeadTag.content"
          type="text"
          class="w-full min-h-[200px] border border-gray-300 rounded-md px-3 py-2"
        />
      </FormField>

      <FormField label="Tag Position">
        <DropdownSelect
          id="tag-position"
          v-model="localHeadTag.position"
          :options="[
            { label: 'Head', value: 'head', key: 'head' },
            { label: 'Body Open', value: 'bodyOpen', key: 'bodyOpen' },
            { label: 'Body Close', value: 'bodyClose', key: 'bodyClose' },
          ]"
        />
      </FormField>

      <FormField
        label="Delete Head Tag"
        description="If you would like to delete this head tag input the confirmation text and click the button on the right"
      >
        <div class="flex flex-col space-y-2">
          <input
            v-model="confirmDelete"
            type="input"
            class="w-full border border-gray-300 rounded-md px-3 py-2"
            :placeholder="`Type '${confirmationString}' to confirm`"
          >
          <AdminButton
            type="danger"
            :disabled="!deleteConfirmed"
            @click="requestDelete"
          >
            Delete Head Tag
          </AdminButton>
        </div>
      </FormField>
    </FormSection>

    <SubmissionGroup>
      <AdminButton type="primary" @click="requestSave">
        Save
      </AdminButton>
      <AdminButton type="secondary" @click="requestCancel">
        Cancel
      </AdminButton>
    </SubmissionGroup>
  </AdministrationChrome>
</template>
