<script setup lang="ts"></script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 13">
    <path
      transform="translate(2.5, 2)"
      d="M0,0V1.987H.5A1,1,0,0,1,1.49.994h1.49V6.458a.492.492,0,0,1-.5.5h-.5v.994H5.961V6.955h-.5a.492.492,0,0,1-.5-.5V.994h1.49a1,1,0,0,1,.994.994h.5V0Z"
      fill="currentColor"
    />
  </svg>
</template>
