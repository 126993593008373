<script setup lang="ts">
import { computed, ref, watch } from 'vue'

import {
  getModelData,
  getModels,
} from '@voix/composables/queries/useQueryModels'

import type { ComputedRef, Ref } from 'vue'
import type { ElementInterface, FieldConfigInterface } from '@voix/types'

import VoixTable from '@voix/components/tables/VoixTable.vue'
import { useSliceStore } from '@voix/store/sliceStore'
import DropdownSelect from './DropdownSelect.vue'

const props = defineProps<{
  slice: ElementInterface
}>()

defineEmits(['applySelection', 'cancelSelection'])

const models: Ref<{ data: Array<any> }> = ref({ data: [] })
getModels({ first: 25, page: 1 }).then((data) => {
  models.value = data
})

const modelMapDone = ref(false)
const selectedModel: Ref<{
  id: string
  model: string
  label: string
  queries: {
    index: string
  }
  fields: [
    {
      name: string
      type: string
      hidden: boolean
      allow_null: boolean
    },
  ]
} | null> = ref(null)

const step = computed(() => {
  if (!selectedModel.value)
    return 'selectModel'

  if (!modelMapDone.value)
    return 'mapData'

  return 'selectData'
})

const modelMap: Ref<
    {
      model_field: string
      value_type: string
      element_field: string
    }[]
  > = ref([])
const modelRecords = ref([])
const modelRecordsPage = ref(1)
const modelRecordsSortColumn = ref('')
const modelRecordsSortDirection = ref('')
const modelRecordsFilters = ref('')
const selectedItems: Ref<Array<any>> = ref([])
const isGloballySelected = ref(false)

function unselect() {
  selectedModel.value = null
  modelMapDone.value = false
  modelMap.value = []
  modelRecords.value = []
  modelRecordsPage.value = 1
  modelRecordsSortColumn.value = ''
  modelRecordsSortDirection.value = ''
  modelRecordsFilters.value = ''
  selectedItems.value = []
  isGloballySelected.value = false
}

const modelFieldOptions = computed(() => {
  const options: Array<{ label: string, value: string, key: string }> = [
    {
      label: `Select a ${selectedModel.value?.label} field`,
      value: '',
      key: 'null',
    },
  ]

  if (selectedModel.value) {
    selectedModel.value.fields.forEach((field) => {
      options.push({
        label: field.name,
        value: field.name,
        key: field.name,
      })
    })
  }

  return options
})

function addSelectedItem(item: any) {
  selectedItems.value.push(item)
}

const anySelected = computed(() => {
  return selectedItems.value.length > 0
})

const modelRecordsColumns = computed(() => {
  const columns: Array<{
    field: string
    label: string
    visible: boolean
    sortable: boolean
    searchable: boolean
  }> = []

  if (selectedModel.value && selectedModel.value.queries?.index) {
    selectedModel.value.fields.forEach((field) => {
      if (!field.hidden) {
        columns.push({
          field: field.name,
          label: field.name.replace(/_/g, ' '),
          visible: true,
          sortable: true,
          searchable: true,
        })
      }
    })
  }
  return columns
})

const dynamicQuery = computed(() => {
  let query = ''
  if (selectedModel.value && selectedModel.value.queries?.index)
    query = selectedModel.value.queries.index

  return query
})

const modelQueryOrderBy = computed(() => {
  if (modelRecordsSortColumn.value && modelRecordsSortDirection.value) {
    return [
      {
        column: modelRecordsSortColumn.value.toUpperCase(),
        order: modelRecordsSortDirection.value.toUpperCase(),
      },
    ]
  }
  return []
})

watch(
  [modelMapDone, modelRecordsFilters, modelQueryOrderBy, modelRecordsPage],
  () => {
    getModelData(dynamicQuery.value, {
      filter: modelRecordsFilters.value,
      orderBy: modelQueryOrderBy.value,
      first: 10,
      page: modelRecordsPage.value,
    }).then((data) => {
      modelRecords.value = data.data
    })
  },
)

const sliceStore = useSliceStore()
const fieldConfiguration = computed(() => {
  if (props.slice?.component)
    return sliceStore.slices[props.slice.component]

  return null
})

function setModelMap(field: FieldConfigInterface, modelField: string) {
  const existingMap = modelMap.value.find(
    map => map.element_field === field.name,
  )

  // If the modelField is empty, remove the map
  if (!modelField && existingMap) {
    modelMap.value.splice(modelMap.value.indexOf(existingMap), 1)
    return
  }

  // If the map exists, update it
  if (existingMap) {
    existingMap.model_field = modelField
    existingMap.value_type = field.type
    return
  }

  // If the map doesn't exist, create it
  if (!existingMap) {
    modelMap.value.push({
      model_field: modelField,
      value_type: field.type,
      element_field: field.name,
    })
  }
}

const modelQueryFilters: ComputedRef<string> = computed(() => {
  if (isGloballySelected.value)
    return modelRecordsFilters.value

  if (anySelected.value) {
    const filters: Array<{
      field: string
      op: string
      value: Array<string>
      clause: string
    }> = []

    const selectedIds: Array<string> = []

    selectedItems.value.forEach((item) => {
      selectedIds.push(item)
    })

    filters.push({
      field: 'id',
      op: '',
      value: selectedIds,
      clause: 'whereIn',
    })
    return JSON.stringify(filters)
  }

  return ''
})

// This is the variable we will be working to populate and is the whole point of this component

const modelQuery = computed(() => {
  return {
    model: selectedModel.value?.model || '',
    query: {
      filters: modelQueryFilters.value,
      order_by: JSON.stringify(modelQueryOrderBy.value),
    },
    map: modelMap.value,
  }
})
</script>

<template>
  <div class="p-2">
    <template v-if="step === 'selectModel'">
      <div class="py-2 text-sm border-b border-gray-200 mb-2">
        <div class="font-medium voix-admin-text">
          Select a Model
        </div>
        <div>
          These are databases in your system that you can map data to on the
          page. Select the database you want to utilize.
        </div>
      </div>
      <ul class="flex flex-col space-y-1">
        <li v-for="(model, key) in models.data" :key="key">
          <button
            class="group py-3 px-4 bg-gray-100 hover:voix-admin-bg-lightest rounded w-full text-left"
            @click="selectedModel = model"
          >
            <span
              class="text-gray-600 group-hover:voix-admin-text font-medium text-sm"
            >{{ model.label }}</span>
          </button>
        </li>
      </ul>
    </template>

    <template v-if="step === 'mapData'">
      <div class="py-2 text-sm border-b border-gray-200 mb-2">
        <div class="font-medium voix-admin-text">
          Map your fields
        </div>
        <div class="">
          The left column represents the fields in your
          <strong>{{ selectedModel?.label }}</strong>. The right are fields in the slice you currently have selected.
        </div>
      </div>
      <div class="mt-4 grid grid-cols-2 divide-y divide-gray-100 gap-x-3">
        <template v-for="(field, key) in fieldConfiguration?.fields" :key="key">
          <div>
            <DropdownSelect
              :id="`field-select-${key}`"
              :options="modelFieldOptions"
              @input="($event) => setModelMap(field, $event)"
            />
          </div>
          <div class="flex space-x-4 items-center font-medium">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-4 h-4"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
              />
            </svg>

            <span>{{ field.label }}</span>
          </div>
        </template>
      </div>
    </template>

    <template v-if="step === 'selectData'">
      <div class="py-2 text-sm border-b border-gray-200 mb-2">
        <div class="font-medium voix-admin-text">
          Select your data
        </div>
        <div class="">
          You can select in three modes: One record at a time, all that you can
          see, and all that you can filter.
        </div>
      </div>
      <div class="-ml-2 -mr-2 -mt-2 -mb-4 bg-gray-100 hover:voix-admin-bg-lightest">
        <button class="flex items-center space-x-1.5 p-2" @click="unselect">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-5 h-5"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
            />
          </svg>
          <span>Select a Different Data Model</span>
        </button>
      </div>

      <VoixTable
        v-model:items="modelRecords"
        v-model:page="modelRecordsPage"
        v-model:sortColumn="modelRecordsSortColumn"
        v-model:sortDirection="modelRecordsSortDirection"
        v-model:filters="modelRecordsFilters"
        v-model:selectedItems="selectedItems"
        v-model:is-globally-selected="isGloballySelected"
        interaction-mode="select"
        :can-globally-select-items="true"
        :columns="modelRecordsColumns"
        @add-selected-item="addSelectedItem"
      />
    </template>

    <div class="flex items-center justify-between py-2">
      <div>
        <button
          v-if="step === 'mapData'"
          class="py-2 px-4 voix-admin-bg hover:voix-admin-bg text-white rounded"
          @click="modelMapDone = true"
        >
          Done Mapping
        </button>
        <button
          v-if="isGloballySelected && step === 'selectData'"
          class="py-2 px-4 voix-admin-bg hover:voix-admin-bg text-white rounded"
          @click="$emit('applySelection', modelQuery)"
        >
          Apply Filter
        </button>
        <button
          v-if="anySelected && !isGloballySelected && step === 'selectData'"
          class="py-2 px-4 voix-admin-bg hover:voix-admin-bg text-white rounded"
          @click="$emit('applySelection', modelQuery)"
        >
          Apply Selection
        </button>
      </div>
      <button
        class="py-2 px-4 bg-gray-100 hover:bg-red-100 text-gray-500 hover:text-red-600 rounded"
        @click="$emit('cancelSelection')"
      >
        Cancel Selection
      </button>
    </div>
  </div>
</template>
