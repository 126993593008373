<script setup lang="ts">
import { computed, ref, watch } from 'vue'

import VoixTable from '@voix/components/tables/VoixTable.vue'
import AdministrationChrome from '@voix/voix-layer/components/administration/AdministrationChrome.vue'

import { getHeadTags } from '@voix/composables/queries/useQueryHeadTags'
import { useHeadTagStore } from '@voix/store/headTagStore'

import type { HeadTagInterface } from '@voix/types'

const props = defineProps({
  pageId: {
    type: String,
    required: true,
  },
})

const emit = defineEmits(['new', 'select'])

const headStore = useHeadTagStore()

const page = ref(1)
const sortColumn = ref('')
const sortDirection = ref('')
const filters = ref('')
const first = ref(25)

const orderBy = computed(() => {
  if (sortColumn.value && sortDirection.value) {
    return [
      {
        column: sortColumn.value.toUpperCase(),
        order: sortDirection.value.toUpperCase(),
      },
    ]
  }
  return []
})

const currentPageFilter = {
  field: 'page_id',
  op: '=',
  value: props.pageId,
  clause: 'where',
}

const finalFilter = computed(() => {
  if (filters.value) {
    const filtersFromTable = JSON.parse(filters.value)
    filtersFromTable.push(currentPageFilter)
    return JSON.stringify(filtersFromTable)
  }

  return JSON.stringify([currentPageFilter])
})

await getHeadTags({
  filter: finalFilter.value,
  orderBy: orderBy.value,
  first: first.value,
  page: page.value,
})

watch([filters, page, sortColumn, sortDirection, first], async () =>
  await getHeadTags({
    filter: finalFilter.value,
    orderBy: orderBy.value,
    first: first.value,
    page: page.value,
  }))

const columns = [
  {
    field: 'type',
    label: 'Type',
    visible: true,
    sortable: true,
    searchable: true,
  },
  {
    field: 'content',
    label: 'Content / URL',
    visible: true,
    sortable: true,
    searchable: true,
  },
]

function selectHeadTag(head: HeadTagInterface) {
  emit('select', head)
}

function requestNewHeadTag() {
  emit('new')
}

function goToPage(newPage: number) {
  page.value = newPage
}
</script>

<template>
  <AdministrationChrome
    title="Manage Page Head Tags"
    :links="[{ label: 'Add New Head Tag', emit: 'new' }]"
    :permissions="['HeadTags']"
    @new="requestNewHeadTag"
  >
    <VoixTable
      v-model:items="headStore.headTags.data"
      v-model:page="page"
      v-model:sortColumn="sortColumn"
      v-model:sortDirection="sortDirection"
      v-model:filters="filters"
      v-model:first="first"
      :columns="columns"
      interaction-mode="link"
      :paginator-info="headStore.headTags.meta"
      @go-to-page="goToPage"
      @select="selectHeadTag"
    />
  </AdministrationChrome>
</template>
