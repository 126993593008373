import TextStyle from '@tiptap/extension-text-style'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    fontSize: {
      /**
             * Set the font size
             */
      setFontSize: (size: string) => ReturnType
      /**
             * Unset the font size
             */
      unsetFontSize: () => ReturnType
    }
  }
}

export const FontSize = TextStyle.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      fontSize: {
        default: null,
        parseHTML: (element: HTMLElement) => element.style.fontSize.replace('px', ''),
        renderHTML: (attributes: any) => {
          if (!attributes.fontSize)
            return {}

          return {
            style: `font-size: ${attributes.fontSize}`,
          }
        },
      },
    }
  },

  addCommands() {
    return {
      ...this.parent?.(),
      setFontSize:
                (fontSize: any) =>
                  ({ commands }: { commands: any }) => {
                    return commands.setMark(this.name, { fontSize })
                  },
      unsetFontSize:
                () =>
                  ({ chain }: { chain: any }) => {
                    return chain()
                      .setMark(this.name, { fontSize: null })
                      .removeEmptyTextStyle()
                      .run()
                  },
    }
  },
})
