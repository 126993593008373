<script setup lang="ts">
import { computed, reactive, ref } from 'vue'
import type { ComputedRef, PropType, Ref } from 'vue'

import { useImage } from '#imports'

import { Cropper, Preview } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

import type { MediaFieldBreakpointConfigInterface, MediaFieldPropertiesInterface } from '@voix/types'

const props = defineProps({
  breakpoint: {
    type: Object as PropType<MediaFieldPropertiesInterface>,
    required: true,
  },
  config: {
    type: Object as PropType<MediaFieldBreakpointConfigInterface>,
    required: true,
  },
})

const emit = defineEmits(['update', 'cancel', 'clearCrop'])

const modal: Ref<null | HTMLDialogElement> = ref(null)

interface MediaCropStateInterface {
  aspectOverride: 'original' | '16:9' | '9:16' | '4:3' | '3:4' | '3:2' | '2:3' | '1:1' | '2:1' | '1:2' | 'custom'
  transparencyOverride: boolean
  result: {
    coordinates: {
      left: number
      top: number
      width: number
      height: number
    }
    image: null | { src: string; height: number; width: number; transforms: object }
  }
}

const state: MediaCropStateInterface = reactive({
  aspectOverride: 'original',
  transparencyOverride: false,
  result: {
    coordinates: {
      left: 0,
      top: 0,
      width: 0,
      height: 0,
    },
    image: null,
  },
})

const nuxtImage = useImage()
function calculateImageUrl() {
  if (typeof props.breakpoint === 'object') {
    const finalModifiers = {
      ...JSON.parse(props.breakpoint.modifiers),
    }
    delete finalModifiers.width
    delete finalModifiers.height
    return nuxtImage(props.breakpoint.url, finalModifiers, { provider: props.breakpoint.provider })
  }

  return ''
}
const imageUrl = calculateImageUrl()

function gcd(a: number, b: number) {
  if (b < 0.0000001)
    return a // Since there is a limited precision we need to limit the value.

  // TODO: Error will occur here when there is a height or width missing. Maybe alert the user they can't crop if we don't have both dims?
  return gcd(b, Math.floor(a % b)) // Discard any fractions due to limitations in precision.
};

const aspectRatio: ComputedRef<number> = computed(() => {
  const width = props.config.width || props.config.height
  const height = props.config.height || props.config.width

  if (state.aspectOverride === 'custom')
    return state.result.coordinates.height / state.result.coordinates.width

  if (typeof props.config === 'object' && state.aspectOverride === 'original')
    return width / height

  // if the string contains a colon, it's a ratio
  if (state.aspectOverride.includes(':')) {
    const [numerator, denominator] = state.aspectOverride.split(':')
    return Number.parseInt(numerator) / Number.parseInt(denominator)
  }

  return state.result.coordinates ? state.result.coordinates.width / state.result.coordinates.height : 1
})

const fractionAspectRatio = computed(() => {
  if (!aspectRatio.value) {
    return {
      numerator: 1,
      denominator: 1,
    }
  }

  const len = aspectRatio.value.toString().length - 2
  let denominator = 10 ** len
  let numerator = aspectRatio.value * denominator

  if (Number.isNaN(numerator))
    numerator = denominator

  if (Number.isNaN(denominator))
    denominator = numerator

  const divisor = gcd(numerator, denominator)

  return {
    numerator: numerator / divisor,
    denominator: denominator / divisor,
  }
})

function updateResult({ coordinates, image }: { coordinates: { left: number; top: number; width: number; height: number }; image: { src: string; height: number; width: number; transforms: object } }) {
  state.result.coordinates.left = coordinates.left
  state.result.coordinates.top = coordinates.top
  state.result.coordinates.width = coordinates.width
  state.result.coordinates.height = coordinates.height
  state.result.image = image
}

const previewHeight = computed(() => {
  return 215 / state.result.coordinates.width * state.result.coordinates.height
})

const cropDataHelp = computed (() => {
  return `<div class="grid grid-cols-2 text-2xs text-sm gap-y-1">
    <div>Image Width</div>
    <div class="text-right">${state.result.image?.width}px</div>
    <div>Image Height</div>
    <div class="text-right">${state.result.image?.height}px</div>
    <div>Crop Top</div>
    <div class="text-right">${state.result.coordinates.top}px</div>
    <div>Crop Left</div>
    <div class="text-right">${state.result.coordinates.left}px</div>
    <div>Crop Width</div>
    <div class="text-right">${state.result.coordinates.width}px</div>
    <div>Crop Height</div>
    <div class="text-right">${state.result.coordinates.height}px</div>
  </div>`
})

const scale = computed(() => {
  if (props.config.width && state.result.coordinates.width)
    return props.config.width / state.result.coordinates.width

  if (props.config.height && state.result.coordinates.height)
    return props.config.height / state.result.coordinates.height

  return 1
})

function clearCrop() {
  setTimeout(() => {
    emit('clearCrop')
  }, 1000)
}

const finalHeight = computed(() => {
  if (props.config.height)
    return props.config.height

  if (props.config.width && aspectRatio.value)
    return Math.round(props.config.width * aspectRatio.value)

  return 0
})
</script>

<template>
  <div class="flex justify-center items-center">
    <div ref="modal" class="rounded-lg overflow-hidden text-sm min-w-[500px] w-[90vw] h-[80vh] flex">
      <div class="flex w-full">
        <div class="relative p-4 bg-slate-900 text-slate-100 w-[250px] flex-none overflow-hidden ">
          <div class="absolute inset-0 overflow-y-scroll overflow-x-hidden pl-4 pr-6 pt-4 pb-12 w-[270px]">
            <div class="relative">
              <div class="relative rounded overflow-hidden bg-slate-800 " :style="{ height: `${previewHeight}px` }">
                <div
                  class="absolute inset-0 z-0 background-grid"
                />
                <Preview
                  class="relative z-10 mb-4 duration-500 transition-all ease-out"
                  :width="215"
                  :height="previewHeight"
                  :image="state.result.image"
                  :coordinates="state.result.coordinates"
                />
              </div>
              <form method="dialog" class="pt-4 grid grid-cols-8 gap-1">
                <button class="voix-admin-bg text-white font-medium text-xs px-4 py-2 rounded col-span-5" @click="emit('update', state.result.coordinates, state.result.image, scale)">
                  Update Crop
                </button>
                <button class="bg-slate-700 text-slate-200 font-medium text-xs px-4 py-2 rounded col-span-3" @click="emit('cancel')">
                  Cancel
                </button>
                <button
                  class="bg-slate-800 hover:bg-slate-700 text-slate-500 hover:text-sla font-medium text-xs px-4 py-2 rounded col-span-8"
                  @click="clearCrop"
                >
                  Clear Crop
                </button>
              </form>
            </div>

            <div class="pt-4 text-slate-400" v-html="cropDataHelp " />

            <div class="mt-4 flex flex-col space-y-4">
              <dl class="grid grid-cols-8 gap-x-3 gap-y-4">
                <dt class="col-span-5 flex items-center">
                  Final Width
                </dt>
                <dd class="text-center col-span-3 text-uppercase text-2xs font-medium py-1.5 px-2 bg-slate-700 text-slate-400 rounded ">
                  {{ config.width }}px
                </dd>
                <dt class="col-span-5 flex items-center">
                  Final Height
                </dt>
                <dd class="text-center col-span-3 text-uppercase text-2xs font-medium py-1.5 px-2 bg-slate-700 text-slate-400 rounded ">
                  {{ finalHeight }}px
                </dd>
              </dl>
            </div>
            <div class="mt-4 flex flex-col space-y-2">
              <div class="col-span-5 flex items-center">
                Aspect Ratio
              </div>
              <div class="grid grid-cols-3 gap-1">
                <button
                  :title="`${fractionAspectRatio.numerator} / ${fractionAspectRatio.denominator}`"
                  class="text-center text-uppercase text-2xs font-medium py-1.5 px-2 bg-slate-800 text-slate-300 rounded line-clamp-1"
                  :class="{
                    'bg-slate-800 text-slate-300': state.aspectOverride !== 'original',
                    'voix-admin-bg-dark text-slate-100': state.aspectOverride === 'original',
                  }"
                >
                  Original
                </button>
                <button
                  title="Square"
                  class="text-center text-uppercase text-2xs font-medium py-1.5 px-2 rounded line-clamp-1"
                  :class="{
                    'bg-slate-800 text-slate-300': state.aspectOverride !== '1:1',
                    'voix-admin-bg-dark text-slate-100': state.aspectOverride === '1:1',
                  }"
                  @click="state.aspectOverride = '1:1'"
                >
                  1:1
                </button>
                <button
                  title="Like a movie!"
                  class="text-center text-uppercase text-2xs font-medium py-1.5 px-2 rounded line-clamp-1"
                  :class="{
                    'bg-slate-800 text-slate-300': state.aspectOverride !== '16:9',
                    'voix-admin-bg-dark text-slate-100': state.aspectOverride === '16:9',
                  }"
                  @click="state.aspectOverride = '16:9'"
                >
                  16:9
                </button>
                <button
                  title="Like old TV!"
                  class="text-center text-uppercase text-2xs font-medium py-1.5 px-2 rounded line-clamp-1"
                  :class="{
                    'bg-slate-800 text-slate-300': state.aspectOverride !== '4:3',
                    'voix-admin-bg-dark text-slate-100': state.aspectOverride === '4:3',
                  }"
                  @click="state.aspectOverride = '4:3'"
                >
                  4:3
                </button>
                <button
                  class="text-center text-uppercase text-2xs font-medium py-1.5 px-2 rounded line-clamp-1"
                  :class="{
                    'bg-slate-800 text-slate-300': state.aspectOverride !== '2:1',
                    'voix-admin-bg-dark text-slate-100': state.aspectOverride === '2:1',
                  }"
                  @click="state.aspectOverride = '2:1'"
                >
                  2:1
                </button>
                <button
                  class="text-center text-uppercase text-2xs font-medium py-1.5 px-2 rounded line-clamp-1"
                  :class="{
                    'bg-slate-800 text-slate-300': state.aspectOverride !== 'custom',
                    'voix-admin-bg-dark text-slate-100': state.aspectOverride === 'custom',
                  }"
                  @click="state.aspectOverride = 'custom'"
                >
                  Custom
                </button>
              </div>
            </div>
            <div class="mt-4 flex flex-col space-y-2">
              <div class="col-span-5 flex items-center">
                Allow Transparency
              </div>

              <div class="relative z-20 grid grid-cols-2 gap-1">
                <button
                  class="text-center text-uppercase text-2xs font-medium py-1.5 px-2  rounded line-clamp-1 "
                  :class="{
                    'bg-slate-800 text-slate-300': state.transparencyOverride,
                    'voix-admin-bg-dark text-slate-100': !state.transparencyOverride,
                  }"
                  @click="state.transparencyOverride = false"
                >
                  No
                </button>
                <button
                  class="text-center text-uppercase text-2xs font-medium py-1.5 px-2  rounded line-clamp-1 "
                  :class="{
                    'bg-slate-800 text-slate-300': !state.transparencyOverride,
                    'voix-admin-bg-dark text-slate-100': state.transparencyOverride,
                  }"
                  @click="state.transparencyOverride = true"
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-1 overflow-hidden bg-black">
          <Cropper
            class="cropper w-full h-full"
            :stencil-props="{
              aspectRatio: state.aspectOverride !== 'custom' ? (aspectRatio || null) : null,
              movable: true,
              resizable: true,
            }"
            :image-restriction="state.transparencyOverride ? 'none' : 'contain'"
            :src="imageUrl"
            @change="updateResult"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.background-grid {
  background-size: 16px 16px;
  background-image:url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23000000' d='M0 0h8v8H0zm8 8h8v8H8z'/%3E%3C/svg%3E")
}
</style>
