<script setup lang="ts">
import { computed } from 'vue'

import type {
  FlexPropertiesInterface,
  GridPropertiesInterface,
  GroupSettingsInterface,
} from '@voix/types'

import DropdownSelect from '@voix/components/chrome/controls/DropdownSelect.vue'
import NumberInput from '@voix/components/chrome/controls/NumberInput.vue'
import TextInput from '@voix/components/chrome/controls/TextInput.vue'
import SwitchInput from '@voix/components/chrome/controls/SwitchInput.vue'

import { useElementSettings } from '@voix/composables/useElementSettings'
import PanelSection from '../PanelSection.vue'

const props = defineProps<{
  settings: GroupSettingsInterface
}>()

const emit = defineEmits(['update'])

function updateSettings(newValue: object) {
  emit('update', {
    ...props.settings,
    ...newValue,
  })
}

const {
  groupDisplayModeOptions,
  flexWrapOptions,
  flexDirectionOptions,
  flexJustifyOptions,
  flexAlignOptions,
} = useElementSettings()

const contain = computed({
  get: (): boolean => {
    return props.settings.contain ? props.settings.contain : false
  },
  set(value: boolean) {
    updateSettings({ contain: value })
  },
})

const displayMode = computed({
  get: (): string => {
    return props.settings.displayMode ? props.settings.displayMode : ''
  },
  set(value: string) {
    updateSettings({ displayMode: value })
  },
})

const gridSettings = computed({
  get: (): GridPropertiesInterface => {
    if (
      props.settings.displayMode === 'grid'
        || props.settings.displayMode === 'inline-grid'
    )
      return props.settings.grid ? props.settings.grid : {}

    return {}
  },
  set(value: GridPropertiesInterface) {
    updateSettings({ grid: value })
  },
})

const gridColumns = computed({
  get: (): number => {
    return gridSettings.value?.columns ? gridSettings.value.columns : 1
  },
  set(value: number) {
    gridSettings.value = {
      ...gridSettings.value,
      columns: value,
    }
  },
})

const gridUnifiedGap = computed({
  get: (): boolean => {
    return gridSettings.value?.unifiedGap !== undefined
      ? gridSettings.value.unifiedGap
      : true
  },
  set(value: boolean) {
    gridSettings.value = {
      ...gridSettings.value,
      unifiedGap: value,
    }
  },
})

const gridGap = computed({
  get: (): number => {
    return gridSettings.value?.gap ? gridSettings.value.gap : 0
  },
  set(value: number) {
    gridSettings.value = {
      ...gridSettings.value,
      gap: value,
    }
  },
})

const gridGapX = computed({
  get: (): number => {
    return gridSettings.value?.gapX ? gridSettings.value.gapX : 0
  },
  set(value: number) {
    gridSettings.value = {
      ...gridSettings.value,
      gapX: value,
    }
  },
})

const gridGapY = computed({
  get: (): number => {
    return gridSettings.value?.gapY ? gridSettings.value.gapY : 0
  },
  set(value: number) {
    gridSettings.value = {
      ...gridSettings.value,
      gapY: value,
    }
  },
})

const flexSettings = computed({
  get: (): FlexPropertiesInterface => {
    if (
      props.settings.displayMode === 'flex'
        || props.settings.displayMode === 'inline-flex'
    )
      return props.settings.flex ? props.settings.flex : {}

    return {}
  },
  set(value: FlexPropertiesInterface) {
    updateSettings({ flex: value })
  },
})

const flexDirection = computed({
  get: (): string => {
    return flexSettings.value?.direction
      ? flexSettings.value.direction
      : 'row'
  },
  set(value: string) {
    flexSettings.value = {
      ...flexSettings.value,
      direction: value as 'row' | 'row-reverse' | 'column' | 'column-reverse',
    }
  },
})

const flexWrap = computed({
  get: (): string => {
    return flexSettings.value?.wrap ? flexSettings.value.wrap : 'norap'
  },
  set(value: string) {
    flexSettings.value = {
      ...flexSettings.value,
      wrap: value as 'nowrap' | 'wrap' | 'wrap-reverse',
    }
  },
})

const flexJustify = computed({
  get: (): string => {
    return flexSettings.value?.justify ? flexSettings.value.justify : 'norap'
  },
  set(value: string) {
    flexSettings.value = {
      ...flexSettings.value,
      justify: value as
          | 'flex-start'
          | 'flex-end'
          | 'center'
          | 'space-between'
          | 'space-around',
    }
  },
})

const flexAlign = computed({
  get: (): string => {
    return flexSettings.value?.align ? flexSettings.value.align : 'norap'
  },
  set(value: string) {
    flexSettings.value = {
      ...flexSettings.value,
      align: value as 'stretch' | 'center' | 'flex-start' | 'flex-end',
    }
  },
})
</script>

<template>
  <PanelSection
    id="display-mode-properties"
    name="Display Mode Properties"
    :start-open="false"
  >
    <div>
      <SwitchInput
        v-model="contain"
        label="Contain"
      />
    </div>
    <div class="mt-4 flex justify-beween space-x-4">
      <DropdownSelect
        id="display-mode"
        v-model="displayMode"
        :options="groupDisplayModeOptions"
        placeholder="Select a Display Mode"
        label="Display Mode"
        class="w-full"
      />
      <div v-if="settings.displayMode === 'grid'">
        <NumberInput
          v-if="gridSettings"
          id="group-columns"
          v-model="gridColumns"
          min="1"
          step="1"
          label="Columns"
        />
      </div>
    </div>

    <div
      v-if="
        'displayMode' in settings
          && 'grid' in settings
          && gridSettings
          && (settings.displayMode === 'grid'
            || settings.displayMode === 'inline-grid')
      "
      class="mt-4"
    >
      <div class="mt-3 grid grid-cols-4 gap-4">
        <TextInput
          id="group-gap"
          v-model="gridGap"
          label="Gap"
          :disabled="!gridUnifiedGap"
        />

        <TextInput
          id="group-gap-x"
          v-model="gridGapX"
          label="Gap X"
          :disabled="gridUnifiedGap"
        />
        <TextInput
          id="group-gap-y"
          v-model="gridGapY"
          label="Gap Y"
          :disabled="gridUnifiedGap"
        />

        <SwitchInput v-model="gridUnifiedGap" label="Unified" />
      </div>
    </div>

    <div
      v-if="
        settings.displayMode === 'flex'
          || settings.displayMode === 'inline-flex'
      "
      class="mt-4"
    >
      <div class="mt-3 grid grid-cols-2 gap-4">
        <DropdownSelect
          id="flex-direction"
          v-model="flexDirection"
          :options="flexDirectionOptions"
          label="Flex Direction"
          class="w-full"
        />
        <DropdownSelect
          id="flex-wrap"
          v-model="flexWrap"
          :options="flexWrapOptions"
          label="Flex Wrap"
          class="w-full"
        />
      </div>
      <div class="mt-4 grid grid-cols-2 gap-4">
        <DropdownSelect
          id="flex-justify"
          v-model="flexJustify"
          :options="flexJustifyOptions"
          label="Flex Justification"
          class="w-full"
        />
        <DropdownSelect
          id="flex-align"
          v-model="flexAlign"
          :options="flexAlignOptions"
          label="Flex Alignment"
          class="w-full"
        />
      </div>
    </div>
  </PanelSection>
</template>
