<script lang="ts">
import { defineComponent } from 'vue'
import { useModelWrapper } from '@voix/composables/useModelWrapper'
import SectionLabel from '@voix/components/chrome/SectionLabel.vue'

export default defineComponent({
  components: { SectionLabel },
  inheritAttrs: false,
  props: {
    modelValue: {
      type: null,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    border: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit, slots }) {
    return { localValue: useModelWrapper(props, emit), props, slots }
  },
})
</script>

<template>
  <div
    :class="{
      'opacity-50': props.disabled,
    }"
  >
    <SectionLabel v-if="props.label">
      {{ props.label }}
    </SectionLabel>
    <div
      class="relative flex items-center pt-1 text-gray-400"
      :class="{
        ' border border-transparent border-b-gray-200 hover:border-gray-100':
          props.border,
      }"
    >
      <!-- Slot for SVG Icons -->
      <div class="mb-1">
        <slot />
      </div>

      <input
        :id="props.id"
        v-model="localValue"
        type="number"
        class="pt-1 pb-1.5 text-xs w-full h-full text-gray-500 bg-transparent placeholder-gray-300 border-b-2 border-transparent focus:border-b-gray-500 focus:outline-0"
        :class="{ '-ml-3 pl-5': slots.default, 'pl-2': !slots.default }"
        :placeholder="placeholder"
        :disabled="props.disabled"
        v-bind="$attrs"
      >
    </div>
  </div>
</template>

<style scoped></style>
