<script lang="ts">
import { usePageStore } from '@voix/store/pageStore'
import { useAdminStore } from '@voix/store/adminStore'

import CopySliceToPage from '@voix/components/chrome/slices/CopySliceToPage.vue'

import { defineAsyncComponent, defineComponent, onMounted, reactive, ref } from 'vue'

import type { Component } from 'vue'
import type {
  ElementInterface,
  GroupInterface,
  QuerySliceInterface,
  SliceInterface,
} from '@voix/types'

import VoixDrop from '../drag-and-drop/VoixDrop.vue'
import PageNavigationSlice from './PageNavigationSlice.vue'
import PageNavigationQuery from './PageNavigationQuery.vue'

const PageNavigationGroup: Component = defineAsyncComponent(() => {
  return new Promise((resolve) => {
    const PageNavigationGroup = import('./PageNavigationGroup.vue')
    resolve(PageNavigationGroup)
  })
})

export default defineComponent({
  name: 'PageNavigationElements',

  components: {
    PageNavigationSlice,
    PageNavigationQuery,
    PageNavigationGroup,
    VoixDrop,
    CopySliceToPage,
  },

  props: {
    parent: {
      type: Object as () => ElementInterface,
      default: null, // null is the root (or page)
    },
    slotName: {
      type: String,
      default: 'default',
    },
    elements: {
      type: Array as () => ElementInterface[],
      required: true,
    },
    depth: {
      type: Number,
      default: 1,
    },
  },

  setup(props) {
    const pageStore = usePageStore()
    const adminStore = useAdminStore()

    const showAction = ref('')

    // Holds the current element that is being right clicked on
    const currentContextItem = reactive<{
      element: ElementInterface | null
      x: number
      y: number
    }>({ element: null, x: 0, y: 0 })

    // Handles the right-click of the menu item
    function contextMenuHandler(event: MouseEvent, item: ElementInterface) {
      event.preventDefault()
      event.stopPropagation()

      currentContextItem.element = item
      currentContextItem.x = event.clientX
      currentContextItem.y = event.clientY
    }

    // Unsets the context menu
    function unsetContextMenu() {
      currentContextItem.element = null
    }

    const selectElement = (element: ElementInterface) => {
      unsetContextMenu()
      adminStore.setSelectedElement(element)
    }

    // TODO: There isn't anything to do with multi-selected elements yet but the selection part works
    const multiSelectElement = (element: ElementInterface) => {
      unsetContextMenu()

      // If there is a selected element we can do multi-select things
      if (adminStore.selectedElements.length > 0) {
        if (adminStore.selectedElements.includes(element)) {
          // This element is already selected, so we unselect it
          adminStore.removeFromSelectedElements(element)
        }
        else {
          // This element is not selected, so we select it
          adminStore.addToSelectedElements(element)
        }
      }
      else {
        // No elements are currently selected, so we can just select the element
        selectElement(element)
      }
    }

    const openElement = (element: ElementInterface) => {
      unsetContextMenu()
      pageStore.openElement(element)
    }
    const closeElement = (element: ElementInterface) => {
      unsetContextMenu()
      pageStore.closeElement(element)
    }

    const findElement = (element: ElementInterface) => {
      unsetContextMenu()
      adminStore.scrollToElement = element
    }

    const setSliceElement = (element: ElementInterface) => {
      return element as SliceInterface
    }

    const setGroupElement = (element: ElementInterface) => {
      return element as GroupInterface
    }

    const setQuerySliceElement = (element: ElementInterface) => {
      return element as QuerySliceInterface
    }

    onMounted(() => {
      //  This channel is for communcation between the iframes on VoixSlice and here.
      //  We have to do this b/c the instance of the slice INSIDE the frame is not the same
      // as the instance of this main window
      const channel = new BroadcastChannel('page-navigation-select-element')

      // A request has been made to the top window to select an element
      channel.onmessage = (data: any) => {
        adminStore.setSelectedElement(data.element)
      }
    })

    // When an element is dropped at the top of the data array
    function dropTopOfDataArray(id: string) {
      // If the user has dropped the element on the top of the data array
      // of a group or slice then we need to move it into that group or slice
      // at the top
      if (props.parent)
        pageStore.moveElementIntoElement(id, props.parent.id)

      // They have moved it to the top of the page
      else
        pageStore.moveElement(id, null)

      pageStore.setSlot(id, props.slotName)
    }

    // When an element is dropped in the middle of the data array on any divider that
    // isn't that top one. This could be in between a root level slice or inside a slice
    // as a child.
    function dropMiddleOfDataArray(id: string, destinationId: string) {
      if (id !== destinationId) {
        pageStore.moveElement(id, destinationId)
        pageStore.setSlot(id, props.slotName)
      }
    }

    function duplicateElement(element: ElementInterface) {
      unsetContextMenu()
      pageStore.duplicateElement(element.id)
    }

    function cancelCopyElementToPage() {
      showAction.value = ''
      unsetContextMenu()
    }

    function deleteElement(element: ElementInterface) {
      unsetContextMenu()
      pageStore.deleteElement(element.id)
    }

    return {
      props,
      pageStore,
      adminStore,
      currentContextItem,
      showAction,
      selectElement,
      multiSelectElement,
      openElement,
      closeElement,
      findElement,
      setSliceElement,
      setGroupElement,
      setQuerySliceElement,
      dropTopOfDataArray,
      dropMiddleOfDataArray,
      contextMenuHandler,
      unsetContextMenu,
      duplicateElement,
      cancelCopyElementToPage,
      deleteElement,
    }
  },
})
</script>

<template>
  <div v-if="pageStore.currentPage" class="pb-24">
    <VoixDrop
      v-slot="{ item }"
      :entire-data-array="pageStore.currentPage.elements"
      :data-array="props.elements"
      data-testid="voix-page-navigation-elements"
      children-key="elements"
      class="flex flex-col space-y-1"
      @drop-at-top="dropTopOfDataArray"
      @drop-in-middle="dropMiddleOfDataArray"
    >
      <PageNavigationSlice
        v-if="item.type === 'slice'"
        :id="`${item.id?.toString()}`"
        :key="item.id?.toString()"
        :slice="setSliceElement(item)"
        :depth="depth"
        data-testid="voix-page-navigation-element"
        @select-element="selectElement($event)"
        @multi-select-element="multiSelectElement($event)"
        @mouseenter="adminStore.setHighlightedElement(item)"
        @mouseleave="adminStore.unsetHighlightedElement()"
        @context="contextMenuHandler"
      />

      <PageNavigationQuery
        v-if="item.type === 'query'"
        :id="`${item.id?.toString()}`"
        :key="item.id"
        :query-slice="setQuerySliceElement(item)"
        :depth="depth"
        data-testid="voix-page-navigation-query"
        @select-element="selectElement(item)"
        @multi-select-element="multiSelectElement(item)"
        @find-item="findElement($event.target.value)"
        @mouseenter="adminStore.setHighlightedElement(item)"
        @mouseleave="adminStore.unsetHighlightedElement()"
        @context="contextMenuHandler"
      />

      <PageNavigationGroup
        v-if="item.type === 'group'"
        :id="`${item.id?.toString()}`"
        :key="item.id"
        :group="setGroupElement(item)"
        :depth="depth"
        data-testid="voix-page-navigation-group"
        @select-element="selectElement(item)"
        @multi-select-element="multiSelectElement(item)"
        @click.left.exact="openElement(item)"
        @mouseenter="adminStore.setHighlightedElement(item)"
        @mouseleave="adminStore.unsetHighlightedElement()"
        @context="contextMenuHandler"
      />
    </VoixDrop>

    <template v-if="currentContextItem.element">
      <Teleport to="#voix-admin-root">
        <div class="fixed inset-0" @click="unsetContextMenu" />

        <CopySliceToPage
          v-if="showAction === 'copyElementToPage'"
          :slice="currentContextItem.element"
          :slice-name="currentContextItem.element.component"
          class="fixed w-96 bg-gray-100 p-4 rounded text-sm shadow-2xl"
          :style="{
            top: `${currentContextItem.y}px`,
            left: `${currentContextItem.x}px`,
          }"
          @close="cancelCopyElementToPage"
        />

        <ul
          v-else
          class="fixed bg-white rounded-sm flex flex-col divide-y shadow-lg text-xs pr-8" :style="{
            top: `${currentContextItem.y}px`,
            left: `${currentContextItem.x}px`,
          }"
        >
          <li>
            <button class="px-4 py-2" @click="findElement(currentContextItem.element)">
              Find Element
            </button>
          </li>
          <li>
            <button class="px-4 py-2" @click="duplicateElement(currentContextItem.element)">
              Duplicate Element
            </button>
          </li>
          <li>
            <button class="px-4 py-2" @click="showAction = 'copyElementToPage'">
              Copy Element To Page
            </button>
          </li>
          <li>
            <button class="px-4 py-2" @click="deleteElement(currentContextItem.element)">
              Remove Element
            </button>
          </li>
        </ul>
      </Teleport>
    </template>
  </div>
</template>
