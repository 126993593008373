<script setup lang="ts">
// eslint-disable-next-line unused-imports/no-unused-vars
const props = defineProps({
  type: {
    type: String,
    required: true,
  },
})


        import TextIcon from '@voix/components/fields/text/Icon.vue'
        import NumberIcon from '@voix/components/fields/number/Icon.vue'
        import TextareaIcon from '@voix/components/fields/textarea/Icon.vue'
        import MediaIcon from '@voix/components/fields/media/Icon.vue'
        import WysiwygIcon from '@voix/components/fields/wysiwyg/Icon.vue'
        import SelectIcon from '@voix/components/fields/select/Icon.vue'
        import CheckboxIcon from '@voix/components/fields/checkbox/Icon.vue'
        import LinkIcon from '@voix/components/fields/link/Icon.vue'
        import DatetimeIcon from '@voix/components/fields/datetime/Icon.vue'
        import ColorIcon from '@voix/components/fields/color/Icon.vue'
        import FilesIcon from '@voix/components/fields/files/Icon.vue'
</script>

<template>
  <div class="-ml-0.5 mr-1.5 flex-none">
    
        <component 
        v-if="props.type === 'text'"
        :is="TextIcon"     
        class="w-4 h-4 " />
        <component 
        v-if="props.type === 'number'"
        :is="NumberIcon"     
        class="w-4 h-4 " />
        <component 
        v-if="props.type === 'textarea'"
        :is="TextareaIcon"     
        class="w-4 h-4 " />
        <component 
        v-if="props.type === 'media'"
        :is="MediaIcon"     
        class="w-4 h-4 " />
        <component 
        v-if="props.type === 'wysiwyg'"
        :is="WysiwygIcon"     
        class="w-4 h-4 " />
        <component 
        v-if="props.type === 'select'"
        :is="SelectIcon"     
        class="w-4 h-4 " />
        <component 
        v-if="props.type === 'checkbox'"
        :is="CheckboxIcon"     
        class="w-4 h-4 " />
        <component 
        v-if="props.type === 'link'"
        :is="LinkIcon"     
        class="w-4 h-4 " />
        <component 
        v-if="props.type === 'datetime'"
        :is="DatetimeIcon"     
        class="w-4 h-4 " />
        <component 
        v-if="props.type === 'color'"
        :is="ColorIcon"     
        class="w-4 h-4 " />
        <component 
        v-if="props.type === 'files'"
        :is="FilesIcon"     
        class="w-4 h-4 " />
  </div>
</template>

<style scoped></style>
