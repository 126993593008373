<script setup lang="ts">
import { computed } from 'vue'

import type { SliceSettingsInterface } from '@voix/types'

import ColorSelect from '@voix/components/chrome/controls/ColorSelect.vue'
import PanelSection from '../PanelSection.vue'

const props = defineProps<{
  settings: SliceSettingsInterface
}>()

const emit = defineEmits(['update'])

function updateSettings(newValue: object) {
  emit('update', {
    ...props.settings,
    ...newValue,
  })
}

const backgroundColor = computed({
  get: (): string => {
    return props.settings.backgroundColor
      ? props.settings.backgroundColor
      : ''
  },
  set(value: string) {
    updateSettings({ backgroundColor: value })
  },
})

const color = computed({
  get: (): string => {
    return props.settings.color ? props.settings.color : ''
  },
  set(value: string) {
    updateSettings({ color: value })
  },
})
</script>

<template>
  <PanelSection id="color-settings" name="Color Settings" :start-open="false">
    <div class="flex justify-beween space-x-4">
      <ColorSelect
        id="group-background-color-select"
        v-model="backgroundColor"
        label="Background Color"
      />
      <ColorSelect
        id="group-text-color-select"
        v-model="color"
        label="Text Color"
      />
    </div>
  </PanelSection>
</template>
