<script setup lang="ts">
import { computed, inject } from 'vue'
import type { Ref } from 'vue'
import DropdownSelect from '@voix/components/chrome/controls/DropdownSelect.vue'

import { useNuxtApp } from '#imports'

const { $voix } = useNuxtApp()

const provider: Ref<string> | undefined = inject('provider')

const sourceOptions = computed(() => {
  const providers: Array<{ label: string; value: string; key: string }> = []

  Object.keys($voix.options.mediaProviderConfig).forEach((providerKey) => {
    if ($voix.options.mediaProviderConfig[providerKey]) {
      const name = $voix.options.mediaProviderConfig[providerKey].name
      const provider = $voix.options.mediaProviderConfig[providerKey].provider
      if (name && provider) {
        providers.push({
          label: name,
          value: provider,
          key: provider,
        })
      }
    }
  })

  return providers
})

const localValue = computed({
  get() {
    if (provider)
      return provider.value

    return sourceOptions.value[0].value
  },
  set(newValue) {
    if (provider)
      provider.value = newValue
  },
})
</script>

<template>
  <div class="py-2 px-2 flex items-center border-b border-gray-200">
    <DropdownSelect
      id="media-manager-source-selector"
      v-model="localValue"
      :options="sourceOptions"
      :border="false"
      placeholder="Select a source"
      class="w-full"
    />
  </div>
</template>
