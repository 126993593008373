<script setup lang="ts">
import { useField } from '@voix/composables/useField'

import TextAreaInput from '@voix/components/chrome/controls/TextAreaInput.vue'
import PropertiesPopupPanel from '@voix/components/chrome/PropertiesPopupPanel.vue'
import VoixFieldHeader from '@voix/voix-layer/components/VoixFieldHeader.vue'

import type { PropType } from 'vue'
import type {
  FieldConfigInterface,
  FieldInterface,
  SliceInterface,
} from '@voix/types'

const props = defineProps({
  element: {
    type: Object as PropType<SliceInterface>,
    required: true,
  },

  fieldData: {
    type: Object as PropType<FieldInterface>,
    default: null,
  },

  fieldConfiguration: {
    type: Object as PropType<FieldConfigInterface>,
    required: true,
  },
})

const { localValue, enabled } = useField(props)
</script>

<template>
  <PropertiesPopupPanel class="min-w-[400px] text-xs text-gray-500 p-4">
    <VoixFieldHeader v-model="enabled" :field-configuration="props.fieldConfiguration" />

    <TextAreaInput
      id="element-permissions"
      v-model="localValue"
      :placeholder="props.fieldConfiguration.label"
    />
  </PropertiesPopupPanel>
</template>
