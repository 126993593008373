<script setup lang="ts">
import { useAdminStore } from '@voix/store/adminStore'
import { usePageStore } from '@voix/store/pageStore'
import { dragEnd, dragStart } from '@voix/composables/useDraggable'

import type { ElementInterface, QuerySliceInterface } from '@voix/types'

const props = defineProps({
  querySlice: {
    type: Object as () => QuerySliceInterface,
    required: true,
  },
  depth: {
    type: Number,
    required: true,
  },
})

const emit = defineEmits(['selectElement', 'multiSelectElement', 'context'])

const adminStore = useAdminStore()
const pageStore = usePageStore()

const toggleSliceGuiOpen = function () {
  if (props.querySlice.id)
    pageStore.toggleAdminGuiOpen(props.querySlice.id)
}

function contextMenuHandler(event: MouseEvent, item: ElementInterface) {
  emit('context', event, item)
}
</script>

<template>
  <div class="w-full outline-none">
    <div
      class="w-full flex items-center py-2 px-3 transition-colors duration-200 border-y cursor-pointer"
      :class="[
        {
          'hover:bg-gray-100 text-gray-400':
            !props.querySlice.administration?.gui?.open
            && !adminStore.selectedElements.includes(props.querySlice),
          'bg-gray-200 border-gray-300':
            props.querySlice.administration?.gui?.open
            && !adminStore.selectedElements.includes(props.querySlice),
          'voix-admin-bg-lightest hover:voix-admin-bg-lightest voix-admin-text':
            adminStore.selectedElements.includes(props.querySlice),
        },
        `pl-${depth * 3}`,
      ]"
      @click.left.exact.stop="$emit('selectElement', props.querySlice)"
      @click.shift.left.exact.stop="
        $emit('multiSelectElement', props.querySlice)
      "
      @click.left.exact="toggleSliceGuiOpen"
      @contextmenu.stop="contextMenuHandler($event, props.querySlice)"
    >
      <div class="flex flex-1">
        <button
          draggable="true"
          class="cursor-ns-resize"
          @dragstart="dragStart($event, `${props.querySlice.id}`)"
          @dragend="dragEnd"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-4 h-4 mr-2 flex-none"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"
            />
          </svg>
        </button>
        <div class="truncate text-xs tracking-tight">
          {{ props.querySlice.label }}
        </div>
      </div>
    </div>
  </div>
</template>
