<script setup lang="ts">
import { computed, inject, ref, watch } from 'vue'
import type { PropType } from 'vue'
import dayjs from 'dayjs'

import type { BreakpointInterface } from '@voix/types'
import TextInput from '@voix/components/chrome/controls/TextInput.vue'

import type {
  MediaFieldConfigInterface,
  MediaFieldPropertiesBreakpointInterface,
  MediaFieldPropertiesInterface,
} from './types'

import MediaEditor from './media-editor/MediaEditor.vue'

const props = defineProps({
  modelValue: {
    type: [Object, String] as PropType<Array<MediaFieldPropertiesInterface>>,
    required: true,
  },
  fieldConfiguration: {
    type: Object as PropType<MediaFieldConfigInterface>,
    required: true,
  },
  breakpointName: {
    type: String as PropType<string>,
    required: true,
  },
  breakpoint: {
    type: Object as PropType<BreakpointInterface>,
    required: true,
  },
})

const emit = defineEmits([
  'update:modelValue',
  'clear',
  'requestMediaManager',
  'titleUpdated',
])

const localValue = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  },
})

const breakpointValue = computed(() => {
  if (
    localValue.value
    && typeof localValue.value === 'object'
    && localValue.value.length > 0
  ) {
    const foundValue = localValue.value.find(
      (breakpoint: MediaFieldPropertiesBreakpointInterface) => breakpoint.breakpoint === props.breakpointName,
    )
    if (foundValue)
      return foundValue
  }
  return null
})

watch(
  () => breakpointValue.value?.title ? breakpointValue.value.title : '',
  (newValue, oldValue) => {
    if (oldValue !== newValue && newValue !== '')
      emit('titleUpdated', newValue, oldValue)
  },
  { deep: true },
)

function downloadMedia() {
  // TODO: Download media
  // eslint-disable-next-line no-console
  console.log('download image')
}

const clearMedia = inject('clearMedia')

const showTools = ref(false)

const createdAt = computed(() => {
  return breakpointValue.value.created_at
    ? dayjs(breakpointValue.value.created_at).format('MMMM D, YYYY')
    : 'Unknown'
})

const breakpointMeta = computed(() => {
  return `
  <dl class="grid grid-cols-2 text-xs text-gray-400 opacity-70 gap-2">
    <dt>File Type</dt>
    <dd class="text-right text-gray-50">
      ${breakpointValue.value?.mime_type || 'Unknown'}
    </dd>
    <dt>Uploaded by</dt>
    <dd class="text-right text-gray-50">
      ${breakpointValue.value.uploaded_by ? breakpointValue.value.uploaded_by : 'Unknown'}
    </dd>
    <dt>Created</dt>
    <dd class="text-right text-gray-50">
      ${createdAt.value}
    </dd>
    <template v-if="breakpointValue.value.width && breakpointValue.value.height">
      <dt>Original Dimensions</dt>
      <dd class="text-right text-gray-50">
        ${breakpointValue.value.width} x ${breakpointValue.value.height}
      </dd>
    </template>
  </dl>
  `
})

const isDuplicatingMedia = ref('')
const duplicateMedia = inject('duplicateMedia')

function showDuplicateMediaModal(fromBreakpoint: string) {
  if (isDuplicatingMedia.value === fromBreakpoint)
    isDuplicatingMedia.value = ''
  else
    isDuplicatingMedia.value = fromBreakpoint
}

function requestDuplicateMedia(toBreakpoint: string) {
  if (isDuplicatingMedia.value === '')
    return

  duplicateMedia(isDuplicatingMedia.value, toBreakpoint)
  isDuplicatingMedia.value = ''
}
</script>

<template>
  <div>
    <div v-if="breakpointValue" class="mt-3 voix-admin-bg-darkest voix-admin-text rounded overflow-hidden">
      <div class="relative bg-gray-700 h-40 w-full flex justify-center items-center voix-admin-text-lightest">
        <VoixMedia :field="{ value: breakpointValue, config: props.fieldConfiguration }" :breakpoint="breakpointName" class="object-contain w-full h-full " />

        <div class="absolute bottom-0 right-0 m-2 flex space-x-2">
          <button class="bg-gray-900 hover:voix-admin-bg-dark hover:voix-admin-text-lightest duration-300 p-1 rounded " @click="showTools = !showTools">
            <Icon name="heroicons:cog-6-tooth-solid" class="w-4 h-4" />
          </button>
          <!-- <button class="bg-gray-900 hover:voix-admin-bg-dark hover:voix-admin-text-lightest duration-300 p-1 rounded " @click="downloadMedia">
            <Icon name="heroicons:arrow-down-circle-20-solid" class="w-4 h-4" />
          </button> -->
          <div class="relative">
            <button class="relative bg-gray-900 hover:voix-admin-bg-dark hover:voix-admin-text-lightest duration-300 p-1 rounded " @click="showDuplicateMediaModal(breakpointName)">
              <Icon name="heroicons:document-duplicate-solid" class="w-4 h-4" />
            </button>
            <ul v-if="breakpoint.breakpoint === isDuplicatingMedia" class="absolute bottom-0 right-0 bg-gray-100 rounded overflow-hidden flex flex-col divide-gray-400 divide-y -translate-y-8 min-w-[100px]">
              <li v-for="(bp, bpName) in fieldConfiguration.breakpoints">
                <button class="px-2 py-1.5 voix-admin-text text-left pr-2 hover:bg-gray-200 w-full" @click="requestDuplicateMedia(bpName)">
                  {{ bpName }}
                </button>
              </li>
            </ul>
          </div>
          <button v-voix-popper:#app.top.left="breakpointMeta" class="bg-gray-900 hover:voix-admin-bg-dark hover:voix-admin-text-lightest duration-300 p-1 rounded">
            <Icon name="heroicons:information-circle-20-solid" class="w-4 h-4" />
          </button>
          <button class="bg-gray-900 hover:voix-admin-bg-dark hover:voix-admin-text-lightest duration-300 p-1 rounded " @click="clearMedia(breakpointName)">
            <Icon name="heroicons:archive-box-x-mark-solid" class="w-4 h-4" />
          </button>
        </div>
      </div>
      <div class="p-2 pt-2.5 px-4 text-xs font-medium voix-admin-bg-light rounded-b">
        {{
          breakpointName !== 'default'
            ? `Media for breakpoint "${breakpointName}" and up`
            : `Main Selected Media`
        }}
      </div>
    </div>

    <div
      v-else
      class="mt-1 px-4 py-2 border-2 border-dashed border-gray-300 bg-border-100 text-2xs text-gray-400 rounded-lg font-bold uppercase"
    >
      Nothing yet selected
    </div>

    <div class="pt-5">
      <div class="relative">
        <TextInput
          v-if="localValue"
          v-show="showTools"
          :id="`${breakpoint}-alt-tag`"
          v-model="breakpointValue.title"
          placeholder="&quot;Describing what this media is&quot;"
          label="Alt Tag / Aria Title"
        />

        <button class="absolute right-0 bottom-0 px-2 py-2 mb-0.5 mr-0.5 bg-gray-100" @click="breakpointValue.title = ''">
          Clear / Desync
        </button>
      </div>

      <MediaEditor
        v-if="localValue"
        v-show="showTools"
        :breakpoint="breakpointValue"
      />
    </div>
  </div>
</template>
