<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue'
import type { ComputedRef, Ref } from 'vue'

import { useAdminStore } from '@voix/store/adminStore'
import { usePageStore } from '@voix/store/pageStore'
import { useRoute } from 'vue-router'

import TextInput from '@voix/components/chrome/controls/TextInput.vue'
import DropdownSelect from '@voix/components/chrome/controls/DropdownSelect.vue'
import PropertiesPopupPanel from '@voix/components/chrome/PropertiesPopupPanel.vue'
import DataModelSelector from '@voix/components/chrome/controls/DataModelSelector.vue'

import GroupProperties from '@voix/components/chrome/property-panels/GroupProperties.vue'
import QueryProperties from '@voix/components/chrome/property-panels/QueryProperties.vue'
import SliceProperties from '@voix/components/chrome/property-panels/SliceProperties.vue'

import type { ElementInterface } from '@voix/types'
import SectionLabel from './SectionLabel.vue'
import GlobalField from './fields/GlobalField.vue'
import FieldPropertyEditors from './property-panels/FieldPropertyEditors.vue'
import PanelSection from './PanelSection.vue'

import { useState } from '#imports'

export default defineComponent({
  components: {
    TextInput,
    DropdownSelect,
    PanelSection,
    FieldPropertyEditors,
    GroupProperties,
    QueryProperties,
    SliceProperties,
    GlobalField,
    PropertiesPopupPanel,
    DataModelSelector,
    SectionLabel,
  },
  setup() {
    const adminStore = useAdminStore()
    const pageStore = usePageStore()
    const route = useRoute()

    const element: ComputedRef<ElementInterface | null> = computed(() => {
      if (adminStore.selectedElements.length === 1) {
        // Pointer to the element in the pageStore when user clicked on the left pane.
        return adminStore.selectedElements[0] as ElementInterface
      }

      return null
    })

    const requestClosePanel = () => {
      adminStore.unsetSelectedElement()
    }

    const unselectField = () => {
      adminStore.unselectField()
    }

    const showModelData = ref(false)
    const applyDataModelSelection = (modelQuery: string) => {
      if (element.value) {
        pageStore.updateSliceModelQuery(element.value, modelQuery)

        showModelData.value = false
      }
    }

    const cancelDataModelSelection = () => {
      showModelData.value = false
    }

    const findElement = () => {
      if (element.value)
        adminStore.scrollToElement = element.value
    }

    const duplicateElement = () => {
      if (element.value)
        pageStore.duplicateElement(element.value.id)
    }

    const deleteElement = () => {
      if (element.value) {
        pageStore.deleteElement(element.value.id)
        requestClosePanel()
      }
    }

    const elementDiagnostics = computed(() => {
      let diagnostics = ''
      if (element.value) {
        diagnostics = `
        <div class="grid grid-cols-2">
          <span>Component: </span><span class="font-voix-mono">${element.value.component}</span>
          <span>Type: </span><span class="font-voix-mono">${element.value.type}</span>
          <span>ID: </span><span class="font-voix-mono">${element.value.id}</span>
        </div>
        `
      }

      return diagnostics
    })

    const loadingModeHelp = computed(() => {
      const helpData = [
        {
          name: 'Load',
          rating: 'Poor',
          ratingClass: 'text-red-300',
          description: 'Load will load the component server side and then rehydrate the component on the client-side as soon as it can.',
          uses: [
            'Interactive slices (Javascript) that need to be ready right away',
            'Pages with less resources being used',
          ],
        },
        {
          name: 'Idle  (Default)',
          rating: 'Medium',
          ratingClass: 'text-orange-300',
          description: 'Load will load the component server side and then rehydrate the component on the client-side once everything has been downloaded.',
          uses: [
            'Interactive slices (Javascript) that can wait 1 - 2 seconds before they are ready to be interactive',
          ],
        },
        {
          name: 'Visible',
          rating: 'High',
          ratingClass: 'text-green-600',
          description: 'Will hydrate the component when it is visible on the screen.',
          uses: [
            'Interactive slices (Javascript) that can wait to be interactive until the user scrolls to it.',
            'Still visible by crawlers',
          ],
        },
        {
          name: 'SSR Only',
          rating: 'High',
          ratingClass: 'text-green-600',
          description: 'The slice will only be built on the serverside and isnt interactive on the client side.',
          uses: [
            'A non-interactive slice that is only used to display data, links, content, or other non-interactive elements.',
          ],
        },
        {
          name: 'Client Only',
          rating: 'Low',
          ratingClass: 'text-red-300',
          description: 'The slice is only viewable on the client side and is not rendered on the server side.',
          uses: [
            'Requires a browser to render the slice.',
            'Requires a user session to render the slice.',
            'Crawling robots may not be able to see this content.',
          ],
        },
      ]

      let help = `
        <div class="grid grid-cols-12 gap-y-8 max-w-[400px]">
          <div class="col-span-2 font-bold text-stone-400">Mode</div>
          <div class="col-span-5 font-bold text-stone-400 pl-4">Description</div>
          <div class="col-span-5 font-bold text-stone-400 pl-4">Use Cases</div>
  
        `

      helpData.map((hd) => {
        help += `
                <div class="col-span-2 font-bold voix-admin-text-lightest">
                  <div>${hd.name}</div>
                  <div class="${hd.ratingClass}">${hd.rating}</div>
                </div>
                <div class="col-span-5 pl-4">${hd.description}</div>
                <div class="col-span-5 ">
                  <ul class="list-disc pl-4">`
        hd.uses.map((use) => {
          help += `<li>${use}</li>`
          return use
        })
        help += ` </ul>
                </div>
              `
        return hd
      })
      help += '</div>'
      return help
    })

    // Debug panel
    // TODO: This is a temporary solution to show the debug panel. We may want to add this to the raft at some point
    // but my thought was that maybe we could have it on the frontend too? idk.
    const voixDebug: Ref<boolean | ElementInterface> = useState('voixDebug', () => false)
    const debug = ref(false)
    watch(debug, (newValue) => {
      if (newValue && element.value)
        voixDebug.value = element.value
      else
        voixDebug.value = false
    })

    watch(voixDebug, (newValue) => {
      debug.value = !!newValue
    })

    return {
      adminStore,
      pageStore,
      route,
      element,
      requestClosePanel,
      unselectField,
      showModelData,
      applyDataModelSelection,
      cancelDataModelSelection,
      findElement,
      duplicateElement,
      deleteElement,
      debug,
      elementDiagnostics,
      loadingModeHelp,
    }
  },
})
</script>

<template>
  <div
    v-if="
      adminStore.selectedElements.length === 1 || adminStore.selectedField.label
    "
    class="fixed z-40 right-0 top-0 bottom-0 flex flex-col justify-between w-[330px] bg-gray-100 shadow-2xl overflow-y-auto"
  >
    <div>
      <!-- element editor which will show multiple fields and element properties -->
      <div
        v-if="adminStore.selectedElements.length === 1 && element"
        class="flex flex-col p-2 px-6"
      >
        <div
          class="voix-admin-bg voix-admin-text-lightest -mt-2 -mx-6 mb-4 pt-4 pb-4 px-6 text-xs uppercase font-medium"
        >
          <div class="flex items-center justify-between">
            <div class="text-sm font-bold tracking-wide">
              {{ element.type }}: {{ element.label }}
            </div>
            <button
              class="voix-admin-bg-darkest voix-admin-text-lightest hover:voix-admin-text-lightest hover:voix-admin-text p-1 rounded-sm"
              @click="requestClosePanel"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
        <PanelSection
          v-if="element"
          id="general-element-properties"
          name="General Element Properties"
          :start-open="true"
        >
          <div class="flex space-x-4">
            <div class="w-1/2 flex items-end">
              <DropdownSelect
                id="hydration-mode"
                v-model="element.hydration_mode"
                label="Loading Mode"
                :options="[
                  {
                    label: 'Load',
                    value: 'load',
                    key: 'load',
                  },
                  {
                    label: 'Idle',
                    value: 'idle',
                    key: 'idle',
                  },
                  {
                    label: 'Visible',
                    value: 'visible',
                    key: 'visible',
                  },
                  {
                    label: 'SSR Only',
                    value: 'none',
                    key: 'none',
                  },
                  {
                    label: 'Client Only',
                    value: 'clientOnly',
                    key: 'clientOnly',
                  },
                ]"
                placeholder="Select a Loading Mode"
                class="flex-1"
              />
              <button
                v-voix-popper:bottom.right="loadingModeHelp"
                class="voix-admin-text pb-1.5 pl-1"
              >
                <Icon name="heroicons:information-circle" class="w-4 h-4 -mt-1" />
              </button>
            </div>
            <div class="w-1/2">
              <TextInput
                id="element-id"
                v-model="element.properties.id"
                placeholder="Element ID"
                label="DOM Id"
              >
                <svg
                  class="w-4 h-4"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M7 20l4-16m2 16l4-16M6 9h14M4 15h14"
                  />
                </svg>
              </TextInput>
            </div>
          </div>
          <div class="mt-2">
            <TextInput
              id="element-name"
              v-model="element.label"
              placeholder="Provide a Name"
              label="Name"
            />
          </div>
        </PanelSection>

        <FieldPropertyEditors
          v-if="element.type === 'slice'"
          :element="element"
        />

        <div v-if="!adminStore.selectedField.name">
          <SectionLabel>Slice Settings</SectionLabel>
          <div class="mt-4">
            <GroupProperties
              v-if="element.type === 'group' && !adminStore.selectedFieldData"
              v-model:group-settings="element.properties.settings"
              :element-id="element.id"
            />
            <SliceProperties
              v-if="element.type === 'slice' && !adminStore.selectedFieldData"
              v-model:slice-settings="element.properties.settings"
              :element-id="element.id"
            />
            <QueryProperties
              v-if="element.type === 'query' && !adminStore.selectedFieldData"
              v-model:query-settings="element.properties.settings"
            />
          </div>
          <div class="mt-6">
            <SectionLabel> Data Models </SectionLabel>
            <button
              class="mt-2 p-2.5 px-2 rounded flex items-center text-xs w-full"
              :class="{
                'bg-gray-200 text-gray-500': !showModelData,
                'voix-admin-bg-lightest voix-admin-text': showModelData,
              }"
              @click="showModelData = !showModelData"
            >
              {{
                element.type === 'query'
                  ? 'Manage Data Model'
                  : 'Enable Data Model'
              }}
            </button>
          </div>
        </div>
        <PropertiesPopupPanel
          v-if="
            showModelData
              && (element.type === 'slice' || element.type === 'query')
          "
          class="min-w-[400px] max-w-[600px] text-xs text-gray-500"
        >
          <DataModelSelector
            :slice="element"
            @apply-selection="applyDataModelSelection"
            @cancel-selection="cancelDataModelSelection"
          />
        </PropertiesPopupPanel>
      </div>
      <!-- Field editor when a single field has been selected -->
      <div v-if="adminStore.selectedField" class="flex flex-col space-y-8 p-4">
        <button
          v-if="adminStore.selectedFieldData"
          class="flex items-center space-x-2 text-2xs uppercase font-bold voix-admin-text bg-gray-200 py-1.5 px-4 rounded-l-lg rounded-r-lg overflow-hidden hover:-translate-x-1 transition-transform duration-200 ease-in-out"
          @click="adminStore.unselectField"
        >
          <div
            class="-my-2 -ml-4 py-2.5 px-2 border-r border-gray-300 voix-admin-text"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-4 h-4"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
              />
            </svg>
          </div>
          <span>Back to Slice Settings</span>
        </button>

        <GlobalField
          :element="adminStore.selectedFieldSlice"
          :field-data="adminStore.selectedFieldData"
          :field-configuration="adminStore.selectedField"
        />
      </div>
    </div>

    <div class="bg-gray-200 flex justify-between px-4 py-3 border-t border-white">
      <div class="flex space-x-1.5">
        <button
          class="bg-gray-300 text-gray-600 hover:voix-admin-text-lightest hover:voix-admin-text p-1 px-2 rounded-sm"
          @click="findElement"
        >
          <svg
            class="w-4 h-4"
            fill="none"
            currentColor="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="12"
              cy="12"
              r="2"
              stroke="currentColor"
              stroke-width="2"
            />
            <circle
              cx="12"
              cy="12"
              r="6"
              stroke="currentColor"
              stroke-width="2"
            />
            <circle
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="2"
            />
          </svg>
        </button>
        <button
          class="bg-gray-300 text-gray-600 hover:voix-admin-text-lightest hover:voix-admin-text p-1 px-2 rounded-sm"
          @click="duplicateElement"
        >
          <Icon name="heroicons:document-duplicate" class="w-4 h-4 -mt-1" />
        </button>
        <button
          v-voix-popper:#app.top.left="elementDiagnostics"
          class="bg-gray-300 text-gray-600 hover:voix-admin-text-lightest hover:voix-admin-text p-1 px-2 rounded-sm"
        >
          <Icon name="heroicons:information-circle" class="w-4 h-4 -mt-1" />
        </button>
      </div>
      <div>
        <button
          class="bg-gray-300 text-gray-600 hover:voix-admin-text-lightest hover:voix-admin-text p-1 px-2 rounded-sm"
          data-testid="voix-delete-element"
          @click="deleteElement"
        >
          <Icon name="heroicons:trash" class="w-4 h-4 -mt-1" />
        </button>
      </div>
    </div>
  </div>
</template>
