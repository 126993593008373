<script setup lang="ts">
import AdministrationChrome from '@voix/voix-layer/components/administration/AdministrationChrome.vue'
import AdminButton from '@voix/voix-layer/components/administration/AdministrationButton.vue'
import AdminAlert from '@voix/voix-layer/components/administration/AdministrationAlert.vue'
import DropdownSelect from '@voix/components/chrome/controls/DropdownSelect.vue'
import FormSection from '@voix/voix-layer/components/administration/AdministrationFormSection.vue'
import FormField from '@voix/voix-layer/components/administration/AdministrationFormField.vue'
import SubmissionGroup from '@voix/voix-layer/components/administration/AdministrationSubmissionGroup.vue'

import { createHeadTag } from '@voix/composables/queries/useQueryHeadTags'
import { useHeadTagUtils } from '@voix/composables/useHeadTagUtils'
import { computed, ref, watchEffect } from 'vue'

const props = defineProps({
  pageId: {
    type: String,
    required: true,
  },
})

const emit = defineEmits(['back'])

const { availableTags } = useHeadTagUtils()

const headTag = ref({
  type: '',
  content: '',
  is_partytown: false,
  position: 'head',
})

const selectedType = computed(() => {
  return availableTags.find(tag => tag.key === headTag.value.type)
})

watchEffect(() => {
  if (headTag.value.type === 'script' || headTag.value.type === 'externalScript')
    headTag.value.is_partytown = true
})

function requestSave() {
  createHeadTag({
    page_id: props.pageId,
    type: headTag.value.type,
    content: headTag.value.content,
    is_partytown: headTag.value.is_partytown,
    position: headTag.value.position,
  }).then(() => {
    emit('back')
  })
}

function requestCancel() {
  emit('back')
}
</script>

<template>
  <AdministrationChrome
    title="New Head Tag"
    :links="[
      {
        label: 'Back to All Head Tags',
        emit: 'back',
      },
    ]"
    @back="requestCancel"
  >
    <FormSection
      title="Head Tag Settings"
    >
      <FormField label="Tag Type">
        <select
          v-model="headTag.type"
          class="w-full border border-gray-300 rounded-md px-3 py-2"
        >
          <option v-for="tag in availableTags" :key="tag.key" :value="tag.key">
            {{ tag.label }}
          </option>
        </select>

        <AdminAlert v-if="selectedType" class="mt-4">
          {{ selectedType.description }}
        </AdminAlert>
      </FormField>

      <FormField label="Content">
        <textarea
          v-model="headTag.content"
          type="text"
          class="w-full min-h-[200px] border border-gray-300 rounded-md px-3 py-2"
        />
      </FormField>

      <FormField label="Tag Position">
        <DropdownSelect
          id="tag-position"
          v-model="headTag.position"
          :options="[
            { label: 'Head', value: 'head', key: 'head' },
            { label: 'Body Open', value: 'bodyOpen', key: 'bodyOpen' },
            { label: 'Body Close', value: 'bodyClose', key: 'bodyClose' },
          ]"
        />
      </FormField>
    </FormSection>

    <SubmissionGroup>
      <AdminButton type="primary" @click="requestSave">
        Save
      </AdminButton>
      <AdminButton type="secondary" @click="requestCancel">
        Cancel
      </AdminButton>
    </SubmissionGroup>
  </AdministrationChrome>
</template>
