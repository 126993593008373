import { useAdminStore } from '@voix/store/adminStore'
import type { ElementInterface, FoundElement, GroupInterface, PageInterface, SliceInterface } from '@voix/types'
import { getCurrentPage } from '@voix/composables/queries/useQueryCurrentPage'
import { useSliceStore } from '@voix/store/sliceStore'
import { usePageStore } from '../store/pageStore'
import { useReleases } from './useReleases'
import { updateCurrentPage } from './queries/useQueryCurrentPage'

import { navigateTo, useCookie } from '#imports'

// Because each element has a divider at the bottom we need
// a way to inject a slice to the very top of the page elements array
function moveElementTopOfPage(elementId: string) {
  const pageStore = usePageStore()
  const currentPage = pageStore.currentPage as PageInterface

  // Get the element to move
  const foundElement = pageStore.findElement(elementId)

  // If found, move it to the top of the page
  if (foundElement && currentPage) {
    const { extractionElements, extractionIndex }
      = getExtractionDetails(foundElement)

    //  If null it's the page itself (root elements list)
    if (extractionElements !== null && extractionIndex !== null) {
      const splicedElement = extractionElements.splice(extractionIndex, 1)

      currentPage.elements.splice(0, 0, splicedElement[0])
    }
  }
}

// When a user drags anything into a group this extracts and injects
// the element into the start of the group.
function moveElementTopOfGroup(elementId: string, group: GroupInterface) {
  const pageStore = usePageStore()
  const currentPage = pageStore.currentPage as PageInterface
  const foundElement = pageStore.findElement(elementId)

  if (foundElement && currentPage) {
    const { extractionElements, extractionIndex }
      = getExtractionDetails(foundElement)

    const nestingCheckFailed = isElementNestingInsideItself(
      foundElement.element,
      group,
    )

    //  If null it's the page itself (root elements list)
    if (
      extractionElements !== null
      && extractionIndex !== null
      && !nestingCheckFailed
    ) {
      const splicedElement = extractionElements.splice(extractionIndex, 1)

      group.elements.splice(0, 0, splicedElement[0])
    }
  }
}

// When a user drags anything into a slice this extracts and injects
// the element into the start of the slice.
function moveElementTopOfSlice(elementId: string, slice: SliceInterface) {
  const pageStore = usePageStore()
  const sliceStore = useSliceStore()
  const currentPage = pageStore.currentPage as PageInterface
  const foundElement = pageStore.findElement(elementId)
  const sliceConfig = sliceStore.getSlice(slice.component)

  if (sliceConfig?.slots && sliceConfig?.slots.length) {
    if (foundElement && currentPage) {
      const { extractionElements, extractionIndex }
        = getExtractionDetails(foundElement)

      const nestingCheckFailed = isElementNestingInsideItself(
        foundElement.element,
        slice,
      )

      //  If null it's the page itself (root elements list)
      if (
        extractionElements !== null
        && extractionIndex !== null
        && !nestingCheckFailed
      ) {
        const splicedElement = extractionElements.splice(extractionIndex, 1)

        if (!slice.elements)
          slice.elements = []

        slice.elements.splice(0, 0, splicedElement[0])
      }
    }
  }
}

// Below each element in the PageNavigationElements component is a hit
// area that is used to drag the element below other elements.
function moveElementBelowElement(elementId: number, elementAbove: ElementInterface) {
  const pageStore = usePageStore()

  const foundElement = pageStore.findElement(elementId)
  const foundElementAbove = pageStore.findElement(elementAbove.id)
  const currentPage = pageStore.currentPage as PageInterface

  if (foundElement && foundElementAbove && currentPage) {
    // EXTRACTION OF THE ELEMENT WE ARE MOVING
    // Means that we are going to be extracting from the root elements array
    const { extractionElements, extractionIndex }
      = getExtractionDetails(foundElement)

    // INJECTION OF ELEMENT WE ARE MOVING
    // Only if we found the guy we gotta move which we always should
    if (extractionElements !== null && extractionIndex !== null) {
      // Means that we are going to be injecting into the page's root elements array
      if (foundElementAbove.parentElement === null) {
        const injectionElements = currentPage.elements
        const injectionIndex = injectionElements.indexOf(
          foundElementAbove.element,
        )

        // Splice the element from the parent element
        const splicedElement = extractionElements.splice(extractionIndex, 1)

        // Inject the element into the new parent element. If we are moving it below it's previous position we need to not add 1 to the index
        const finalInjectionIndex
          = injectionIndex <= extractionIndex
            ? injectionIndex + 1
            : injectionIndex
        injectionElements.splice(finalInjectionIndex, 0, splicedElement[0])
      }

      // Means that we are going to be injecting an element's element property array
      if (
        foundElementAbove.parentElement
        && foundElementAbove.parentElement.elements
      ) {
        const injectionElements = foundElementAbove.parentElement.elements
        const injectionIndex = injectionElements.indexOf(
          foundElementAbove.element,
        )
        const nestingCheckFailed = isElementNestingInsideItself(
          foundElement.element,
          foundElementAbove.parentElement,
        )

        if (!nestingCheckFailed) {
          // Splice the element from the parent element
          const splicedElement = extractionElements.splice(extractionIndex, 1)

          // Inject the element into the new parent element
          injectionElements.splice(injectionIndex, 0, splicedElement[0])
        }
      }
    }
  }
}

function getExtractionDetails(foundElement: FoundElement) {
  const pageStore = usePageStore()
  const currentPage = pageStore.currentPage as PageInterface

  // If the element we want to extract is a parent, then the extraction elements are from the current page
  // and the extraction index is the index of the element in the current page elements
  if (foundElement.parentElement === null) {
    const extractionElements = currentPage.elements
    const extractionIndex = extractionElements.indexOf(foundElement.element)
    return { extractionElements, extractionIndex }
  }

  // If the element we want to extract is a child, then the extraction elements are from the parent element
  // and the extraction index is the index of the element in the parent elements
  if (foundElement.parentElement && foundElement.parentElement.elements) {
    const extractionElements = foundElement.parentElement.elements
    const extractionIndex = extractionElements.indexOf(foundElement.element)
    return { extractionElements, extractionIndex }
  }

  // If the element we want to extract is not a parent or child, then we return null
  return { extractionElements: null, extractionIndex: null }
}

function isElementNestingInsideItself(element: ElementInterface, attemptedParent: ElementInterface) {
  // If the element is trying to nest inside itself, we need to prevent it
  if (element.id === attemptedParent.id)
    return true

  return false
}

function alterElementTypes(pageData: PageInterface) {
  pageData.elements.map((element: ElementInterface) => {
    if (Object.prototype.hasOwnProperty.call(element, 'model_query') && element.model_query !== null)
      element.type = 'query'
    return element
  })
  return pageData
}

async function requestSave(save: any) {
  const pageStore = usePageStore()

  try {
    let pageData = JSON.parse(JSON.stringify(pageStore.currentPage))

    pageData = alterElementTypes(pageData)

    const { setReleaseData } = useReleases()
    const adminStore = useAdminStore()
    let releaseId = adminStore.releaseId

    // this should probably happen in the setReleaseData function
    // if the releaseid that's being passed does not belong to the current page being saved clear it
    if (releaseId && releaseId !== pageData.current_release.id && releaseId !== pageData.draft_release?.id)
      releaseId = ''

    // if we're saving to a new release then the released id needs to be cleared
    if (releaseId && save.saveMode.value === 'queue-new-release')
      releaseId = ''

    const payload = setReleaseData(save, {
      id: pageData.id,
      title: pageData.title,
      path: pageData.path,
      layout: pageData.layout,
      elements: pageData.elements,
      release_date: '',
      release_label: '',
      release_id: releaseId || '',
    })

    await updateCurrentPage(payload)

    // If the path changed redirect
    navigateTo({
      name: 'voix-studio',
      query: {
        editing: pageData.path,
      },
    })

    // If the page is a draft, unset the release id
    if (pageStore.currentPage?.draft_release?.id || save.saveMode.value === 'publish')
      adminStore.unsetReleaseId()

    if (releaseId && save.saveMode.value === 'queue-existing-release') {
      // queued to a release. lets switch to that release
      const releasePreviewCookie = useCookie('voix-release-preview')
      releasePreviewCookie.value = save.releaseId.value
      adminStore.setReleaseId(save.releaseId.value)
    }

    adminStore.unselectElements()

    save.endSave(save.close)
  }
  catch (error) {
    console.error('Error saving page', { error })
    save.endSave(save.close)
  }
}

async function requestSaveDraft(save: any) {
  const pageStore = usePageStore()

  try {
    let pageData = JSON.parse(JSON.stringify(pageStore.currentPage))

    pageData = alterElementTypes(pageData)

    const payload = Object.assign({}, pageData)

    const { data } = await updateCurrentPage(payload)
    // If the path changed redirect
    navigateTo({
      name: 'voix-studio',
      query: {
        editing: pageData.path,
      },
    })

    const adminStore = useAdminStore()
    const releaseId = data.value.draft_release.id
    if (releaseId) {
      const releasePreviewCookie = useCookie('voix-release-preview')
      releasePreviewCookie.value = releaseId

      adminStore.setReleaseId(releaseId)
    }
    else {
      adminStore.unsetReleaseId()
    }

    adminStore.unselectElements()

    save.endSave(save.close)
  }
  catch (error) {
    console.error('Error saving page', { error })
    save.endSave(save.close)
  }
}

export {
  moveElementTopOfPage,
  moveElementTopOfGroup,
  moveElementBelowElement,
  moveElementTopOfSlice,
  requestSave,
  requestSaveDraft,
}
