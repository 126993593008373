<script setup lang="ts">
import { computed } from 'vue'
import { useField } from '@voix/composables/useField'

import DropdownSelect from '@voix/components/chrome/controls/DropdownSelect.vue'
import PropertiesPopupPanel from '@voix/components/chrome/PropertiesPopupPanel.vue'
import VoixFieldHeader from '@voix/voix-layer/components/VoixFieldHeader.vue'

import type { PropType } from 'vue'
import type {
  FieldInterface,
  SliceInterface,
} from '@voix/types'

import type {
  VoixSelectFieldConfigurationInterface,
} from './types'

const props = defineProps({
  element: {
    type: Object as PropType<SliceInterface>,
    required: true,
  },

  fieldData: {
    type: Object as PropType<FieldInterface>,
    default: null,
  },

  fieldConfiguration: {
    type: Object as PropType<VoixSelectFieldConfigurationInterface>,
    required: true,
  },
})

const options = computed(() => {
  const options: Array<{ label: string; value: any; key: string }> = []
  Object.keys(props.fieldConfiguration.options).forEach((key: any) => {
    options.push({
      label: props.fieldConfiguration.options[key],
      value: key,
      key,
    })
  })
  return options
})

const { localValue } = useField(props)
</script>

<template>
  <PropertiesPopupPanel class="min-w-[400px] text-xs text-gray-500 p-4">
    <VoixFieldHeader v-model="enabled" :field-configuration="props.fieldConfiguration" />

    <DropdownSelect
      id="element-permissions"
      v-model="localValue"
      :options="options"
      :placeholder="props.fieldConfiguration.label"
    />
  </PropertiesPopupPanel>
</template>
