<script lang="ts">
import { defineComponent, ref } from 'vue'

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    startOpen: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const open = ref(true)

    const toggleOpen = () => {
      // TODO: Need to cookie this state against the id of the section
      open.value = !open.value
    }

    open.value = props.startOpen

    return { props, open, toggleOpen }
  },
})
</script>

<template>
  <div>
    <button
      class="w-full flex justify-between items-center text-2xs tracking-wider font-semibold uppercase text-gray-400 border-b border-gray-300 pb-1"
      @click="toggleOpen"
    >
      <div class="truncate">
        {{ props.name }}
      </div>

      <svg
        class="w-3 h-3 transform duration-150 mr-2 text-gray-400" :class="{
          'rotate-180': !open,
        }" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
      >
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M19 9l-7 7-7-7" />
      </svg>
    </button>

    <div
      class="mb-3 overflow-hidden transition-all duration-200" :class="{
        'max-h-[5000px]': open,
        'max-h-0': !open,
      }"
    >
      <div class="flex flex-col py-4">
        <slot />
      </div>
    </div>
  </div>
</template>

<style scoped>
</style>
