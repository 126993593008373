<script setup lang="ts">
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'

import { useField } from '@voix/composables/useField'
import { computed } from 'vue'

import TextInput from '@voix/components/chrome/controls/TextInput.vue'
import PropertiesPopupPanel from '@voix/components/chrome/PropertiesPopupPanel.vue'
import VoixFieldHeader from '@voix/voix-layer/components/VoixFieldHeader.vue'

import type { PropType } from 'vue'
import type {
  FieldConfigInterface,
  FieldInterface,
  SliceInterface,
} from '@voix/types'

const props = defineProps({
  element: {
    type: Object as PropType<SliceInterface>,
    required: true,
  },

  fieldData: {
    type: Object as PropType<FieldInterface>,
    default: null,
  },

  fieldConfiguration: {
    type: Object as PropType<FieldConfigInterface>,
    required: true,
  },
})

dayjs.extend(advancedFormat)

const type = computed(() => {
  if (props.fieldConfiguration.date && !props.fieldConfiguration.time)
    return 'date'

  if (!props.fieldConfiguration.date && props.fieldConfiguration.time)
    return 'time'

  return 'datetime-local'
})

const minFormatted = computed(() => {
  const min = props.fieldConfiguration.min
  if (min)
    return dayjs(min).format('YYYY-MM-DD')

  return ''
})

const maxFormatted = computed(() => {
  const max = props.fieldConfiguration.max
  if (max)
    return dayjs(max).format('YYYY-MM-DD')

  return ''
})

const { localValue, enabled } = useField(props)

const localValueData = computed({
  get() {
    if (localValue.value)
      return dayjs(localValue.value).format('YYYY-MM-DD')

    return {}
  },
  set(value) {
    if (props.fieldConfiguration.format)
      value = dayjs(value).format(props.fieldConfiguration.format)

    localValue.value = value
  },
})
</script>

<template>
  <PropertiesPopupPanel class="min-w-[400px] text-xs text-gray-500 p-4">
    <VoixFieldHeader v-model="enabled" :field-configuration="props.fieldConfiguration" />

    <TextInput
      id="datetime-input"
      v-model="localValueData"
      :type="type"
      :placeholder="props.fieldConfiguration.label"
      :min="minFormatted"
      :max="maxFormatted"
      :step="props.fieldConfiguration.step"
    />
  </PropertiesPopupPanel>
</template>
