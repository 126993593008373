<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { useUserStore } from '@voix/store/userStore'
import type { Ref } from 'vue'
import { inject, ref } from 'vue'

const breakpointNames = {
  'default': 'Smallest',
  'sm': 'Mobile',
  'md': 'Mobile/Tablet',
  'lg': 'Tablet/Desktop',
  'xl': 'Large Desktop',
  '2xl': 'XL Desktop',
}

const fieldConfiguration = inject('fieldConfiguration', null)
const selectMedia = inject<Function>('selectMedia', () => {})
const uploadedFile: Ref<string | null> = ref(null)

function onMediaSelect(breakpointName: string) {
  if (uploadedFile.value === null)
    return

  const userStore = useUserStore()
  const currentUser = userStore.currentUser || { name: 'Unknown' }
  const parsedUrl = new URL(uploadedFile.value)

  selectMedia(breakpointName, {
    final_url: parsedUrl.pathname + parsedUrl.search + parsedUrl.hash,
    thumb: parsedUrl.pathname + parsedUrl.search + parsedUrl.hash,
    type: 'image',
    created_at: new Date().toISOString(),
    uploaded_by: currentUser.name,
  })
}

function onFileUpload(response: any) {
  uploadedFile.value = response.data.value.url
}
</script>

<template>
  <div>
    <VoixFileSelector :multiple="false" @file-upload="onFileUpload" />
    <div v-if="uploadedFile" class="mt-8 py-4 px-8 flex space-x-4 voix-admin-bg-light voix-admin-text text-sm">
      <div class="flex-1">
        <div class="font-bold">
          File Uploaded
        </div>
        <div>
          <a :href="uploadedFile" target="_blank">
            <Icon name="heroicons:link-20-solid" class="w-4 h-4 inline-block" />
            {{ uploadedFile }}
          </a>
        </div>
      </div>
      <div>
        <Menu v-if="uploadedFile && fieldConfiguration">
          <MenuButton
            class="relative rounded-full flex justify-between space-x-3 voix-admin-bg text-white px-5 py-3 text-xs font-bold"
          >
            <span>Use File</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="4"
              stroke="currentColor"
              class="w-4 h-4"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
              /></svg>
          </MenuButton>
          <Transition
            enter-active-class="transition duration-100 ease-out"
            enter-from-class="transform scale-95 opacity-0"
            enter-to-class="transform scale-100 opacity-100"
            leave-active-class="transition duration-75 ease-out"
            leave-from-class="transform scale-100 opacity-100"
            leave-to-class="transform scale-95 opacity-0"
          >
            <MenuItems
              class="absolute z-30 top-0 right-0 transform translate-y-14 rounded-lg bg-white flex flex-col shadow-lg w-56"
            >
              <MenuItem
                v-for="(
                  breakpoint, breakpointName
                ) in fieldConfiguration.breakpoints"
                :key="breakpointName"
                v-slot="{ active }"
                class="px-4 py-2"
              >
                <button
                  class="whitespace-nowrap"
                  :class="{ 'voix-admin-bg-lightest': active }"
                  @click="onMediaSelect(breakpointName)"
                >
                  For
                  <span class="font-bold">{{ breakpointNames[breakpointName] }}</span>
                  and Up
                </button>
              </MenuItem>
            </MenuItems>
          </Transition>
        </Menu>
      </div>
    </div>
  </div>
</template>
