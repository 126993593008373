<script setup lang="ts">
import { computed, ref } from 'vue'
import type { FieldInterface } from '@voix/types'

import {
  breakpointOptions,
  removeBreakpoint,
  sortBreakpoints,
} from '@voix/composables/useBreakpoints'

import PanelSection from '@voix/components/chrome/PanelSection.vue'
import DropdownSelect from '@voix/components/chrome/controls/DropdownSelect.vue'
import SwitchInput from '@voix/components/chrome/controls/SwitchInput.vue'

const props = defineProps<{
  field: FieldInterface
}>()

const emit = defineEmits(['addFieldBreakpoint'])

const showBreakpoints = ref(false)
const breakpointToAdd = ref<string | null>(null)
const fieldBreakpointOptions = computed(() => breakpointOptions(props.field))

const filteredBreakpoints = computed(() => {
  if (showBreakpoints.value || props.field.properties.length > 1) {
    return sortBreakpoints(props.field.properties)
  }
  else {
    return props.field.properties.filter(
      property => property.breakpoint === 'default',
    )
  }
})

function addFieldBreakpoint() {
  if (breakpointToAdd.value) {
    emit('addFieldBreakpoint', {
      breakpoint: breakpointToAdd.value,
      settings: {},
    })
  }

  breakpointToAdd.value = null
}
</script>

<template>
  <div v-if="props.field">
    <SwitchInput
      v-if="props.field.properties && Array.isArray(props.field.properties)"
      v-model="showBreakpoints"
      label="Show Breakpoints"
    />
    <div
      v-if="
        showBreakpoints
          || (Array.isArray(props.field.properties)
            && props.field.properties.length > 1)
      "
      class="flex justify-between space-x-4"
    >
      <DropdownSelect
        id="grid-breakpoint-to-add"
        v-model="breakpointToAdd"
        :options="fieldBreakpointOptions"
        placeholder="Select a Breakpoint to Add"
        class="flex-1"
      />

      <button
        class="bg-gray-100 p-1 px-2 rounded flex items-center text-2xs text-gray-500"
        @click="addFieldBreakpoint"
      >
        <svg
          class="w-4 h-4"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
          />
        </svg>
      </button>
    </div>
    <div v-if="showBreakpoints" class="mt-4 flex flex-col space-y-8">
      <PanelSection
        v-for="(breakpoint, key) in filteredBreakpoints"
        id="field-properties"
        :key="breakpoint.breakpoint"
        :name="
          breakpoint.breakpoint === 'default'
            ? 'Default Properties'
            : `Group Properties for ${breakpoint.breakpoint}`
        "
      >
        <button
          class="bg-gray-100 p-1 px-2 rounded flex items-center text-2xs text-gray-500"
          @click="removeBreakpoint(props.field, breakpoint)"
        >
          Remove Breakpoint
        </button>

        <slot name="breakpoint" v-bind="{ breakpoint, key }" />
      </PanelSection>
    </div>
  </div>
</template>
