<script lang="ts" setup>
import { computed, inject, ref } from 'vue'
import DropdownSelect from '@voix/components/chrome/controls/DropdownSelect.vue'
import RangeInput from '@voix/components/chrome/controls/RangeInput.vue'

import type { PropType, Ref } from 'vue'

import type { MediaFieldConfigInterface, MediaFieldPropertiesInterface } from '../types'
import MediaCrop from './MediaCrop.vue'

const props = defineProps({
  breakpoint: {
    type: Object as PropType<MediaFieldPropertiesInterface>,
    required: true,
  },
})

const applyModification: any = inject('applyModification')

function updateModifiers(key: string, value: string | number | null) {
  applyModification(key, value, props.breakpoint.breakpoint)
}

const defaults = {
  fit: 'cover',
  flip: '',
  rot: 0,
  saturation: 0,
  brightness: 0,
  contrast: 0,
  exposure: 0,
  highlight: 0,
  shadow: 0,
  sharpen: 0,
  auto: 'format,compress',
}

const modifiersForEditor = computed({
  get: () => {
    let mods = {}

    if (props.breakpoint.modifiers) {
      if (typeof props.breakpoint.modifiers === 'string')
        mods = JSON.parse(props.breakpoint.modifiers)
      else
        mods = props.breakpoint.modifiers
    }

    return Object.assign({}, defaults, mods)
  },
  set: (newValue) => {
    updateModifiers('modifiers', JSON.stringify(newValue))
  },
})

const currentRot: Ref<number> = ref(0)
function rotate(direction: 'clockwise' | 'counter-clockwise') {
  if (direction === 'clockwise') {
    currentRot.value = currentRot.value === 270 ? 0 : currentRot.value + 90
    updateModifiers('rot', currentRot.value.toString())
  }
  if (direction === 'counter-clockwise') {
    currentRot.value = currentRot.value === 0 ? 270 : currentRot.value - 90
    updateModifiers('rot', currentRot.value.toString())
  }
}

const showCrop = ref(false)

const openCrop = function () {
  showCrop.value = true
}

const fieldConfiguration = inject('fieldConfiguration') as MediaFieldConfigInterface

const position: Ref<string> = ref('')
if (fieldConfiguration.position)
  position.value = fieldConfiguration.position

if (fieldConfiguration.breakpoints) {
  const breakpointConfiguration = fieldConfiguration.breakpoints[props.breakpoint.breakpoint]
  if (breakpointConfiguration && breakpointConfiguration.position)
    position.value = breakpointConfiguration.position
}

function updateCrop(coordinates: { left: number, top: number, width: number, height: number }, image: { width: number, height: number }, scale: number) {
  const padLeft = coordinates.left > 0 ? 0 : Math.abs(coordinates.left)
  const padRight = coordinates.width - (padLeft + image.width)
  const padTop = coordinates.top > 0 ? 0 : Math.abs(coordinates.top)
  const padBottom = coordinates.height - (padTop + image.height)

  const finalCoordinates = {
    left: (coordinates.left > 0 ? coordinates.left : 0),
    top: (coordinates.top > 0 ? coordinates.top : 0),
    width: coordinates.width,
    height: coordinates.height,
    padLeft: padLeft * scale,
    padRight: padRight * scale,
    padTop: padTop * scale,
    padBottom: padBottom * scale,
  }

  updateModifiers('pad-left', finalCoordinates.padLeft)
  updateModifiers('pad-right', finalCoordinates.padRight)
  updateModifiers('pad-top', finalCoordinates.padTop)
  updateModifiers('pad-bottom', finalCoordinates.padBottom)

  if (coordinates.left < 0 || coordinates.top < 0 || coordinates.width < image.width || coordinates.height < image.height) {
    updateModifiers('fm', 'png')
    updateModifiers('fill', 'solid')
    updateModifiers('fit', 'fillmax')
  }

  updateModifiers('rect', `${finalCoordinates.left},${finalCoordinates.top},${finalCoordinates.width},${finalCoordinates.height}`)
  updateModifiers('w', finalCoordinates.width * scale)
  updateModifiers('h', finalCoordinates.height * scale)

  showCrop.value = false
}

function clearCrop() {
  updateModifiers('rect', null)
  updateModifiers('fm', null)
  updateModifiers('fill', null)
  updateModifiers('fit', null)
  updateModifiers('pad-left', null)
  updateModifiers('pad-right', null)
  updateModifiers('pad-top', null)
  updateModifiers('pad-bottom', null)
  updateModifiers('h', null)
  updateModifiers('w', null)
  showCrop.value = false
}
</script>

<template>
  <div class="pt-4 flex flex-col space-y-4">
    <div class="flex justify-between">
      <div class="flex space-x-1">
        <button
          class="p-1.5 bg-slate-100 hover:bg-slate-200 text-gray-600 border border-gray-300 rounded flex justify-center items-center w-8 h-8 overflow-hidden"
          @click="
            updateModifiers(
              'flip',
              modifiersForEditor.flip === 'h' ? '' : 'h',
            )
          "
        >
          <Icon name="mdi:flip-horizontal" class="w-5 h-5" />
        </button>
        <button
          class="p-1.5 bg-slate-100 hover:bg-slate-200 text-gray-600 border border-gray-300 rounded flex justify-center items-center w-8 h-8 overflow-hidden"
          @click="
            updateModifiers(
              'flip',
              modifiersForEditor.flip === 'v' ? '' : 'v',
            )
          "
        >
          <Icon name="mdi:flip-vertical" class="w-5 h-5" />
        </button>
        <button
          class="p-1.5 bg-slate-100 hover:bg-slate-200 text-gray-600 border border-gray-300 rounded flex justify-center items-center w-8 h-8 overflow-hidden"
          @click="rotate('counter-clockwise')"
        >
          <Icon name="mdi:file-rotate-left" class="w-6 h-6" />
        </button>
        <button
          class="p-1.5 bg-slate-100 hover:bg-slate-200 text-gray-600 border border-gray-300 rounded flex justify-center items-center w-8 h-8 overflow-hidden"
          @click="rotate('clockwise')"
        >
          <Icon name="mdi:file-rotate-right" class="w-6 h-6" />
        </button>
      </div>
      <div class="flex items-center space-x-1">
        <button
          class="p-1.5 bg-slate-100 hover:bg-slate-200 text-gray-600 border border-gray-300 rounded flex justify-center items-center w-8 h-8 overflow-hidden"
          @click="
            openCrop
          "
        >
          <Icon name="solar:crop-bold" class="w-6 h-6" />
        </button>
        <button
          class="p-1.5 bg-slate-100 hover:bg-slate-200 text-gray-600 border border-gray-300 rounded flex justify-center items-center w-8 h-8 overflow-hidden"
          @click="
            clearCrop
          "
        >
          <Icon name="solar:crop-line-duotone" class="w-6 h-6" />
        </button>
      </div>
    </div>

    <div>
      <DropdownSelect
        id="media-fit"
        :model-value="modifiersForEditor.fit || defaults.fit"
        label="Media Fit"
        :options="[
          {
            label: 'Cover',
            value: 'cover',
            key: 'cover',
          },
          {
            label: 'Contain',
            value: 'contain',
            key: 'contain',
          },
        ]"
        placeholder="Select a Fit Type"
        class="flex-1"
        @change="updateModifiers('fit', $event.target.value)"
      />
    </div>

    <div v-if="position">
      <DropdownSelect
        id="media-gravity"
        v-model="position"
        label="Media Gravity"
        :options="[
          {
            label: 'Top Left',
            value: 'left-top',
            key: 'left-top',
          },
          {
            label: 'Top',
            value: 'top',
            key: 'top',
          },
          {
            label: 'Top Right',
            value: 'right-top',
            key: 'right-top',
          },
          {
            label: 'Center Left',
            value: 'left',
            key: 'left',
          },
          {
            label: 'Center',
            value: 'center',
            key: 'center',
          },
          {
            label: 'Center Right',
            value: 'right',
            key: 'right',
          },
          {
            label: 'Bottom Left',
            value: 'left-bottom',
            key: 'left-bottom',
          },
          {
            label: 'Bottom',
            value: 'bottom',
            key: 'bottom',
          },
          {
            label: 'Bottom Right',
            value: 'right-bottom',
            key: 'right-bottom',
          },
        ]"
        placeholder="Select a Gravity Position"
        class="flex-1"
        @change="
          updateModifiers(
            'position',
            position,
          )
        "
      />
    </div>

    <div>
      <div>
        <RangeInput
          id="media-modifiers-saturation"
          :model-value="modifiersForEditor.saturation || defaults.saturation"
          label="Saturation"
          :min="-100"
          :max="100"
          :step="1"
          @dblclick="updateModifiers('saturation', defaults.saturation)"
          @change="updateModifiers('saturation', $event.target.value)"
        />
      </div>
      <div>
        <RangeInput
          id="media-modifiers-brightness"
          :model-value="modifiersForEditor.brightness || defaults.brightness"
          label="Brightness"
          :min="-100"
          :max="100"
          :step="1"
          @dblclick="updateModifiers('brightness', defaults.brightness)"
          @change="updateModifiers('brightness', $event.target.value)"
        />
      </div>
      <div>
        <RangeInput
          id="media-modifiers-contrast"
          :model-value="modifiersForEditor.contrast || defaults.contrast"
          label="Contrast"
          :min="-100"
          :max="100"
          :step="1"
          @dblclick="updateModifiers('contrast', defaults.contrast)"
          @change="updateModifiers('contrast', $event.target.value)"
        />
      </div>
      <div>
        <RangeInput
          id="media-modifiers-exposure"
          :model-value="modifiersForEditor.exposure || defaults.exposure"
          label="Exposure"
          :min="-100"
          :max="100"
          :step="1"
          @dblclick="updateModifiers('exposure', defaults.exposure)"
          @change="updateModifiers('exposure', $event.target.value)"
        />
      </div>
      <div>
        <RangeInput
          id="media-modifiers-highlight"
          :model-value="Math.abs(modifiersForEditor.highlight) || defaults.highlight"
          label="Reduce highlight"
          :min="0"
          :max="100"
          :step="1"
          @dblclick="updateModifiers('highlight', defaults.highlight)"
          @change="updateModifiers('highlight', -$event.target.value)"
        />
      </div>
      <div>
        <RangeInput
          id="media-modifiers-shadow"
          :model-value="modifiersForEditor.shadow || defaults.shadow"
          label="Boost shadow"
          :min="0"
          :max="100"
          :step="1"
          @dblclick.stop.exact="updateModifiers('shadow', defaults.shadow)"
          @change="updateModifiers('shadow', $event.target.value)"
        />
      </div>
      <div>
        <RangeInput
          id="media-modifiers-sharpen"
          :model-value="modifiersForEditor.sharpen || defaults.sharpen"
          label="sharpen"
          :min="0"
          :max="100"
          :step="1"
          @dblclick.stop.exact="updateModifiers('sharpen', defaults.sharpen)"
          @change="updateModifiers('sharpen', $event.target.value)"
        />
      </div>

      <div class="mt-2 text-xs italic text-slate-500 bg-slate-50 border-l-4 voix-admin-border-light rounded-r p-4 py-3">
        <span class="font-semibold voix-admin-text-light">Note:</span> Double click on a slider to reset it to its default value.
      </div>
    </div>

    <Teleport to="body">
      <MediaCrop
        v-if="showCrop && fieldConfiguration.breakpoints"
        class="fixed z-[999999] inset-0"
        :breakpoint="breakpoint"
        :config="fieldConfiguration.breakpoints[breakpoint.breakpoint]"
        @cancel="showCrop = false"
        @update="updateCrop"
        @clear-crop="clearCrop"
      />
    </Teleport>
  </div>
</template>
