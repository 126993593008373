<script lang="ts">
import { useAdminStore } from '@voix/store/adminStore'
import { usePageStore } from '@voix/store/pageStore'
import { defineComponent, inject } from 'vue'

export default defineComponent({
  inheritAttrs: false,

  setup() {
    const pageStore = usePageStore()
    const adminStore = useAdminStore()

    const mode = inject('mediaManagerDisplayMode', 'sidebar')

    return {
      pageStore,
      adminStore,
      mode,
    }
  },
})
</script>

<template>
  <Teleport to="#voix-admin-root">
    <div
      :class="{
        'absolute z-40 flex justify-center items-center': mode === 'modal',
      }"
    >
      <div
        class="duration-200 ease-out my-[2vh] bg-white/90 backdrop-blur-md"
        :class="[
          {
            'fixed z-40 top-0 right-0 mr-[335px] min-w-[300px] rounded-md shadow-2xl':
              mode === 'sidebar',
            'shadow-2xl w-[300px] h-[500px]': mode === 'modal',
            'translate-x-72 -translate-y-72 opacity-10 pointer-events-none':
              mode === 'sidebar' && adminStore.isZenMode,
          },
          $attrs.class,
        ]"
      >
        <div class="overflow-hidden mt-2">
          <div
            class="overflow-y-scroll max-h-[90vh] overflow-x-hidden -mr-4 pr-4"
          >
            <slot />
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>
