<script setup lang="ts">
import { inject, ref, toRefs, watch } from 'vue'

import TextInput from '@voix/components/chrome/controls/TextInput.vue'
import SpinnerLoader from '@voix/components/chrome/Loader.vue'
import { $voixNuxtApi } from '@voix/voix-layer/composables/voixNuxtApi'
import MediaPreview from './MediaPreview.vue'

const props = defineProps({
  selectedDirectory: {
    type: String,
    required: true,
  },
  directoryMode: {
    type: String,
    required: true,
  },
})
const viewMode = ref<'grid' | 'list'>('grid')
const showFilters = ref(false)
const selectedFileId = ref('')

function setViewMode(mode: 'list' | 'grid') {
  viewMode.value = mode
}

// TODO: Add arrow key navigation to currently selected media
function selectFile(id: string) {
  if (selectedFileId.value === id)
    selectedFileId.value = ''
  else
    selectedFileId.value = id
}

const { selectedDirectory } = toRefs(props)
const provider = inject('provider')
const mode = inject('mediaManagerDisplayMode', 'sidebar')
const loading = ref(true)
const fresh = ref(false)
const searchTerm = ref('')

const media = ref([])

watch(() => props.selectedDirectory, () => {
  getMedia()
})

function refreshMedia() {
  fresh.value = true
  getMedia().then(() => {
    fresh.value = false
  })
}

async function getMedia() {
  loading.value = true

  const query = {}

  if (selectedDirectory.value)
    query.hierarchy_id = selectedDirectory.value

  if (searchTerm.value)
    query.query = searchTerm.value

  if (fresh.value)
    query.fresh = 1

  const params = new URLSearchParams(query).toString()
  const url = `/api/voix/media/${provider.value}?${params.toString()}`

  const { data } = await $voixNuxtApi(url)

  media.value = data.value
  loading.value = false
}

getMedia()
</script>

<template>
  <div
    class="relative flex flex-col bg-white h-screen"
    :class="{ 'overflow-y-scroll': mode !== 'modal' }"
  >
    <div
      class="sticky z-10 top-0 left-0 right-0 flex justify-between w-full bg-white border-b border-gray-100 divide-x divide-gray-100"
    >
      <div class="w-full py-2">
        <!-- TODO: Add hotkey for search -->
        <TextInput
          id="media-manager-search"
          v-model="searchTerm"
          placeholder="Search Media"
          class="w-full"
          :border="false"
        />
      </div>

      <div class="flex items-center space-x-2 px-3 py-2">
        <button
          class="p-1 rounded hover:voix-admin-text-lightest transition-colors duration-300 hover:voix-admin-text"
          :class="{
            'bg-gray-100 text-gray-500': !showFilters,
            'voix-admin-text-lightest voix-admin-text': showFilters,
          }"
          @click="showFilters = !showFilters"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-4 h-4"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 13.5V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 9.75V10.5"
            />
          </svg>
        </button>
      </div>

      <div class="flex items-center space-x-2 px-3 py-2">
        <button
          class="p-1 rounded hover:voix-admin-text-lightest transition-colors duration-300 hover:voix-admin-text"
          :class="{
            'bg-gray-100 text-gray-500': viewMode !== 'grid',
            'voix-admin-text-lightest voix-admin-text': viewMode === 'grid',
          }"
          @click="setViewMode('grid')"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            class="w-4 h-4"
          >
            <path
              fill-rule="evenodd"
              d="M3 6a3 3 0 013-3h2.25a3 3 0 013 3v2.25a3 3 0 01-3 3H6a3 3 0 01-3-3V6zm9.75 0a3 3 0 013-3H18a3 3 0 013 3v2.25a3 3 0 01-3 3h-2.25a3 3 0 01-3-3V6zM3 15.75a3 3 0 013-3h2.25a3 3 0 013 3V18a3 3 0 01-3 3H6a3 3 0 01-3-3v-2.25zm9.75 0a3 3 0 013-3H18a3 3 0 013 3V18a3 3 0 01-3 3h-2.25a3 3 0 01-3-3v-2.25z"
              clip-rule="evenodd"
            />
          </svg>
        </button>

        <button
          class="p-1 rounded hover:voix-admin-text-lightest transition-colors duration-300 hover:voix-admin-text"
          :class="{
            'bg-gray-100 text-gray-500': viewMode !== 'list',
            'voix-admin-text-lightest voix-admin-text': viewMode === 'list',
          }"
          @click="setViewMode('list')"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            class="w-4 h-4"
          >
            <path
              fill-rule="evenodd"
              d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
              clip-rule="evenodd"
            />
          </svg>
        </button>

        <button class="relative p-1 flex justify-center items-center rounded voix-admin-text-lightest hover:voix-admin-bg-light transition-colors duration-300 voix-admin-text" @click="refreshMedia">
          <Icon
            name="pepicons-pop:refresh" class="w-4 h-4"
          />
        </button>
      </div>
    </div>

    <div
      v-if="loading === true"
      class="h-full flex justify-center items-center"
    >
      <div class="p-4">
        <SpinnerLoader>
          <div class="mt-2 text-center voix-admin-text font-medium text-xs">
            Loading...
          </div>
        </SpinnerLoader>
      </div>
    </div>
    <div
      v-else
      class="grid gap-4 p-4"
      :class="{
        'grid-cols-2 lg:grid-cols-3 grid-flow-row-dense': viewMode === 'grid',
        'grid-cols-1': viewMode === 'list',
      }"
    >
      <div
        v-for="file in media"
        :key="file.id"
        :class="{
          'col-span-2 lg:col-span-3 row-span-4':
            viewMode === 'grid' && selectedFileId && selectedFileId === file.id,
        }"
      >
        <MediaPreview
          :media="file"
          :view-mode="viewMode"
          :provider="provider"
          :selected-file-id="selectedFileId"
          @select="selectFile(file.id)"
          @deselect="selectFile(file.id)"
        />
      </div>
    </div>
    <div
      v-if="media.result?.value?.browseMedia < 1"
      class="mx-4 md:mx-12 p-4 rounded bg-gray-200 text-gray-400 uppercase font-bold text-center text-2xs"
    >
      No Media Found in Current {{ directoryMode }}
    </div>
  </div>
</template>
@voix/voix-layer/composables/voixNuxtApi
