<script setup lang="ts">
import { computed } from 'vue'
import ColorProperties from '@voix/components/chrome/property-panels/ColorProperties.vue'
import SpacingProperties from '@voix/components/chrome/property-panels/SpacingProperties.vue'
import DisplayModeProperties from '@voix/components/chrome/property-panels/DisplayModeProperties.vue'

import type { SettingsInterface } from '@voix/types'
import BreakpointProperties from '../controls/BreakpointProperties.vue'

const props = defineProps<{
  querySettings?: Array<SettingsInterface>
}>()

const emit = defineEmits(['update:querySettings'])

const localSettings = computed({
  get: () => props.querySettings,
  set: value => emit('update:querySettings', value),
})

function updateSettings(breakpoint: SettingsInterface,
  settings: SettingsInterface) {
  const settingsClone = localSettings.value ? localSettings.value : []
  const index = settingsClone.findIndex(
    bp => bp.breakpoint === breakpoint.breakpoint,
  )
  settingsClone[index] = settings
  emit('update:querySettings', settingsClone)
}
</script>

<template>
  <BreakpointProperties v-slot="slotProps" v-model:settings="localSettings">
    <ColorProperties
      :settings="slotProps.breakpoint"
      @update="updateSettings(slotProps.breakpoint, $event)"
    />

    <DisplayModeProperties
      :settings="slotProps.breakpoint"
      @update="updateSettings(slotProps.breakpoint, $event)"
    />

    <SpacingProperties
      :settings="slotProps.breakpoint"
      class="mt-4"
      @update="updateSettings(slotProps.breakpoint, $event)"
    />
  </BreakpointProperties>
</template>
