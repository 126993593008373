<script setup lang="ts">
import { computed, inject } from 'vue'
import type { ComputedRef } from 'vue'
import type { SliceInterface } from '@voix/types'
import SlicePreview from './SlicePreview.vue'

// eslint-disable-next-line import/no-self-import
import ChildSlicePreviewDirectory from './SlicePreviewDirectory.vue'

const props = defineProps({
  directoryName: {
    type: String,
    default: 'Root Slices',
  },
  directory: {
    type: Object,
    default: null,
  },
})

const selectedSlice = inject('selectedSlice')
const selectSlice: any = inject('selectSlice')

const files: ComputedRef<{ [key: string]: any }> = computed(() => {
  if (props.directory) {
    const filteredObject = Object.entries(props.directory).filter((slice) => {
      return Object.prototype.hasOwnProperty.call(slice[1], 'name')
    })

    return Object.fromEntries(filteredObject)
  }
  return []
})

const directories: ComputedRef<{ [key: string]: any }> = computed(() => {
  if (props.directory) {
    const filteredObject = Object.entries(props.directory).filter((slice) => {
      return !Object.prototype.hasOwnProperty.call(slice[1], 'name')
    })

    return Object.fromEntries(filteredObject)
  }
  return {}
})
</script>

<template>
  <div class="text-gray-600">
    <div
      v-if="Object.entries(files).length > 0"
      class="mt-12 mb-3 text-sm uppercase font-bold"
    >
      {{ directoryName }}
    </div>
    <div class="grid grid-cols-3 gap-4">
      <template v-for="(slice, key) in files" :key="key">
        <SlicePreview
          :slice="slice"
          :component-name="key.toString()"
          :selected="key === selectedSlice"
          class="cursor-pointer pointer-events-auto rounded transition-all duration-300"
          data-testid="voix-slice-preview-directory"
          :class="{
            'opacity-50': selectedSlice && key !== selectedSlice,
          }"
          @click="selectSlice(key)"
        />
      </template>
    </div>

    <template
      v-for="(dir, dirName) in directories"
      :key="dirName"
    >
      <ChildSlicePreviewDirectory
        :directory="dir"
        :directory-name="dirName"
      />
    </template>
  </div>
</template>
