<script lang="ts">
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
} from 'vue'
import { v4 as uuidv4 } from 'uuid'
import queryString from 'query-string'
import iframeResizer from 'iframe-resizer'
import { usePageStore } from '../../store/pageStore'
import { useAdminStore } from '../../store/adminStore'
import VoixViewerBreakpointSelector from './VoixViewerBreakpointSelector.vue'

export default defineComponent({
  components: { VoixViewerBreakpointSelector },

  props: {
    scale: {
      type: Number,
      required: true,
    },
    keyString: {
      type: String,
      required: true,
    },
  },

  emits: ['remove'],

  setup(props, { emit }) {
    const pageStore = usePageStore()
    const theIframe = ref<HTMLIFrameElement | null>(null)
    const iframeResizerInstance = ref<Array<any> | null>(null)

    const viewerWidth = ref(1200)
    const resizingStartX = ref(0)
    const isResizing = ref(false)
    const minWidth = 380
    const maxWidth = 2400

    const pagePath = computed((): string | null => {
      if (pageStore.currentPath) {
        // Break the url into its parts
        const parsedUrl = queryString.parseUrl(pageStore.currentPath)
        // Add the voix-sync query parameter
        parsedUrl.query['voix-sync'] = 'true'
        // Add cache busting param
        parsedUrl.query['cache-buster'] = uuidv4()
        // Remove the first character if it's a slash
        let url = parsedUrl.url
        if (url[0] === '/')
          url = parsedUrl.url.slice(1)

        // Put it all back together
        return `/${url}?${queryString.stringify(parsedUrl.query)}`
      }

      return null
    })

    onMounted(() => {
      window.addEventListener('mouseup', stopResize)
      window.addEventListener('mousemove', resize)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('mouseup', stopResize)
      window.removeEventListener('mousemove', resize)
    })

    function resizeToBreakpoint(breakpoint: number) {
      viewerWidth.value = breakpoint
    }

    function startResize(event: MouseEvent) {
      isResizing.value = true
      resizingStartX.value = event.clientX
    }

    function stopResize() {
      isResizing.value = false
      if (iframeResizerInstance.value && iframeResizerInstance.value[0])
        iframeResizerInstance.value[0].iFrameResizer.resize()
    }

    function resize(event: MouseEvent) {
      // If resizing is active from the mousedown event
      if (!isResizing.value)
        return
      // Calculate the new width and multiply it by 2 because the iframe is centered
      viewerWidth.value += event.clientX - resizingStartX.value
      if (viewerWidth.value < minWidth)
        viewerWidth.value = minWidth

      if (viewerWidth.value > maxWidth)
        viewerWidth.value = maxWidth

      resizingStartX.value = event.clientX
    }

    const setWidth = (event: KeyboardEvent) => {
      const target = event.target as HTMLInputElement
      if (target) {
        if (Number.parseInt(target.value) > 380) {
          viewerWidth.value = Number.parseInt(target.value)
          target.blur()
        }
      }
    }

    function initIframeResizer() {
      iframeResizerInstance.value = (iframeResizer as any).iframeResizer(
        {
          checkOrigin: false,
          scrolling: true,
          warningTimeout: 0,
        },
        theIframe.value,
      )
    }

    initIframeResizer()

    // TODO: This is a hack to get the iframe to reload when the url changes
    // theIframe.value.addEventListener('load', () => {
    //   const iframeWindow = theIframe.value.contentWindow
    //   iframeWindow.addEventListener('beforeunload', iframeChangedUrlListener)
    // })

    // function iframeChangedUrlListener(event: BeforeUnloadEvent) {
    //   const iframeWindow = theIframe.value?.contentWindow

    //   event.preventDefault()
    //   event.returnValue = ''
    // }

    // function iframeChanged() {
    //   const iframeWindow = theIframe.value?.contentWindow
    //   const iframeDoc = iframeWindow?.document
    //   // Handle URL change event here
    // }

    const adminStore = useAdminStore()
    const scrollToElementComputed = computed(() => {
      if (adminStore.scrollToElement)
        return `voix-${adminStore.scrollToElement.type}-${adminStore.scrollToElement.id}`

      return null
    })

    // Scrolling to a specific element
    watch(scrollToElementComputed, (element) => {
      if (element && theIframe.value && scrollToElementComputed.value) {
        const iframeElementToScrollTo
            = theIframe.value.contentDocument?.getElementById(
              scrollToElementComputed.value,
            )

        if (iframeElementToScrollTo) {
          iframeElementToScrollTo.scrollIntoView()

          setTimeout(() => {
            adminStore.scrollToElement = null
          }, 1000)
        }
      }
    })

    return {
      emit,
      props,
      theIframe,
      viewerWidth,
      isResizing,
      pageStore,
      pagePath,
      startResize,
      stopResize,
      resizeToBreakpoint,
      setWidth,
    }
  },
})
</script>

<template>
  <div
    :style="{
      transform: `scale(${props.scale})`,
      width: `${viewerWidth * props.scale}px`,
    }"
  >
    <div class="flex justify-center items-start">
      <div class="relative flex flex-col justify-center items-center">
        <div class="absolute bottom-0 left-0 -mb-12 flex items-center">
          <input
            type="text"
            :value="viewerWidth"
            class="inline w-24 items-center px-3 pr-6 py-0.5 rounded-full text-sm font-medium voix-admin-bg-lightest voix-admin-text-dark border-0"
            @keyup.enter="setWidth"
          >
          <span
            class="absolute right-0 top-0 mr-2 mt-0.5 voix-admin-text-light text-sm font-medium"
          >px</span>
        </div>

        <VoixViewerBreakpointSelector
          class="voix-admin-text-light mb-4"
          :viewer-width="viewerWidth"
          @resize="resizeToBreakpoint"
        />

        <div class="absolute top-0 right-0 mt-3 flex items-center">
          <button @click="$emit('remove')">
            <Icon name="heroicons:x-mark-20-solid" class="w-6 h-6 voix-admin-text" />
          </button>
        </div>

        <div class="relative" :style="{ width: `${viewerWidth}px` }">
          <div
            class="absolute z-0 inset-0 opacity-40 shadow-gray-500 shadow-2xl shadow-opacity-25"
          />

          <div v-if="pagePath" class="flex">
            <!-- Been 20 years of coding and iFrames are still in my life - Gary -->
            <iframe
              ref="theIframe"
              :src="pagePath"
              width="100%"
              class="relative z-10 bg-white h-[80vh]"
            />

            <div
              class="absolute z-10 inset-0"
              :class="{ 'pointer-events-none': !isResizing }"
            />

            <!-- Handle for resizing -->
            <div
              class="relative z-10 -mr-6 flex items-center justify-center w-6 bg-gray-200 border-l border-gray-300 rounded-r-xl cursor-ew-resize"
              @mousedown.stop.prevent="startResize"
              @mouseup.stop.prevent="stopResize"
            >
              <svg
                class="w-4 h-4 transform rotate-90 text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 6h16M4 10h16M4 14h16M4 18h16"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
