export function useElementSettings() {
  const groupDisplayModeOptions = [
    {
      label: 'Block',
      value: 'block',
      key: 'block',
    },
    {
      label: 'Flex',
      value: 'flex',
      key: 'flex',
    },
    {
      label: 'Grid',
      value: 'grid',
      key: 'grid',
    },
    {
      label: 'Inline',
      value: 'inline',
      key: 'inline',
    },
    {
      label: 'Inline Block',
      value: 'inline-block',
      key: 'inline-block',
    },
    {
      label: 'Inline Flex',
      value: 'inline-flex',
      key: 'inline-flex',
    },
    {
      label: 'Inline Grid',
      value: 'inline-grid',
      key: 'inline-grid',
    },
  ]

  const flexWrapOptions = [
    {
      label: 'No Wrap',
      value: 'nowrap',
      key: 'nowrap',
    },
    {
      label: 'Wrap',
      value: 'wrap',
      key: 'wrap',
    },
    {
      label: 'Wrap Reverse',
      value: 'wrap-reverse',
      key: 'wrap-reverse',
    },
  ]

  const flexDirectionOptions = [
    {
      label: 'Row',
      value: 'row',
      key: 'row',
    },
    {
      label: 'Row Reverse',
      value: 'row-reverse',
      key: 'row-reverse',
    },
    {
      label: 'Column',
      value: 'column',
      key: 'column',
    },
    {
      label: 'Column Reverse',
      value: 'column-reverse',
      key: 'column-reverse',
    },
  ]

  const flexJustifyOptions = [
    {
      label: 'Start',
      value: 'start',
      key: 'start',
    },
    {
      label: 'End',
      value: 'end',
      key: 'end',
    },
    {
      label: 'Center',
      value: 'center',
      key: 'center',
    },
    {
      label: 'Space Between',
      value: 'space-between',
      key: 'space-between',
    },
    {
      label: 'Space Around',
      value: 'space-around',
      key: 'space-around',
    },
    {
      label: 'Space Evenly',
      value: 'space-evenly',
      key: 'space-evenly',
    },
  ]

  const flexAlignOptions = [
    {
      label: 'Start',
      value: 'start',
      key: 'start',
    },
    {
      label: 'End',
      value: 'end',
      key: 'end',
    },
    {
      label: 'Center',
      value: 'center',
      key: 'center',
    },
    {
      label: 'Baseline',
      value: 'baseline',
      key: 'baseline',
    },
    {
      label: 'Stretch',
      value: 'stretch',
      key: 'stretch',
    },
  ]

  return {
    groupDisplayModeOptions,
    flexWrapOptions,
    flexDirectionOptions,
    flexJustifyOptions,
    flexAlignOptions,
  }
}
