<script lang="ts">
import { usePageStore } from '@voix/store/pageStore'

import type { GroupInterface, SliceConfigInterface } from '@voix/types'
import { defineComponent, ref } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { useSliceStore } from '@voix/store/sliceStore'
import AddSliceOptions from '../slices/AddSliceOptions.vue'

export default defineComponent({
  components: {
    AddSliceOptions,
  },

  props: {
    addTo: {
      type: [String, Number],
      default: null,
    },
    theSlot: {
      type: Object,
      default: () => ({ name: 'default', allowedElements: [] }),
    },
  },

  emits: ['close'],

  setup(props, ctx) {
    const pageStore = usePageStore()
    const sliceStore = useSliceStore()

    // Shows the main slice selection window
    const showSliceSelector = ref(false)

    // The currently selected slice from the selection window
    const selectedSlice = ref<string | null>(null)

    // Function to select a slice.
    const selectSlice = (sliceComponentName: string) => {
      selectedSlice.value = sliceComponentName
    }

    const selectGroupSlice = () => {
      selectedSlice.value = 'group'
    }

    // Function to deselect a slice.
    const deselectSlice = () => {
      selectedSlice.value = null
    }

    // Finalizes the selection of a slice and adds it to the current page.
    const addSliceToCurrentPage = () => {
      // Ok, so the user has selected a slice which is just a string of the component name.
      const component: string | null = selectedSlice.value
      // If the user hasn't select a slice then we don't do anything.
      if (!component)
        return

      // The addToString is where the slice will be added to the page.
      const addToString = props.addTo ? props.addTo.toString() : null

      // If the user selected the built-in "group" slice then we need to create a new group
      if (component === 'group') {
        const newGroup = {
          id: uuidv4(),
          label: 'Group',
          hydration_mode: 'load',
          type: 'group',
          elements: [],
          properties: {
            id: '',
            settings: [{ breakpoint: 'default', padding: {}, margin: {}, contain: true }],
          },
          administration: {
            gui: { open: false, el: null },
            permissions: [],
          },
        } as GroupInterface

        pageStore.addElement(newGroup, addToString)
        showSliceSelector.value = false
        return
      }

      // Otherwise we need to create a new slice from the slice config. We do this
      // by passing in the component name and the slot name of where its being added.
      // The buildSliceDataFromSliceConfig function will return a new slice object
      const newSlice = sliceStore.buildSliceDataFromSliceConfig(
        component,
        props.theSlot.name,
      )

      // If we have a new slice then we add it to the page and close the slice selector.
      if (newSlice) {
        pageStore.addElement(newSlice, addToString)
        showSliceSelector.value = false
        ctx.emit('close')
      }
    }

    const requestCancel = () => {
      ctx.emit('close')
      showSliceSelector.value = false
    }

    return {
      showSliceSelector,
      selectGroupSlice,
      selectSlice,
      deselectSlice,
      addSliceToCurrentPage,
      requestCancel,
    }
  },
})
</script>

<template>
  <div class="relative text-xs">
    <div @click="showSliceSelector = !showSliceSelector">
      <slot />
    </div>

    <div
      v-if="showSliceSelector"
      class="fixed bottom-0 mb-4 ml-3 left-0 w-[900px] h-[90vh] translate-x-[275px] shadow-lg shadow-gray-400/30 bg-white border-l border-gray-200"
    >
      <div class="absolute inset-0 overflow-y-scroll px-6 py-2 pb-24">
        <AddSliceOptions
          :allowed-elements="theSlot.allowedElements"
          @deselect="deselectSlice"
          @select="selectSlice"
          @select-group-slice="selectGroupSlice"
        />
      </div>

      <div
        class="absolute z-50 left-0 right-0 bottom-0 flex justify-start p-4 bg-gray-50 border-t border-gray-100"
      >
        <div class="max-w-sm w-full flex space-x-2">
          <button
            class="voix-admin-bg text-white px-3 py-2 rounded-full w-full"
            @click="addSliceToCurrentPage"
          >
            Add Slice
          </button>
          <button
            class="bg-gray-200 px-3 py-2 rounded-full w-full voix-admin-text"
            @click="requestCancel"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
