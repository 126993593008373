<script lang="ts">
import { defineComponent } from 'vue'
import { useModelWrapper } from '@voix/composables/useModelWrapper'
import SectionLabel from '@voix/components/chrome/SectionLabel.vue'

export default defineComponent({
  components: { SectionLabel },
  props: {
    modelValue: {
      type: null,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit, slots }) {
    return { localValue: useModelWrapper(props, emit), props, slots }
  },
})
</script>

<template>
  <div
    :class="{
      'opacity-50': props.disabled,
    }"
  >
    <SectionLabel v-if="props.label">
      {{ props.label }}
    </SectionLabel>
    <div
      class="relative flex items-center pt-1 border border-transparent text-gray-400"
    >
      <!-- Slot for SVG Icons -->
      <div class="mb-1">
        <slot />
      </div>

      <textarea
        :id="props.id"
        v-model="localValue"
        type="text"
        class="pt-2.5 pb-3 min-h-[200px] text-xs w-full h-full text-gray-500 bg-transparent placeholder-gray-300 border rounded border-gray-200 hover:voix-admin-border-lightest focus:voix-admin-border-lightest0 focus:outline-0"
        :class="{ '-ml-3 px-5': slots.default, 'px-4': !slots.default }"
        :placeholder="placeholder"
        :disabled="props.disabled"
      />
    </div>
  </div>
</template>
