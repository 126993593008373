import { computed } from 'vue'
import { usePageStore } from './../store/pageStore'

export function useField(props: { element: any; fieldConfiguration: any; fieldData: any }) {
  const localValue = computed({
    get() {
      if (props.fieldData) {
        if (props.fieldData[props.fieldData.type] !== undefined)
          return props.fieldData[props.fieldData.type]
      }
      if (props.fieldConfiguration && props.fieldConfiguration.default)
        return props.fieldConfiguration.default

      return null
    },
    set(value) {
      const pageStore = usePageStore()
      if (props.element) {
        pageStore.updateField(
          props.element.id,
          props.fieldConfiguration.name,
          value,
        )
      }
    },
  })

  const enabled = computed({
    get: (): boolean => {
      if (props.fieldData && props.fieldData.enabled !== undefined)
        return props.fieldData.enabled
      return true
    },
    set(value: boolean) {
      const pageStore = usePageStore()
      if (value === true) {
        pageStore.enableField(
          props.element.id,
          props.fieldConfiguration.name,
        )
      }
      else {
        pageStore.disableField(
          props.element.id,
          props.fieldConfiguration.name,
        )
      }
    },
  })

  return { localValue, enabled }
}
