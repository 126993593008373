<script setup lang="ts">
import { computed } from 'vue'
import SectionLabel from '@voix/components/chrome/SectionLabel.vue'
import SwitchInput from '@voix/components/chrome/controls/SwitchInput.vue'

const props = defineProps<{
  modelValue: boolean
  fieldConfiguration: {
    label: string
    enabled?: boolean
  }
}>()

const modelValue = defineModel()

const showEnabler = computed(() => {
  return typeof props.fieldConfiguration.enabled !== 'undefined'
})
</script>

<template>
  <div class="flex items-center justify-between">
    <SectionLabel>{{ fieldConfiguration.label }}</SectionLabel>
    <div v-if="showEnabler" class="flex items-center space-x-1 text-gray-400">
      <div>
        <Icon v-if="modelValue" name="heroicons:eye" class="w-5 h-5 voix-admin-text-light" />
        <Icon v-else name="heroicons:eye-slash" class="w-5 h-5" />
      </div>
      <SwitchInput v-model="modelValue" />
    </div>
  </div>
</template>
