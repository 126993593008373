<script lang="ts">
import { computed, defineAsyncComponent, defineComponent, inject, ref } from 'vue'
import type { ComputedRef } from 'vue'

import { usePageStore } from '@voix/store/pageStore'
import { useAdminStore } from '@voix/store/adminStore'
import { moveElementTopOfSlice } from '@voix/composables/usePage'

import { dragEnd, dragStart } from '@voix/composables/useDraggable'

import type {
  ElementInterface,
  FieldConfigInterface,
  GroupInterface,
  QuerySliceInterface,
  SliceInterface,
} from '@voix/types'

import { useSliceStore } from '@voix/store/sliceStore'
import AddSlice from './AddSlice.vue'

const PageNavigationElements: any = defineAsyncComponent(
  () => import('./PageNavigationElements.vue'),
)

export default defineComponent({
  name: 'PageNavigationSlice',

  components: { PageNavigationElements, AddSlice },

  props: {
    slice: {
      type: Object as () => SliceInterface,
      required: true,
    },
    depth: {
      type: Number,
      required: true,
    },
  },

  emits: [
    'selectElement',
    'selectField',
    'multiSelectElement',
    'findElement',
    'context',
  ],

  setup(props, ctx) {
    const adminStore = useAdminStore()
    const pageStore = usePageStore()
    const sliceStore = useSliceStore()

    const sliceGui: ComputedRef<{ open: boolean }> = computed(() => {
      return props.slice.administration?.gui || { open: false }
    })

    const sliceConfig = computed(() => {
      return sliceStore.getSlice(props.slice.component)
    })

    const getFieldData = (fieldConfig: FieldConfigInterface) => {
      if (props && props.slice && props.slice.fields) {
        return props.slice.fields.find(
          field => field.name === fieldConfig.name,
        )
      }
    }

    const getSlotElements = (
      slotName: string,
      elements: Array< SliceInterface | GroupInterface | QuerySliceInterface | ElementInterface>,
    ): Array<ElementInterface | SliceInterface | GroupInterface | QuerySliceInterface> => {
      if (elements) {
        return elements.filter((element) => {
          if (!element.slot && slotName === 'default')
            return true
          return element.slot === slotName
        })
      }

      return []
    }

    const hasSlots = computed(() => {
      return sliceConfig.value?.slots && sliceConfig.value?.slots.length
    })

    const hasMultipleSlots = computed(() => {
      return sliceConfig.value?.slots && sliceConfig.value?.slots.length > 1
    })
    const showSlotOptions = ref(false)

    const openAndSelect = () => {
      sliceGui.value.open = !sliceGui.value.open
      ctx.emit('selectElement', props.slice)
    }

    const buildSlotLabel = (slot: { name: string, label?: string }) => {
      if (slot.label)
        return slot.label

      if (slot.name === 'default')
        return 'Sub Slices'

      const result = slot.name.replace(/([A-Z])/g, ' $1')
      return result.charAt(0).toUpperCase() + result.slice(1)
    }

    const isDraggingOverSlice = ref(false)

    const beginDraggingOver = () => {
      if (hasSlots.value)
        isDraggingOverSlice.value = true
    }

    const endDraggingOver = () => {
      if (hasSlots.value)
        isDraggingOverSlice.value = true
    }

    const dropOnSlice = (event: DragEvent) => {
      isDraggingOverSlice.value = false
      if (hasSlots.value) {
        if (event.dataTransfer) {
          const elementId = event.dataTransfer.getData('elementId')
          moveElementTopOfSlice(elementId, props.slice)
        }
      }
    }

    function contextMenuHandler(event: MouseEvent, item: ElementInterface | SliceInterface | GroupInterface | QuerySliceInterface) {
      ctx.emit('context', event, item)
    }

    const mountOpen = inject('mountOpen', false)
    if (mountOpen)
      sliceGui.value.open = true
    return {
      props,
      adminStore,
      pageStore,
      sliceConfig,
      hasSlots,
      hasMultipleSlots,
      showSlotOptions,
      sliceGui,
      isDraggingOverSlice,
      beginDraggingOver,
      endDraggingOver,
      dragStart,
      dragEnd,
      getFieldData,
      getSlotElements,
      openAndSelect,
      buildSlotLabel,
      dropOnSlice,
      contextMenuHandler,
    }
  },
})
</script>

<template>
  <div class="w-full outline-none select-none">
    <div
      draggable="true"
      class="w-full flex items-center py-2 px-3 transition-colors duration-200 cursor-ns-resize"
      :class="[
        {
          ' voix-admin-text hover:voix-admin-text-dark hover:voix-admin-bg-lightest': !adminStore.selectedElements.includes(
            props.slice,
          ),
          'bg-opacity-75': !adminStore.selectedElements.includes(
            props.slice,
          ),
          'voix-admin-bg text-white rounded': adminStore.selectedElements.includes(
            props.slice,
          ),
          'voix-admin-bg text-white': isDraggingOverSlice,
        },
      ]"
      @click.left.exact="openAndSelect"
      @click.shift.left.stop="$emit('multiSelectElement', props.slice)"
      @dragstart="dragStart($event, `${props.slice.id}`)"
      @dragend="dragEnd"
      @contextmenu.stop="contextMenuHandler($event, props.slice)"
    >
      <div
        class="flex justify-between flex-1"
        @dragover.prevent="beginDraggingOver"
        @dragleave.prevent="endDraggingOver"
        @drop="dropOnSlice($event)"
      >
        <div class="flex items-center">
          <button
            class="cursor-ns-resize"
            :class="{
              'voix-admin-text-dark': !adminStore.selectedElements.includes(
                props.slice,
              ),
            }"
          >
            <svg
              class="w-3 h-3 mr-2.5 flex-none"
              xmlns="http://www.w3.org/2000/svg"
              width="9.576"
              height="9.576"
              viewBox="0 0 9.576 9.576"
            >
              <path
                fill="currentColor"
                class="cls-1"
                d="M2.972,0a.617.617,0,0,0-.661.451L2.034,1.506A5.894,5.894,0,0,1,8.066,7.435l1.065-.3a.617.617,0,0,0,.442-.671A7.433,7.433,0,0,0,2.972,0ZM1.879,2.1.011,9.2a.3.3,0,0,0,.374.369L7.474,7.6a5.356,5.356,0,0,0-5.6-5.5Zm.516,5.683a.6.6,0,1,1,.6-.6A.6.6,0,0,1,2.395,7.781Zm.9-2.843a.6.6,0,1,1,.6-.6A.6.6,0,0,1,3.293,4.938ZM5.238,6.883a.6.6,0,1,1,.6-.6A.6.6,0,0,1,5.238,6.883Z"
                transform="translate(-0.001 0)"
              />
            </svg>
          </button>
          <div class="truncate text-xs tracking-tight select-none">
            {{ slice.label ? slice.label : sliceConfig?.name.label }}
          </div>
        </div>
        <div v-if="sliceConfig && hasSlots && sliceConfig.slots && sliceConfig.slots[0]">
          <AddSlice v-if="!hasMultipleSlots" :the-slot="sliceConfig.slots[0]" :add-to="props.slice.id" @click.stop="" @close="showSlotOptions = false">
            <button
              class="voix-admin-text-lightest voix-admin-text-dark p-0.5 rounded"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-4 h-4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>
            </button>
          </AddSlice>

          <button v-if="hasMultipleSlots" class="voix-admin-text-lightest voix-admin-text-dark p-0.5 rounded" @click="showSlotOptions = !showSlotOptions">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-4 h-4"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 4.5v15m7.5-7.5h-15"
              />
            </svg>
          </button>

          <div
            v-show="showSlotOptions && adminStore.selectedElements.includes(
              props.slice,
            )" class="absolute top-0 right-0 m-1 mt-8 z-30 voix-admin-bg-lightest border-t border-t-white border-b-gray-200 border-b rounded flex flex-col divide-y-2 divide-gray-200"
          >
            <div
              v-for="(slot, key) in sliceConfig.slots"
              :key="key"
              class="text-left"
            >
              <AddSlice :the-slot="slot" :add-to="props.slice.id" @close="showSlotOptions = false">
                <button class="w-full py-1.5 px-3 voix-admin-text hover:voix-admin-text-dark duration-100 hover:voix-admin-bg-lightest">
                  <div>Add to {{ buildSlotLabel(slot) }}</div>
                </button>
              </AddSlice>
            </div>
            <button class="py-1.5 px-3 voix-admin-text hover:voix-admin-text-dark duration-100 hover:voix-admin-bg-lightest" @click="showSlotOptions = false">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="sliceConfig && sliceGui.open && hasSlots && props.slice.elements && props.slice.elements.length"
      class="voix-admin-bg-lightest rounded-b"
    >
      <div v-if="hasSlots">
        <div
          v-for="(slot, key) in sliceConfig.slots"
          :key="key"
          class="text-left border border-transparent"
        >
          <div v-if="sliceConfig.slots.length > 0" class="px-2 pt-4 capitalize font-medium voix-admin-text">
            {{ slot.label || `Slot: ${slot.name}` }}
          </div>
          <div
            class="flex flex-col space-y-1 py-1.5 pl-2 "
          >
            <PageNavigationElements
              :slot-name="slot.name"
              :elements="getSlotElements(slot.name, props.slice.elements)"
              :parent="props.slice"
              :depth="depth + 1"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
