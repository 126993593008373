<script setup lang="ts">
import { ref } from 'vue'
import { useAdminStore } from '@voix/store/adminStore'

defineProps({
  // The full data array for searching
  entireDataArray: {
    type: Array,
    default: () => [],
  },

  // Current Data Array for presentation
  dataArray: {
    type: Array,
    required: true,
  },

  childrenKey: {
    type: String,
    default: null,
  },
})

const emit = defineEmits(['dropAtTop', 'dropInMiddle'])

// Typing for item for slotprops
defineSlots<{
  default: any
}>()

const draggingOverTop = ref(false)
const draggingOverDivider = ref(0)

const adminStore = useAdminStore()

function dropTopOfDataArray(event: DragEvent) {
  draggingOverTop.value = false
  const idOfDroppedItem = event.dataTransfer?.getData('elementId')
  emit('dropAtTop', idOfDroppedItem)
}

function dropInMiddle(event: DragEvent, elementAbove: any): void {
  draggingOverDivider.value = 0
  const idOfDroppedItem = event.dataTransfer?.getData('elementId')
  emit('dropInMiddle', idOfDroppedItem, elementAbove.id)
}

function draggingOverThisDivider(elementId: number): boolean {
  return draggingOverDivider.value === elementId
}
</script>

<template>
  <div class="relative" :class="{ 'py-12 -translate-y-6': draggingOverTop }">
    <div
      class="absolute z-10 top-0 left-0 right-0 flex justify-center items-center"
      :class="{
        'opacity-100 h-12 translate-y-4': draggingOverTop,
        'opacity-0 h-3': !draggingOverTop,
        'hidden pointer-events-none': !adminStore.isDragging,
      }"
      @dragover.prevent="draggingOverTop = true"
      @dragleave.prevent="draggingOverTop = false"
      @drop="dropTopOfDataArray($event)"
    >
      <div class="relative voix-admin-bg h-3 w-3 rounded-full pointer-events-none opacity-50">
        <div class="absolute top-0 left-0 border-2 voix-admin-border animate-ping w-3 h-3	rounded-full" />
      </div>
      <div class="absolute left-0 right-0 flex justify-center ">
        <div class="h-1 opacity-50 voix-admin-bg w-1/4 rounded-full" />
      </div>
    </div>
    <div
      v-for="(item, key) in dataArray" :key="key" class="relative"
    >
      <div
        class="absolute z-10 bottom-0 left-0 right-0 -mb-2 flex justify-center items-center"
        :class="{
          'opacity-100 h-12 translate-y-4': draggingOverThisDivider(key + 1),
          'opacity-0 h-3': !draggingOverThisDivider(key + 1),
          'hidden pointer-events-none': !adminStore.isDragging,
        }"
        @dragover.prevent="draggingOverDivider = key + 1"
        @dragleave.prevent="draggingOverDivider = 0"
        @drop="dropInMiddle($event, item)"
      >
        <div class="relative voix-admin-bg h-3 w-3 rounded-full pointer-events-none opacity-50">
          <div class="absolute top-0 left-0 border-2 voix-admin-border animate-ping w-3 h-3	rounded-full" />
        </div>
        <div class="absolute left-0 right-0 flex justify-center ">
          <div class="h-1 opacity-50 voix-admin-bg w-1/2 rounded-full" />
        </div>
      </div>
      <div class="duration-200" :class="{ '-translate-y-3': draggingOverThisDivider(key + 1) }">
        <slot :item="item" />
      </div>
    </div>
  </div>
</template>
